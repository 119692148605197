// BookingForm.jsx
import { useState, useEffect } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { useLocation } from "react-router-dom";
import Select from "react-select"; // Importing react-select
import * as Yup from "yup";
import { useSaveTourLeads } from "hooks/leads";
import { SuccessModal } from "./SuccessModelPage";
import Spinner from "components/Adways/ContentLoaders/formSpinner";
import { logEvent, getOrCreateUserId } from "../../../../src/google-analytics";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { Nationalities, formatTripName } from "hooks/Global";
import { SiteErrorModal } from "../LandingPageSections/SiteError";

const BookingForm = () => {
  const location = useLocation();

  useEffect(() => {
    const userId = getOrCreateUserId();
    logEvent("User", "Opened Booking Form", userId);
  }, []);

  //Modal page state
  const [isModalOpen, setIsModalOpen] = useState(false);
  //handle facebook pixels
  const [fbPixelTriggered, setfbPixelTriggered] = useState(false);
  //
  const [saveLeadsData, setSaveLeadsData] = useState([]);
  // const [emailData, setEmailData] = useState([
  //   {
  //     subject: "Your Adventure Awaits! Booking Confirmation",
  //     body:
  //       "Thank you for choosing Adansi Travels for your upcoming adventure! We are thrilled to confirm your booking and can’t wait to provide you with an unforgettable travel experience.",
  //     email: "",
  //   },
  // ]);

  // const updateEmail = (newEmail) => {
  //   setEmailData((prevState) => {
  //     return prevState.map((data) => ({
  //       ...data,
  //       email: newEmail,
  //     }));
  //   });
  // };

  const { isLoading, submitted, error, isError } = useSaveTourLeads(
    saveLeadsData,
    "https://workstation.adansitravels.com/api_save_tour_leads"
  );
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [initializeEffect, setinitializeEffect] = useState(false);

  useEffect(() => {
    if (!initializeEffect) {
      setinitializeEffect(true);
      return;
    }
    //console.log(submitted,":::", isError);
    if (submitted) {
      setIsModalOpen(true);
    }
    if (isError) {
      setIsErrorModalOpen(true);
    }
  }, [submitted, isError]);

  const closeFormModal = () => {
    setIsErrorModalOpen(false);
  };

  const {
    tourNamesAndIds,
    tourNamesAndDates,
    mergerType,
    tourNames,
    tourDates,
    marketingSource,
    specialSource,
  } = location.state || {};

  //query parameters for booking source
  const allowedOptions = [
    "website",
    "facebook",
    "instagram",
    "twitter",
    "linkedIn",
    "youTube",
    "whatsApp",
    "staff",
    "flyer",
    "tv",
    "radio",
  ].concat(specialSource);

  //handle merger date

  function updateFormValues(values) {
    const { selectedDate, selectedPackage: initialTourName } = values;

    // Determine the tourName based on mergerType
    const tourName =
      mergerType === "date"
        ? tourNamesAndDates.find((tour) => tour.date === selectedDate)?.name
        : initialTourName;

    // Find the corresponding tourId using the tourName
    const tourId =
      tourNamesAndIds.find((tour) => tour.name === tourName)?.id ||
      values.tourId;

    // Step 3: Update the form values with the correct tourName and tourId
    const updatedValues = {
      ...values,
      tourName: tourName,
      tourId: tourId,
    };

    // Step 4: Remove the selectedPackage and selectedDate fields before submitting
    delete updatedValues.selectedPackage;
    delete updatedValues.selectedDate;

    return updatedValues;
  }

  //console.log(mergerType, "mereger")
  //handle Required fields for star and date fields

  //End handle form search
  let tourName = "";
  if (mergerType !== "cost") {
    tourName = tourNames[0];
  }

  let defaultStrPackage = "";
  let defaultStrDate = "";

  switch (mergerType) {
    case "cost":
      defaultStrDate = "pass";
      break;
    case "date":
      defaultStrPackage = "pass";
      break;
    default:
      defaultStrDate = "pass";
      defaultStrPackage = tourName;
  }
  //let emailData = [];
  // useEffect(() => {

  // },[]);

  // console.log("marketing source: ",marketingSource);
  let isSource = false;
  if (marketingSource !== null && marketingSource !== undefined) {
    if (marketingSource !== "website") {
      isSource = true;
    } else {
      isSource = false;
    }
  }

  //Handles form search
  const NationalitySelect = ({ options, field, form }) => (
    <Select
      options={options}
      name={field.name}
      value={
        options ? options.find((option) => option.value === field.value) : ""
      }
      onChange={(option) => form.setFieldValue(field.name, option.value)}
      onBlur={field.onBlur}
      isSearchable={true}
      className="react-select-container"
      classNamePrefix="react-select"
    />
  );

  const nationalities = Nationalities();

  const nationalityOptions = nationalities.map((nationality) => ({
    value: nationality.name,
    label: nationality.name.charAt(0).toUpperCase() + nationality.name.slice(1),
  }));

  const initialValues = {
    tourId: "",
    tourName: tourName,
    surname: "",
    othernames: "",
    nationality: "",
    placeOfWork: "",
    phone: "",
    email: "",
    selectedPackage: defaultStrPackage, // New field
    selectedDate: defaultStrDate, // New field
    emergencyName: "",
    emergencyPhone: "",
    source: marketingSource,
  };

  const validationSchema = Yup.object().shape({
    surname: Yup.string().required("Required"),
    othernames: Yup.string().required("Required"),
    nationality: Yup.string().required("Required"),
    placeOfWork: Yup.string().required("Required"),
    phone: Yup.string()
      .required("Required")
      .matches(/^[0-9]+$/, "Invalid phone number"),
    email: Yup.string().required("Required").email("Invalid email address"),
    confirmEmail: Yup.string()
      .oneOf([Yup.ref("email"), null], "Emails must match")
      .required("Required"),
    selectedPackage: Yup.string().required("Required"), // New validation
    selectedDate: Yup.string().required("Required"), // New validatio
    emergencyName: Yup.string().required("Required"),
    emergencyPhone: Yup.string()
      .required("Required")
      .matches(/^[0-9]+$/, "Invalid phone number"),
    source: Yup.string().required("Required"),
  });

  if (isLoading) {
    return <Spinner className="min-h-screen" />;
  }

  return (
    <div className="container mx-auto width-full m-2 md:w-3/3 lg:w-2/3 mb-20 bg-gray-100 p-10">
      <div className="justify-content-center mx-auto row">
        <div className="col-12 col-md-8">
          <h3 className="text-center text-xl md:text-2xl p-1 text-gray-600 mt-0 font-semibold">
            {formatTripName(tourName)} BOOKING FORM
          </h3>
          <p className="text-center p-0 text-base md:text-lg text-gray-600 font-semibold"></p>
          <div className="text-left card">
            <div className="card-body">
              <div className="alert alert-primary text-base md:text-base mt-2 text-left md:text-center p-2 text-gray-600 mb-8">
                Thank you for your interest this tour packages! Please complete
                the form below to book your tour.
              </div>
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={async (values, { setSubmitting }) => {
                  try {
                    // console.log("values: ", values);
                    // Remove selectedStar and selectedDate if present
                    const sanitizedValues = updateFormValues(values);
                    //console.log("sanitizedValues: ", sanitizedValues);

                    setSaveLeadsData([sanitizedValues]);
                    //setIsSiteErrorModalOpen(false);
                    //updateEmail(sanitizedValues.email);
                  } catch (error) {
                    console.error("Form submission error: ", error);
                    setIsErrorModalOpen(true);
                    // Handle submission error (e.g., show error message)
                  }
                }}
              >
                {({ isSubmitting, setFieldValue, values }) => (
                  <Form className="grid grid-cols-1 md:grid-cols-2 gap-5">
                    <div className="mb-1">
                      <label className="form-label font-normal text-black">
                        Surname<span style={{ color: "red" }}>*</span>
                      </label>
                      <Field
                        type="text"
                        name="surname"
                        className="form-control rounded-none"
                      />
                      <ErrorMessage
                        name="surname"
                        component="div"
                        className="text-red-600 text-sm font-light"
                      />
                      <p className="text-muted text-sm font-light">
                        As seen in passport
                      </p>
                    </div>
                    <div className="mb-1">
                      <label className="form-label font-normal text-black">
                        Other Names<span style={{ color: "red" }}>*</span>
                      </label>
                      <Field
                        type="text"
                        name="othernames"
                        className="form-control rounded-none"
                      />
                      <ErrorMessage
                        name="othernames"
                        component="div"
                        className="text-red-600 text-sm font-light"
                      />
                      <p className="text-muted text-sm font-light">
                        As seen in passport
                      </p>
                    </div>

                    <div className="mb-1">
                      <label className="form-label font-normal text-black">
                        Nationality<span style={{ color: "red" }}>*</span>
                      </label>
                      <Field
                        name="nationality"
                        component={NationalitySelect}
                        options={nationalityOptions}
                        className="border-none"
                      />
                      <ErrorMessage
                        name="nationality"
                        component="div"
                        className="text-red-600 text-sm font-light"
                      />
                    </div>

                   
                    <div className="mb-1">
                      <div className="mb-1">
                        <label className="form-label font-normal text-black">
                          Phone Number<span className="text-red-500">*</span>
                        </label>
                        <PhoneInput
                          country={"gh"}
                          value={values.phone}
                          onChange={(phone) => {
                            // Parse the phone number
                            setFieldValue("phone", phone);
                          }}
                          inputStyle={{ width: "100%" }}
                        />
                        <ErrorMessage
                          name="phone"
                          component="div"
                          className="text-red-600 text-sm font-light"
                        />
                      </div>
                    </div>
                    <div className="mb-1">
                      <label className="form-label font-normal text-black">
                        Email<span style={{ color: "red" }}>*</span>
                      </label>
                      <Field
                        type="text"
                        name="email"
                        className="form-control rounded-none"
                      />
                      <ErrorMessage
                        name="email"
                        component="div"
                        className="text-red-600 text-sm font-light"
                      />
                    </div>
                    <div className="mb-1">
                      <label className="form-label font-normal text-black">
                        Confirm Email<span style={{ color: "red" }}>*</span>
                      </label>
                      <Field
                        type="text"
                        name="confirmEmail"
                        className="form-control rounded-none"
                      />
                      <ErrorMessage
                        name="confirmEmail"
                        component="div"
                        className="text-red-600 text-sm font-light"
                      />
                    </div>

                    <div className="mb-1">
                      <label className="form-label font-normal text-black">
                        Place of Work<span style={{ color: "red" }}>*</span>
                      </label>
                      <Field
                        type="text"
                        name="placeOfWork"
                        className="form-control rounded-none"
                      />
                      <ErrorMessage
                        name="placeOfWork"
                        component="div"
                        className="text-red-600 text-sm font-light"
                      />
                    </div>

                    {mergerType === "date" && (
                      <div className="mb-1">
                        <label className="form-label font-normal text-black">
                          Select Date<span style={{ color: "red" }}>*</span>
                        </label>
                        <Field
                          as="select"
                          name="selectedDate"
                          className="form-control rounded-none"
                        >
                          <option value="">Select a date</option>
                          {tourDates.map((date) => (
                            <option key={date} value={date}>
                              {date}
                              {/* Format date as desired */}
                            </option>
                          ))}
                        </Field>
                        <ErrorMessage
                          name="selectedDate"
                          component="div"
                          className="text-red-600 text-sm font-light"
                        />
                      </div>
                    )}

                    {mergerType === "cost" && (
                      <div className="mb-1">
                        <label className="form-label font-normal text-black">
                          Select Package<span style={{ color: "red" }}>*</span>
                        </label>
                        <Field
                          as="select"
                          name="selectedPackage"
                          className="form-control rounded-none"
                        >
                          <option value="">Select Package</option>
                          {tourNames.map((tour) => (
                            <option key={tour} value={tour}>
                              {tour}
                            </option>
                          ))}
                        </Field>
                        <ErrorMessage
                          name="selectedPackage"
                          component="div"
                          className="text-red-600 text-sm font-light"
                        />
                      </div>
                    )}

                    <div className="mb-1">
                      <label className="form-label font-normal text-black">
                        Emergency Contact Person Name
                        <span style={{ color: "red" }}>*</span>
                      </label>
                      <Field
                        type="text"
                        name="emergencyName"
                        className="form-control rounded-none"
                      />
                      <ErrorMessage
                        name="emergencyName"
                        component="div"
                        className="text-red-600 text-sm font-light"
                      />
                    </div>

                    <div className="mb-1">
                      <label className="form-label font-normal text-black">
                        Emergency Contact Person Phone
                        <span className="text-red-500">*</span>
                      </label>
                      <PhoneInput
                        country={"gh"}
                        value={values.emergencyPhone}
                        onChange={(emergencyPhone) => {
                          // Parse the phone number
                          setFieldValue("emergencyPhone", emergencyPhone);
                        }}
                        inputStyle={{ width: "100%" }}
                      />
                      <ErrorMessage
                        name="emergencyPhone"
                        component="div"
                        className="text-red-600 text-sm font-light"
                      />
                    </div>
                    <div className="mb-1">
                      <label className="form-label font-normal text-black">
                        Source<span style={{ color: "red" }}>*</span>
                      </label>
                      <Field
                        as="select"
                        name="source"
                        className="form-control rounded-none"
                        disabled={Boolean(isSource)}
                      >
                        <option value="">Select an option</option>
                        {allowedOptions.map((option) => (
                          <option key={option} value={option}>
                            {option}
                          </option>
                        ))}
                      </Field>
                      <ErrorMessage
                        name="source"
                        component="div"
                        className="text-red-600 text-sm font-light"
                      />
                    </div>
                    <div className="col-span-1 md:col-span-2 text-center mt-8">
                      <button
                        type="submit"
                        className="btn bg-green-600 hover:bg-green-700 text-white font-bold py-2 px-6 rounded w-full md:w-auto"
                        disabled={isSubmitting}
                      >
                        Submit
                      </button>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>

      <SuccessModal
        isOpen={isModalOpen}
        fbPixelTriggered={fbPixelTriggered}
        onClose={() => setIsModalOpen(false)}
        onfbPixelTriggered={() => setfbPixelTriggered(true)}
      />

      <SiteErrorModal
        isOpen={isErrorModalOpen}
        onClose={() => {
          closeFormModal();
        }}
        errorMessage="An error occurred. Please try again later."
        details={error}
        buttonText="Close"
      />
    </div>
  );
};

export default BookingForm;
