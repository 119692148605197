import React, { useState, useEffect } from "react";
import sales_img from "../../../../images/marshall/sales.jpeg";
import tech_img from "../../../../images/marshall/tech.jpeg";
import visalegal_img from "../../../../images/marshall/visa-legal.jpeg";
import expertKnow from "../../../../images/marshall/expertKnow.jpeg";
import ServiceCard from "./ServiceCard";

const ServiceSection: React.FC = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const servicesData = [
    {
      icon: sales_img,
      title: "Customer-Centric Sales Techniques",
      description:
        "Master the art of selling travel services and creating customized travel experiences.",
    },
    {
      icon: tech_img,
      title: "Technology Proficiency",
      description:
        "Get hands-on training with GDS systems and AI tools essential in today’s travel industry.",
    },
    {
      icon: expertKnow,
      title: "Expert Knowledge in Tour Packaging",
      description:
        "Design and price travel packages to meet diverse client needs.",
    },
    {
      icon: visalegal_img,
      title: "Visa and Legal Insights",
      description:
        "Navigate visa requirements and provide accurate travel documentation support.",
    },
  ];

  const ServiceSliderTitle = "";
  const ServiceSliderDescription =
    "Build a solid foundation in travel consultancy with courses on customer service, sales strategies, destination knowledge, and essential industry skills.";

  // Automatically advance slides
  //   useEffect(() => {
  //     const interval = setInterval(() => {
  //       setCurrentIndex((prevIndex) => (prevIndex +1) % servicesData.length);
  //     }, 3000); // 3 seconds interval

  //     return () => clearInterval(interval); // Cleanup on component unmount
  //   }, [servicesData.length]);

  const nextSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % servicesData.length);
    console.log(currentIndex);
  };

  const prevSlide = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? servicesData.length - 1 : prevIndex - 1
    );
    console.log(currentIndex);
  };

  return (
    <div className="bg-gray-950">
      {/* Header Section */}
      <div className="max-w-6xl mx-auto pt-10 px-2 md:p-10 text-white">
        <h2 className="text-xl md:text-3xl font-bold mb-5 text-center">
          Essentials for Aspiring Travel Consultants
        </h2>
        <p className="text-base md:text-lg text-left px-3 md:text-center">
          Build a solid foundation in travel consultancy with courses on
          customer service, sales strategies, destination knowledge, and
          essential industry skills.
        </p>
      </div>

      {/* Slider Section */}
      <div className="flex items-center justify-center w-full py-8">
        {/* <button
          className="text-white border-none rounded-full cursor-pointer text-4xl px-0 mx-auto"
          onClick={prevSlide}
        >
          {"<"}
        </button> */}

        <div className="w-9/10 overflow-hidden">
          <div
            className="flex flex-col md:flex-row transition-transform duration-1000 ease-in-out mx-auto"
            style={{ transform: `md:translateX(-${currentIndex * 0}%)` }}
          >
            {servicesData.map((service, index) => (
              <ServiceCard
                key={index}
                icon={service.icon}
                title={service.title}
                description={service.description}
              />
            ))}
          </div>
        </div>
{/* 
        <button
          className="text-white mx-auto border-none rounded-full cursor-pointer text-4xl px-0"
          onClick={nextSlide}
        >
          {">"}
        </button> */}
      </div>
    </div>
  );
};

export default ServiceSection;
