import React, { FC, useState, useEffect, useRef } from "react";
import TabFilters from "./ToursTabFilters";
import TrendingTours from "../../Adways/TrendingTours";
import { useFetchAllTours } from "hooks/tours";
import LoadingListing from "../../Adways/ContentLoaders/toursListingLoader";
import Heading2 from "../../../shared/Heading/AdansiHeading";
import { useSelector } from "react-redux";
import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import ShowErr from "../../Adways/lottieFiles/nodata";
import ts from "typescript";

// Define the correct data type for your tour data
interface TourDataType {
  id: string;
  className?: string;
  checkIn: {
    seconds: number;
    nanoseconds: number;
  };
  destination: string; // Assuming destination property exists in your data
  // Add properties according to your tour data structure
}
const SectionGridFilterCard: FC = () => {
  const [currentPage, setCurrentPage] = useState(1); //Pagination

  const { data: toursData, isLoading } = useFetchAllTours();
  const [checkedItems, setCheckedItems] = useState<string[]>([]);
  const [checkedItemsDes, setCheckedItemsDes] = useState<string[]>([]);
  const [filteredTours, setFilteredTours] = useState<TourDataType[]>([]);
  // Use //@ts-ignore to ignore TypeScript error
  //@ts-ignore
  const filterDate = useSelector((state) => state.tourFilter.selected_dates); // Access state
  // Use //@ts-ignore to ignore TypeScript error
  //@ts-ignore
  const filterDestination = useSelector((state) => state.tourFilter.selected_destination); // Access state
  const [homePageFilteredMonth, setHomePageFilteredMonth] = useState<
    string | null
  >(null);
  const [
    homePageFilteredDestination,
    setHomePageFilteredDestination,
  ] = useState<string | null>(null);

  //New
  // State for search query
  const [searchQuery, setSearchQuery] = useState("");
  const sectionRef = useRef<HTMLDivElement | null>(null);
  const [itemsToShow, setItemsToShow] = useState(4);

  useEffect(() => {
    if (toursData && searchQuery.length > 0) {
      const filtered = toursData.filter((tour) =>
        tour.name.toLowerCase().includes(searchQuery.toLowerCase())
      );
      setFilteredTours(filtered);
      // setShowDropdown(true); // Show dropdown when query exists
    }
  }, [searchQuery, toursData]);

  //New

  useEffect(() => {
    if (filterDate) {
      const homePageFilteredMonthData = getMonthFromStartDate(filterDate);
      setHomePageFilteredMonth(homePageFilteredMonthData);
    }
    if (filterDestination) {
      setHomePageFilteredDestination(filterDestination);
    }
  }, [filterDate, filterDestination]);

  // Scroll to section when currentPage changes
  useEffect(() => {
    sectionRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [currentPage]);

  useEffect(() => {
    if (toursData && !isLoading) {
      let filteredByItems = toursData;
      let filteredByDestinations = toursData;

      // Default filter by homePageFilteredDestination
      if (homePageFilteredDestination) {
        filteredByDestinations = toursData.filter((tour) =>
          tour.destination.includes(homePageFilteredDestination)
        );
      }

      // Default filter by homePageFilteredMonth
      if (homePageFilteredMonth !== null) {
        filteredByDestinations = filteredByDestinations.filter((tour) => {
          const checkInDate = new Date(tour.checkIn.seconds * 1000);
          // Use //@ts-ignore to ignore TypeScript error
          //@ts-ignore
          return checkInDate.getMonth() === homePageFilteredMonth;
        });
      }

      const months = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ];

      // Function to convert timestamp (seconds) to month name
      const getMonthName = (seconds) => {
        const date = new Date(seconds * 1000);
        return months[date.getUTCMonth()];
      };

      // Filter tours based on checked items if checks are not empty
      if (checkedItems.length > 0) {
        filteredByItems = filteredByItems.filter((tour) => {
          const checkInMonth = getMonthName(tour.checkIn._seconds);
          return checkedItems.includes(checkInMonth);
          //console.log('checkedItems',   checkedItems )
        });
      }

      // Filter tours based on checked destinations if checks are not empty
      if (checkedItemsDes.length > 0) {
        filteredByDestinations = filteredByDestinations.filter((tour) =>
          checkedItemsDes.includes(tour.destination)
        );
      }

      // Combine the two filters
      const finalFilteredTours = filteredByItems.filter((tour) =>
        filteredByDestinations.includes(tour)
      );

      // Set the filtered tours to state
      setFilteredTours(finalFilteredTours);

      // Clear homePageFilteredMonth and homePageFilteredDestination after filtering
      setHomePageFilteredMonth(null);
      setHomePageFilteredDestination(null);
    }
  }, [
    toursData,
    isLoading,
    checkedItems,
    checkedItemsDes,
    homePageFilteredMonth,
    homePageFilteredDestination,
  ]);

  const getMonthFromStartDate = (dateObject) => {
    const startDate = new Date(dateObject.start_date);
    const month = startDate.toLocaleString("default", { month: "long" });
    return month;
  };

  if (isLoading) {
    return (
      <div className={`nc-SectionGridFilterCard mb-5 mt-5 pt-10 pb-10`}>
        <LoadingListing />
      </div>
    );
  }

  //********Handle Pagination**********//
  const ITEMS_PER_PAGE_DESK = 10; // Adjust this number based on how many items you want per page
  const ITEMS_PER_PAGE_MOBILE = 4; // Adjust this number based on how many items you want per page
  // Function to scroll to the tour-pages section
  const scrollToSection = () => {
    sectionRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const totalPages = Math.ceil(filteredTours.length / ITEMS_PER_PAGE_DESK);
  const indexOfLastItem = currentPage * ITEMS_PER_PAGE_DESK;
  const indexOfFirstItem = indexOfLastItem - ITEMS_PER_PAGE_DESK;
  const currentItems = filteredTours.slice(indexOfFirstItem, indexOfLastItem);

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
    scrollToSection();
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
    scrollToSection();
  };

  //pagination for mobile
  const handleViewMore = () => {
    setItemsToShow(ITEMS_PER_PAGE_MOBILE + itemsToShow); // Show more items on mobile
  };

  // Calculate items to display based on current page or itemsToShow for mobile
  const displayItems =
    window.innerWidth < 768
      ? filteredTours.slice(0, itemsToShow) // Mobile uses itemsToShow
      : filteredTours.slice(
          (currentPage - 1) * ITEMS_PER_PAGE_DESK,
          currentPage * ITEMS_PER_PAGE_DESK
        );

  const isMobile = window?.innerWidth < 768;

  return (
    <div className="nc-SectionGridFilterCard mb-5 mt-5 pt-10 pb-10">
      <div className="absolute inset-0 z-0 bg-gray-200">
        <BackgroundSection />
      </div>
      <div ref={sectionRef} id="tour-pages" className="relative z-10">
        <Heading2
          heading="Tour Packages"
          subHeading={
            <span className="block text-neutral-500 dark:text-neutral-400">
              Found {toursData?.length || 0} tours
            </span>
          }
        />
        <TabFilters
          setSearchFilteredTours={setFilteredTours}
          checkedItems={checkedItems}
          setCheckedItems={setCheckedItems}
          checkedItemsDes={checkedItemsDes}
          setCheckedItemsDes={setCheckedItemsDes}
        />
        <div className="grid grid-cols-1 gap-6 md:gap-8 xl:grid-cols-2 mt-5">
          {displayItems.length > 0 ? (
            displayItems.map((tour) => (
              //@ts-ignore
              <TrendingTours key={tour.id} data={tour} />
            ))
          ) : (
            <ShowErr />
          )}
        </div>

        {/* "View More" Button (Mobile Only) */}
        <button
          onClick={handleViewMore}
          className="flex md:hidden mt-6 mx-auto block border border-yellow-600 text-yellow-600 px-4 py-2 rounded-lg hover: transition duration-300"
          style={{ display: isMobile ? "block" : "none" }}
        >
          View More
        </button>

        {/* Pagination (Desktop Only) */}
        <div className="hidden md:flex justify-between items-center mt-7">
          <button
            onClick={handlePrevPage}
            disabled={currentPage === 1}
            className={`flex items-center px-4 py-2 border border-yellow-600 text-yellow-600 rounded-lg shadow-md transition duration-300 
            ${
              currentPage === 1
                ? "opacity-50  text-gray-300 border-gray-400 cursor-not-allowed"
                : "hover:bg-yellow-600 hover:text-white"
            }`}
          >
            <i className="fas fa-chevron-left mr-2"></i> Previous
          </button>

          <span className="text-lg font-semibold">
            Page {currentPage} of {totalPages}
          </span>

          <button
            onClick={handleNextPage}
            disabled={currentPage === totalPages}
            className={`flex items-center px-4 py-2 border border-yellow-600 text-yellow-600 rounded-lg shadow-md transition duration-300 
            ${
              currentPage === totalPages
                ? "opacity-8 text-gray-300 border-gray-400 cursor-not-allowed"
                : " hover:bg-yellow-600 hover:text-white"
            }`}
          >
            Next <i className="fas fa-chevron-right ml-2"></i>
          </button>
        </div>
      </div>
    </div>
  );
};

export default SectionGridFilterCard;
