import React from "react";

const PriceSection = () => {
  return (
    <section className="bg-gray-50 pt-12 pb-16 mx-auto  bg-gradient-to-r from-blue-50 via-white to-blue-100 terms-center ">
      <div className="max-w-5xl mx-auto grid grid-cols-1 md:grid-cols-10 gap-8 items-center px-4">
        {/* Left Column - Overview (70%) */}
        <div className="md:col-span-5 space-y-4">
         
          <p className="text-base text-left text-gray-800 ">
            Are you ready to embark on a career in travel consultancy? Our
            comprehensive Travel Sales Consultancy Course, crafted in
            partnership with industry experts, offers you everything you need
            to become a skilled travel professional. Enroll to gain essential
            skills in sales, marketing, customer service, tour packaging, and
            advanced technologies like GDS and AI.
          </p>
        </div>

        {/* Right Column - Pricing (30%) */}
        <div className="md:col-span-4 bg-white p-10 rounded-md border border-gray-200 shadow-sm ">
          <h3 className="text-xl font-semibold text-gray-700 mb-4">
            Course Fee
          </h3>
          <div className="space-y-5">
            <div className="flex justify-between items-center text-lg">
              <span className="font-bold text-gray-800">Standard Price:</span>
              <span className="text-lg text-red-600 line-through">₵ 12,000.00</span>
            </div>
            <div className="flex justify-between items-center text-lg">
              <span className=" font-bold text-gray-800">Early Bird Promo:</span>
              <span className="text-lg text-green-600 font-bold">₵ 9,500.00</span>
            </div>
            <p className="text-sm text-gray-500">
              Valid till 31st December or for the first 15 applicants.
            </p>
          </div>
          {/* <button className="mt-6 w-full bg-green-500 hover:bg-green-600 text-white py-3 px-4 rounded-lg font-semibold transition">
            Enroll Now
          </button> */}
        </div>
      </div>
    </section>
  );
};

export default PriceSection;
