import React, { Fragment, useEffect, useState } from "react";
import { Popover, Transition } from "@headlessui/react";
import { FC } from "react";
import ButtonSubmit from "./ButtonSubmit";
import { PathName } from "routers/types";
import { useSelector } from 'react-redux';
import { Link, useNavigate } from "react-router-dom";
// import { GuestsObject } from "./type";
// import { useSelector, useDispatch } from 'react-redux';
// import { set_destination , set_dates} from "../../redux/slices/tourFilter";


export interface GuestsInputProps {
  fieldClassName?: string;
  className?: string;
  buttonSubmitHref?: PathName;
  hasButtonSubmit?: boolean;
}



const GuestsInput: FC<GuestsInputProps> = ({
  fieldClassName = "[ nc-hero-field-padding ]",
  className = "[ nc-flex-1 ]",
  buttonSubmitHref = "/tours",
  hasButtonSubmit = true,
}) => {
  // const [guestAdultsInputValue, setGuestAdultsInputValue] = useState(2);
  // const [guestChildrenInputValue, setGuestChildrenInputValue] = useState(1);
  // const [guestInfantsInputValue, setGuestInfantsInputValue] = useState(1);

  //const dispatch = useDispatch();

  // const handleChangeData = (value: number, type: keyof GuestsObject) => {
  //   let newValue = {
  //     guestAdults: guestAdultsInputValue,
  //     guestChildren: guestChildrenInputValue,
  //     guestInfants: guestInfantsInputValue,
  //   };
  //   if (type === "guestAdults") {
  //     setGuestAdultsInputValue(value);
  //     newValue.guestAdults = value;
  //   }
  //   if (type === "guestChildren") {
  //     setGuestChildrenInputValue(value);
  //     newValue.guestChildren = value;
  //   }
  //   if (type === "guestInfants") {
  //     setGuestInfantsInputValue(value);
  //     newValue.guestInfants = value;
  //   }
  // };

  // const totalGuests =
  //   guestChildrenInputValue + guestAdultsInputValue + guestInfantsInputValue;


   // Use //@ts-ignore to ignore TypeScript error
  //@ts-ignore
  const filterDate = useSelector((state) => state.tourFilter.selected_dates); // Access state
   // Use //@ts-ignore to ignore TypeScript error
  //@ts-ignore
  const filterDestination = useSelector((state) => state.tourFilter.selected_destination)


  const navigate = useNavigate();

const gotoTourFilter=()=>{
  if(filterDate && filterDestination){
    navigate("/tours");
  }else{
    alert( "choose destination and date to continue" )
  }
}

  return (
    <Popover className={`flex relative ${className}`}>
      {({ open }) => (
        <>
  <div
  className={`flex-1 z-10 flex items-center focus:outline-none ${
    open ? "nc-hero-field-focused" : ""
  }`}
>
  {/* Other content */}
  
  {/* Float the button to the right */}
  {hasButtonSubmit && (
    <div className="ml-auto pr-2 xl:pr-4">
      <ButtonSubmit onClick={gotoTourFilter} />
    </div>
  )}
</div>
        </>
      )}
    </Popover>
  );
};

export default GuestsInput;

