import React from 'react';
import { Player } from '@lottiefiles/react-lottie-player';
import animationData from './nodata.json';

const LottieAnimation = () => {
  return (
    <div className="flex justify-center items-center w-full h-screen">
      <Player
        autoplay
        loop
        src={animationData}
        style={{ height: '100%', width: '100%' }}
      />
    </div>
  );
};

export default LottieAnimation;
