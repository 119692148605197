import React, { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import ButtonPrimary from "shared/Button/ButtonPrimary";

const Popup = ({ isOpen, closeModal, type, message }) => {
  const isError = type === "error";
  const isSuccess = type === "success";

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="fixed inset-0 z-50 overflow-y-auto" onClose={closeModal}>
        <div className="min-h-screen px-4 text-center">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-40" />
          </Transition.Child>

          <span className="inline-block h-screen align-middle" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div className="inline-block w-full max-w-md p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
              <Dialog.Title
                as="h3"
                className={`text-lg font-medium leading-6 ${isError ? "text-red-900" : "text-green-900"}`}
              >
                {isError ? "Error" : "Success"}
              </Dialog.Title>
              <div className="mt-2">
                <p className={`text-sm ${isError ? "text-red-600" : "text-green-600"}`}>
                  {message}
                </p>
              </div>

              <div className="mt-4">
                <ButtonPrimary
                  type="button"
                  className={`inline-flex justify-center px-4 py-2 text-sm font-medium ${isError ? "text-red-900 bg-red-100 hover:bg-red-200" : "text-green-900 bg-green-100 hover:bg-green-200"}`}
                  onClick={closeModal}
                >
                  Close
                </ButtonPrimary>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
};

export default Popup;
