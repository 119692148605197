import React, { useState } from 'react';

const PaymentSection = ({ onApplyCoupon }) => {
  const [couponCode, setCouponCode] = useState('');

  const handleApplyCoupon = () => {
    onApplyCoupon(couponCode);
  };

  return (
    <div className="m-2 p-4 border rounded-md bg-gray-50">

      {/* Coupon input field and apply button in two columns */}
      <div className="grid grid-cols-2 gap-2">
        <input
          type="text"
          placeholder="Enter coupon code"
          value={couponCode}
          onChange={(e) => setCouponCode(e.target.value)}
          className="border border-gray-300 rounded-md p-2 w-full"
        />
        <button
            type='button'
          onClick={handleApplyCoupon}
          className="bg-green-600 hover:bg-green-700 text-white rounded-md p-2 text-base w-full"
        >
          Apply
        </button>
      </div>
    </div>
  );
};

export default PaymentSection;
