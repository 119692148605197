import React, { ChangeEvent, FormEvent } from "react";

interface TermsAndConditionsComponentProps {
  submit: boolean;
  onSubmitted: () => void;
  accepted: boolean;
  onAccepted: (accepted: boolean) => void;
}

const TermsAndConditionsComponent: React.FC<TermsAndConditionsComponentProps> = ({
  submit,
  onSubmitted,
  accepted,
  onAccepted,
}) => {
  const handleAcceptChange = (e: ChangeEvent<HTMLInputElement>): void => {
    // Directly toggle the accepted state based on the checkbox state
    onAccepted(e.target.checked);
  };

  const handleSubmit = (e: FormEvent): void => {
    e.preventDefault();
    if (accepted) {
      onSubmitted();
    }
  };

  const refund_and_cancellation = [
    {
      id: "introduction",
      title: "Introduction",
      content: `<p>The Client's contractual agreement with <strong>Adansi Travels Ltd</strong> (referred to as the <strong>"Company"</strong>) is established on the following terms and conditions. Please review these conditions as they outline the rights and responsibilities of both parties.</p>

<p>By acknowledging and accepting these terms and conditions, the Client affirms that they have either personally read them or ensured that they have been read, explained, and interpreted to them in a language they comprehend.</p>

<p>By selecting the <strong>"I Accept"</strong> box, the Client affirms their understanding and agreement to the specified terms and conditions.</p>

<p>These terms and conditions apply to all travel arrangements facilitated by the Company, and they take precedence over any prior agreements between the Client and representatives of the Company.</p>

<p>By consenting to these terms, the Client agrees to be bound by the terms and conditions stipulated below.</p>
`,
    },
    {
      id: "definitions",
      title: "1. Definitions",
      content: `<p><strong>1.1.</strong> <strong>"Child"</strong> means a person between the ages of 2 years and 11 years for the purposes of the trip. Children 12 years of age and above are considered adults by most hotels and tour sites and or attraction centers and by airlines.</p>

<p><strong>1.2.</strong> <strong>"Infant"</strong> means a person who is less than 2 years at the time of travel.</p>

<p><strong>1.3.</strong> <strong>"Client"</strong> means a person who has signed up for and paid for a trip with the Company.</p>

<p><strong>1.4.</strong> <strong>"Force Majeure"</strong> means an event that could not have reasonably been anticipated and planned for, and which is beyond the control of, and occurs without the fault or negligence of, the party failing to perform including, without limitation, trade embargos, war, military or international peacekeeping intervention, acts of God, fire, explosion, strikes or other labor disputes, riots or other civil disturbances, or voluntary or involuntary compliance with any law, order, regulation or request of any governmental authority which wholly or partly prevents the affected party from performing its obligations hereunder.</p>

<p><strong>1.5.</strong> <strong>"Group Hotel"</strong> means the accommodation provided for Clients.</p>

<p><strong>1.6.</strong> <strong>"Host Country"</strong> means the country the Company shall visit and tour with its Clients.</p>

<p><strong>1.7.</strong> <strong>"Partner Agent"</strong> means others whose services are necessary for a successful trip and may include, airlines, hotels, transportation companies, restaurants, etc.</p>

<p><strong>1.8.</strong> <strong>"Promotional Price"</strong> means the reduced price off the recommended retail price of the Tour Package, which a Client would be entitled to, should they make all payments in relation to the Tour Package before or on a date specified by the Company.</p>

<p><strong>1.9.</strong> <strong>"Service Charge"</strong> means the fees charged for the services offered by the Company.</p>

<p><strong>1.10.</strong> <strong>"Special Arrangements"</strong> means specific accommodations or adjustments made to standard Tour Packages to cater to the unique requirements, preferences, or needs of the Client.</p>

<p><strong>1.11.</strong> <strong>"Tour"</strong> means a tour of the Host Country.</p>

<p><strong>1.12.</strong> <strong>"Tour Dates"</strong> means dates that the Company has advertised for a particular Tour Package.</p>

<p><strong>1.13.</strong> <strong>"Tour Packages"</strong> means a particular trip to a destination whether domestic or international covering a number of travel services as advertised.</p>

<p><strong>1.14.</strong> <strong>"Tour Package Price"</strong> means the recommended retail price of the Tour Package.</p>
`,
    },
    {
      id: "cancellation",
      title: "11. Cancellation",
      content: `
    <p><strong>11.1.</strong> The <strong>Client</strong> may not cancel their trip 14 days before the scheduled departure date.</p>

<p><strong>11.2.</strong> The <strong>Client</strong> may cancel their subscription to a <strong>Tour Package</strong> in line with the following Clause, where:</p>
<ul style="margin: 10px; margin-left: 20px;">
    <li>a. The <strong>Client</strong> experiences unexpected emergencies, including but not limited to suffering a serious injury or ailment.</li>
    <li>b. The Government of the destination country issues travel advisories, warnings, travel restrictions, or entry bans.</li>
    <li>c. The <strong>Company</strong> makes significant changes to the <strong>Tour</strong> itinerary provided that were not initially agreed upon.</li>
    <li>d. Natural disasters, acts of terrorism, political unrest, or other force majeure events occur, making the <strong>Tour</strong> unsafe or impracticable.</li>
</ul>

<p><strong>11.3.</strong> To cancel their subscription, the <strong>Client</strong> is required to inform the <strong>Company</strong> by sending an email to the <strong>Tour Leader</strong> using the <strong>Tour Leader's</strong> designated email address. The cancellation notice from the <strong>Client</strong> becomes valid only upon receipt in writing by the <strong>Company</strong>.</p>

<p><strong>11.4.</strong> The <strong>Company</strong> may cancel a scheduled <strong>Tour</strong> where:</p>
<ul style="margin: 10px; margin-left: 20px;">
    <li>a. The Government of the destination country imposes travel advisories, warnings, travel restrictions, or entry bans, or</li>
    <li>b. There are events beyond the <strong>Company’s</strong> control, such as natural disasters, extreme weather conditions, acts of terrorism, political unrest, or any other force majeure event, or</li>
    <li>c. There are safety concerns at the destination, such as disease outbreaks, civil unrest, or other hazardous conditions, or</li>
    <li>d. There are unexpected and irreconcilable logistical issues.</li>
</ul>

<p><strong>11.5.</strong> The <strong>Company</strong> will notify the <strong>Clients</strong> of a tour cancellation through a phone call, text message, or email. This communication will be promptly conveyed as soon as the decision to cancel is finalized.</p>
 `,
    },
    {
      id: "refund_policy",
      title: "12. Refund Policy",
      content: `
   <p><strong>12.1.</strong> The <strong>Company</strong> will refund any payments made towards a <strong>Tour</strong>, deducting administrative charges and visa application fees where the <strong>Client’s</strong> visa application has been denied for reasons other than fraud or the submission of counterfeit documents. The refund policy shall be subject to periodic review.</p>

<p><strong>12.2.</strong> Where the <strong>Client</strong> does not participate in the trip for any reason whatsoever, the <strong>Company</strong> will not refund any payments made for air tickets. The <strong>Client</strong> must verify the ticket conditions with the <strong>Company</strong> during the booking process.</p>

<p><strong>12.3.</strong> The <strong>Company</strong> reserves the right to refund monies paid if the <strong>Client</strong> cancels their trip before the booking and confirmation of flight ticket and accommodation. The refunded amount will be subject to the deduction of administrative fees, as communicated to the <strong>Client</strong> by the <strong>Company</strong>.</p>

<p><strong>12.4.</strong> The <strong>Client</strong> may also request that the money paid be used for another <strong>Tour</strong> with the <strong>Company</strong>. This alternative arrangement may incur additional costs for the <strong>Client</strong>.</p>

<p><strong>12.5.</strong> Where the visa is granted and the <strong>Client</strong> refuses to travel, there shall be no refunds of monies paid for the trip to the <strong>Client</strong>.</p>

<p><strong>12.6.</strong> In cases where visa refusal stems from the submission of fraudulent documents, such as counterfeit Bank Statements provided by <strong>Clients</strong>, no refunds of payments will be issued. The <strong>Company</strong> may report the <strong>Client</strong> to the Embassy and the <strong>Company</strong> may pursue legal remedies to restore its reputation against the implicated <strong>Client</strong>.</p>

<p><strong>12.7.</strong> The cost of flights is non-refundable after the <strong>Company</strong> has issued and booked flight tickets.</p>
 `,
    },
  ];

  return (
    <div className="p-2 md:p-6 w-full md:max-w-4xl mx-auto">
      <h1 className="text-xl text-left text-gray-600 font-bold mb-4">
        Terms and Conditions
      </h1>
      <div
        style={{
          margin: "0cm",
          fontSize: "16px",
          fontFamily: '"Times New Roman", serif',
          textAlign: "justify",
          lineHeight: "150%",
        }}
      >
        <div className="overflow-y-scroll w-full h-60 border-gray-300 p-2 rounded-lg bg-white">
          {refund_and_cancellation.map((section) => (
            <div className="">
              <div key={section.id} id={section.id}>
                <h2 className="text-xl font-semibold mt-4">{section.title}</h2>
                <div
                  className="leading-loose md:ml-10"
                  dangerouslySetInnerHTML={{ __html: section.content }}
                />
              </div>
            </div>
          ))}
          <a
            href="http://adansitravels.com/terms-and-conditions"
            className="md:ml-10 font-semibold text-blue-800 hover:text-blue-700 hover:underline"
            target="_blank"
            rel="noopener noreferrer"
          >
            Read More ...
          </a>
        </div>
      </div>

      <form onSubmit={handleSubmit} className="mt-6">
        <label className="flex items-center space-x-2">
          <input
            type="checkbox"
            checked={accepted}
            onChange={handleAcceptChange}
            className="form-checkbox h-5 w-5 text-green-600"
          />
          <span>I Accept the Terms and Conditions</span>
        </label>
      </form>

      {submit && !accepted && (
        <p className="text-red-500 mt-4">
          You must accept the terms and conditions to proceed.
        </p>
      )}
    </div>
  );
};

export default TermsAndConditionsComponent;
