import React from "react";
import hero_img from "../../../../images/marshall/sales.jpeg";
import Button from "../Button";

const TwoColumnSection: React.FC = () => {
  return (
    <div className="flex flex-col p-0 md:p-20 text-center justify-center bg-gray-50">
      <h2 className="text-xl md:text-4xl pt-10 md:pt-0 font-bold text-black mb-2 md:mb-5 text-center">
        Benefits of Expressing Your Interest Early
      </h2>
      <p className="px-3 text-left md:text-center pb-2 text-base md:text-lg">
        Expressing your interest early unlocks exclusive opportunities, ensures
        priority access, and sets you ahead of the competition
      </p>

      <div className="flex flex-col md:flex-row items-center md:px-20 ">
        {/* Left Column */}
        <div className="md:w-1/2 px-5 pb-10 flex-1 md:pr-5 bg-white md:p-10 md:shadow-lg">
          <ul className="text-base md:text-lg text-left text-black leading-relaxed list-disc pl-5 mb-5">
            <li className="md:py-2">
              <strong className="md:font-semibold">Stay Updated</strong> – Be
              the first to know when registration opens and receive course
              updates.
            </li>
            <li className="md:py-3">
              <strong className="md:font-semibold">Exclusive Discount</strong> –
              Qualify for a special discount on the course fees.
            </li>
            <li className="md:py-3">
              <strong className="md:font-semibold">Priority Access</strong> –
              Secure a spot in this high-demand program before it fills up.
            </li>
          </ul>
          <Button
            label="Register Now"
            onClick={() =>
              (window.location.href =
                "https://marshalls.edu.gh/adansi/marshalls-adansi-application/")
            }
          />
        </div>

        {/* Right Column */}
        <div className="md:w-1/2 flex-1 flex justify-center mt-5 md:-ml-6 md:mt-72">
          <img
            src={hero_img}
            alt="Course Benefits"
            className="w-full md:max-w-full md:rounded-sm md:shadow-lg"
          />
        </div>
      </div>
    </div>
  );
};

export default TwoColumnSection;
