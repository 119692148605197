import { useState, useEffect } from 'react';
import axios from 'axios';


const useSubmitVisaForm = (formValues) => {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const BASE_URL = 'https://workstation.adansitravels.com/website_submit_visa_request'; // Update with your actual base URL
  const API_KEY = process.env.REACT_APP_API_KEY;

  useEffect(() => {
    const isFormValuesEmpty = !formValues || Object.keys(formValues).length === 0;

    if (isFormValuesEmpty) {
      setError('Form values are empty. Please provide the required information.');
      return;
    }

    const fetchData = async () => {
      setIsLoading(true);
      setError(null);

      try {
        const response = await axios.post(
          BASE_URL,
          {data : formValues},
          {
            headers: {
              'adway-key': API_KEY
            }
          }
        );
        setData(response.data);
      } catch (err) {
        console.error('Error fetching data:', err);
        setError('Error submitting the form. Please try again later.');
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [formValues]);

  return { data, isLoading, error };
};

  export {
    useSubmitVisaForm
  }