"use client";

import Heading from "../../shared/Heading/Heading";
import NcPlayIcon from "../../shared/NcPlayIcon/NcPlayIcon";
import NcPlayIcon2 from "../../shared/NcPlayIcon2/NcPlayIcon2";
import React, { FC, useState } from "react";
import trvlHouse from "../../images/trvlHouse.jpg"
import mayaVideo from "../../images/maya.jpg";
import barbados from "../../images/barbados.jpg";
import dubaiOnEmirates from "../../images/dubaiOnEmirates.jpg"
import kofiKing from "../../images/kofiKing.png";

export interface VideoType {
  id: string;
  title: string;
  thumbnail: string;
}

export interface SectionVideosProps {
  videos?: VideoType[];
  className?: string;
}

const VIDEOS_DEMO: VideoType[] = [
  {
    id: "nB5Ghe3QKss",
    title: "Welcome to the Adansi Travel House! A state of the facility serving as a hub to cater for all your travel needs and  a testament to our mission of being one of the best travel agencies in Ghana and beyond.",
    thumbnail:trvlHouse,
  },
  {
    id: "tiZifge5lbQ",
    title: "We are connecting the Dots by Bridging the gap between Africans across the Globe. We are achieving this through a historic chartered flight to Barbados with  @WODEMAYA What's more? We will be bringing our brothers in Barbados to experience Ghana.This experience is one you should not miss!",
    thumbnail:mayaVideo,
  },
  {
    id: "sDevomopAqw",
    title: "Embark on a luxurious journey to the gem of the Caribbean - Barbados! Adansi Travels is excited to offer an exclusive chartered flight package from August 1st to August 11th, 2024. Dive into the vibrant culture, bask in the sun on pristine beaches, and indulge in the adventure of a lifetime!",
    thumbnail:barbados,
  },
  {
    id: "gwFoIACaCLw",
    title: "Step back into the magic of our milestone celebration with a glimpse into the exclusive chartered flight experience on Emirates",
    thumbnail:dubaiOnEmirates,
  },
  {
    id: "mPNg57X_bVo",
    title: "Adansi Travels 11th Anniversary with Kofi Kinaata",
    thumbnail:kofiKing,
  },
];

const SectionVideos: FC<SectionVideosProps> = ({
  videos = VIDEOS_DEMO,
  className = "",
}) => {
  const [isPlay, setIsPlay] = useState(false);
  const [currentVideo, setCurrentVideo] = useState(0);

  const renderMainVideo = () => {
    const video: VideoType = videos[currentVideo];
    return (
      <div
        className="group aspect-w-16 aspect-h-16 sm:aspect-h-9 bg-neutral-800 rounded-3xl overflow-hidden border-4 border-white dark:border-neutral-900 sm:rounded-[50px] sm:border-[10px] will-change-transform"
        title={video.title}
      >
        {isPlay ? (
          <iframe
            src={`https://www.youtube.com/embed/${video.id}?autoplay=1`}
            title={video.title}
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        ) : (
          <>
            <div
              onClick={() => setIsPlay(true)}
              className="cursor-pointer absolute inset-0 flex items-center justify-center z-10"
            >
              <NcPlayIcon />
            </div>

            <img
              className="object-cover w-full h-full transform transition-transform group-hover:scale-105 duration-300"
              src={video.thumbnail}
              title={video.title}
              alt={video.title}
              sizes="(max-width: 1000px) 100vw,
                (max-width: 1200px) 75vw,
                50vw"
            />
          </>
        )}
      </div>
    );
  };

  const renderSubVideo = (video: VideoType, index: number) => {
    if (index === currentVideo) return null;
    return (
      <div
        className="group relative aspect-h-16 aspect-w-16 rounded-2xl cursor-pointer overflow-hidden sm:aspect-h-12 sm:rounded-3xl lg:aspect-h-9"
        onClick={() => {
          setCurrentVideo(index);
          !isPlay && setIsPlay(true);
        }}
        title={video.title}
        key={String(index)}
      >
        <div className="absolute inset-0 flex items-center justify-center z-10">
          <NcPlayIcon2 />
        </div>
        <img
          className="object-cover w-full h-full transform transition-transform group-hover:scale-110 duration-300"
          src={video.thumbnail}
          title={video.title}
          alt={video.title}
          sizes="(max-width: 300px) 100vw,
          (max-width: 1200px) 50vw,
          25vw"
        />
      </div>
    );
  };

  return (
    <div className={`nc-SectionVideos ${className}`}>
      <Heading
        desc="Feel the beauty of life with us as we tour the world in style. Watch some videos to see more."
      >
       ADANSI VLOG
      </Heading>

      <div className="flex flex-col relative sm:pr-4 sm:py-4 md:pr-6 md:py-6 xl:pr-14 xl:py-14 lg:flex-row">
        <div className="absolute -top-4 -bottom-4 -right-4 w-2/3 rounded-3xl bg-primary-100 bg-opacity-40 z-0 sm:rounded-[50px] md:top-0 md:bottom-0 md:right-0 xl:w-1/2 dark:bg-neutral-800 dark:bg-opacity-40"></div>
        <div className="flex-grow relative pb-2 sm:pb-4 lg:pb-0 lg:pr-5 xl:pr-6">
          {renderMainVideo()}
        </div>
        <div className="flex-shrink-0 grid gap-2 grid-cols-4 sm:gap-6 lg:grid-cols-1 lg:w-36 xl:w-40">
          {videos.map(renderSubVideo)}
        </div>
      </div>
    </div>
  );
};

export default SectionVideos;
