const Button: React.FC<{ label: string; onClick: () => void }> = ({ label, onClick }) => {
    return (
      <button
        className="px-6 py-3 bg-red-700 text-white rounded-lg hover:bg-red-800 transition-colors"
        onClick={onClick}
      >
        {label}
      </button>
    );
  };
  
  export default Button;
  