import ReactGA from 'react-ga4';
import { v4 as uuidv4 } from "uuid";

const GA_TRACKING_ID = 'G-B3WTPQ4ZHP';

export const initGA = () => {
  ReactGA.initialize(GA_TRACKING_ID);
};

export const logPageView = () => {
  const path = window.location.pathname + window.location.search;
  ReactGA.send({ hitType: "pageview", page: path });
};

export const logEvent = (category = '', action = '', label = '') => {
  if (category && action) {
    ReactGA.event({
      category,
      action,
      label,
    });
  }
};

export const getOrCreateUserId = (): string => {
  let userId = localStorage.getItem('userId');
  if (!userId) {
    userId = uuidv4();
    localStorage.setItem('userId', userId);
  }
  return userId;
};
