import React from 'react';

const BookingTable = ({ bookings , loadingState }) => {

if(!loadingState && bookings?.length ==0){
    return <></>
}else{

  return (
    <div className="overflow-x-auto">
      <table className="min-w-full bg-white border border-gray-200">
        <thead>
          <tr className="bg-gray-100 border-b border-gray-200">
            <th className="text-left py-2 px-4">Tour Name</th>
            <th className="text-left py-2 px-4">Total Cost</th>
            <th className="text-left py-2 px-4">Status</th>
            <th className="text-left py-2 px-4">Booking ID</th>
            <th className="text-left py-2 px-4">Booking Date</th>
            <th className="text-left py-2 px-4">Action</th>
          </tr>
        </thead>
        <tbody>
          {bookings?.map((booking) => (
            <tr key={booking.id} className="border-b border-gray-200">
              <td className="text-left py-2 px-4">{booking.tourname}</td>
              <td className="text-left py-2 px-4">{booking.totalCost}</td>
              <td className="text-left py-2 px-4">{booking.status}</td>
              <td className="text-left py-2 px-4">{booking.bookingid}</td>
              <td className="text-left py-2 px-4">{new Date(booking.createdAt._seconds * 1000).toLocaleDateString()}</td>
              <td className="text-left py-2 px-4"></td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );

}
}

export default BookingTable;
