import React, { useState, useEffect } from "react";

const PaxCounter = ({ onChange, paxCounts, isDisabled }) => {
  const [numberOfPax, setNumberOfPax] = useState(0);

  useEffect(() => {
    if (numberOfPax > 0 && paxCounts.adult === 0 && paxCounts.couple === 0) {
      setNumberOfPax(0);

    }
  }, [paxCounts]);

  const increment = () => {
    if (!isDisabled) {
      const newPaxCount = numberOfPax + 1;
      setNumberOfPax(newPaxCount);
      onChange(newPaxCount);
    }
  };

  const decrement = () => {
    if (numberOfPax > 0 && !isDisabled) {
      const newPaxCount = numberOfPax - 1;
      setNumberOfPax(newPaxCount);
      onChange(newPaxCount);
    }
  };
 let style = isDisabled ? "bg-gray-600 hover:bg-green-500" : "bg-green-600 hover:noon"
  return (
    <div className="flex items-left space-x-4">
      <button
        type="button"
        className={` p-2  text-white rounded-full  focus:outline-none  ${isDisabled ? 'bg-gray-200' : 'bg-green-500 text-white hover:bg-green-600'}`}
        onClick={decrement}
        disabled={isDisabled}
      >
        <svg
          width="14"
          height="2"
          viewBox="0 0 14 2"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect
            width="14"
            height="2"
            rx="1"
            fill={isDisabled ? "gray" : "white"}
          ></rect>
        </svg>
      </button>
      <span className="text-lg font-normal w-3">{numberOfPax}</span>
      <button
        type="button"
        className={` p-2  text-white rounded-full  focus:outline-none  ${isDisabled ? 'bg-gray-200' : 'bg-green-500 text-white hover:bg-green-600'}`}
        onClick={increment}
        disabled={isDisabled}
      >
        <svg
          width="14"
          height="14"
          viewBox="0 0 14 14"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M7 0C6.44772 0 6 0.447716 6 1V6H1C0.447716 6 0 6.44771 0 7C0 7.55228 0.447716 8 1 8H6V13C6 13.5523 6.44772 14 7 14C7.55228 14 8 13.5523 8 13V8H13C13.5523 8 14 7.55228 14 7C14 6.44772 13.5523 6 13 6H8V1C8 0.447715 7.55228 0 7 0Z"
            fill={isDisabled ? "gray" : "white"}
          ></path>
        </svg>
      </button>
    </div>
  );
};

export default PaxCounter;
