import React from "react";
import IframeContainer from "./IframeContainer";

const E_SIM: React.FC = () => {
  return (
    <div>
      <IframeContainer />
    </div>
  );
};

export default E_SIM;
