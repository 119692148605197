// import React, { useState } from 'react';
// // import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/solid';

// const images = [
//   'https://via.placeholder.com/600x300?text=Slide+1',
//   'https://via.placeholder.com/600x300?text=Slide+2',
//   'https://via.placeholder.com/600x300?text=Slide+3',
//   'https://via.placeholder.com/600x300?text=Slide+4',
//   'https://via.placeholder.com/600x300?text=Slide+5',
//   'https://via.placeholder.com/600x300?text=Slide+6',
//   'https://via.placeholder.com/600x300?text=Slide+7',
//   'https://via.placeholder.com/600x300?text=Slide+8',
//   // Add more image URLs here
// ];

// const ImageCarousel = () => {
//   const [currentIndex, setCurrentIndex] = useState(0);

//   const prevSlide = () => {
//     setCurrentIndex((prevIndex) => (prevIndex === 0 ? Math.ceil(images.length / 4) - 1 : prevIndex - 1));
//   };

//   const nextSlide = () => {
//     setCurrentIndex((prevIndex) => (prevIndex === Math.ceil(images.length / 4) - 1 ? 0 : prevIndex + 1));
//   };

//   return (
//     <div className="relative w-full max-w-7xl mx-auto">
//       <div className="relative overflow-hidden">
//         <div className="flex transition-transform duration-500" style={{ transform: `translateX(-${currentIndex * 100}%)` }}>
//           {images.map((image, index) => (
//             <img key={index} src={image} alt={`Slide ${index + 1}`} className="w-1/4 flex-shrink-0" />
//           ))}
//         </div>
//       </div>
//       <button onClick={prevSlide} className="absolute left-0 top-1/2 transform -translate-y-1/2 bg-red-500 text-white p-2 rounded-full">
//         {/* <ChevronLeftIcon className="w-6 h-6" /> */}
//       </button>
//       <button onClick={nextSlide} className="absolute right-0 top-1/2 transform -translate-y-1/2 bg-red-500 text-white p-2 rounded-full">
//         {/* <ChevronRightIcon className="w-6 h-6" /> */}
//       </button>
//     </div>
//   );
// };

// export default ImageCarousel;

"use client";

import React, { FC, useEffect, useState } from "react";
import { AnimatePresence, motion, MotionConfig } from "framer-motion";
import { useSwipeable } from "react-swipeable";
import { variants } from "utils/animationVariants";
import { useWindowSize } from "react-use";
import PrevBtn from "../../components/Adways/PrevBtn";
import NextBtn from "../../components/Adways/NextBtn";

// const images = [
//   'https://via.placeholder.com/600x300?text=Image+1',
//   'https://via.placeholder.com/600x300?text=Image+2',
//   'https://via.placeholder.com/600x300?text=Image+3',
//   'https://via.placeholder.com/600x300?text=Image+4',
//   'https://via.placeholder.com/600x300?text=Image+5',
//   'https://via.placeholder.com/600x300?text=Image+6',
//   'https://via.placeholder.com/600x300?text=Image+7',
//   'https://via.placeholder.com/600x300?text=Image+8',
//   // Add more image URLs here
// ];

// Use //@ts-ignore to ignore TypeScript error
//@ts-ignore

interface ImageCarouselProps {
  images?: string[];
}

const ImageCarousel: FC<ImageCarouselProps> = ({ images }) => {
  

  const [currentIndex, setCurrentIndex] = useState(0);
  const [direction, setDirection] = useState(0);
  const [numberOfItems, setNumberOfItems] = useState(4);

  const windowWidth = useWindowSize().width;

  useEffect(() => {
    if (windowWidth < 640) {
      setNumberOfItems(1);
    } else if (windowWidth < 768) {
      setNumberOfItems(2);
    } else if (windowWidth < 1024) {
      setNumberOfItems(3);
    } else {
      setNumberOfItems(4);
    }
  }, [windowWidth]);

  const changeItemId = (newVal: number) => {
    if (newVal > currentIndex) {
      setDirection(1);
    } else {
      setDirection(-1);
    }
    setCurrentIndex(newVal);
  };

  const handlers = useSwipeable({
    onSwipedLeft: () => {
      if (currentIndex < images.length - numberOfItems) {
        changeItemId(currentIndex + 1);
      }
    },
    onSwipedRight: () => {
      if (currentIndex > 0) {
        changeItemId(currentIndex - 1);
      }
    },
    trackMouse: true,
  });

  useEffect(() => {
    const interval = setInterval(() => {
      if (currentIndex < images?.length - numberOfItems) {
        setCurrentIndex((prevIndex) => prevIndex + 1);
      } else {
        setCurrentIndex(0);
      }
    }, 3000); // Adjust the interval time as needed (in milliseconds)

    return () => clearInterval(interval);
  }, [currentIndex, images?.length, numberOfItems]);

  return (
    <div className="relative w-full max-w-7xl mx-auto" {...handlers}>
      <MotionConfig
        transition={{
          x: { type: "spring", stiffness: 300, damping: 50 },
          opacity: { duration: 0.2 },
        }}
      >
        <div className="relative overflow-hidden">
          <motion.ul
            initial={false}
            className="relative whitespace-nowrap -mx-2 xl:-mx-4"
          >
            <AnimatePresence initial={false} custom={direction}>
              {images.map((image, index) => (
                <motion.li
                  key={index}
                  className="relative inline-block px-2 xl:px-4"
                  custom={direction}
                  initial={{
                    x: `${(currentIndex - 1) * -100}%`,
                  }}
                  animate={{
                    x: `${currentIndex * -100}%`,
                  }}
                  variants={variants(200, 1)}
                  style={{
                    width: `calc(100% / ${numberOfItems})`,
                  }}
                >
                  <img
                    src={image}
                    alt={`Slide ${index + 1}`}
                    className="w-full h-[450px] sm:h-[450px] object-cover rounded-xl"
                  />
                </motion.li>
              ))}
            </AnimatePresence>
          </motion.ul>
        </div>
      </MotionConfig>

      {currentIndex > 0 && (
        <PrevBtn
          style={{ transform: "translate3d(0, 0, 0)" }}
          onClick={() => changeItemId(currentIndex - 1)}
          className="w-9 h-9 xl:w-12 xl:h-12 text-lg absolute -left-3 xl:-left-6 top-1/3 -translate-y-1/2 z-[1]"
        />
      )}

      {images.length > currentIndex + numberOfItems && (
        <NextBtn
          style={{ transform: "translate3d(0, 0, 0)" }}
          onClick={() => changeItemId(currentIndex + 1)}
          className="w-9 h-9 xl:w-12 xl:h-12 text-lg absolute -right-3 xl:-right-6 top-1/3 -translate-y-1/2 z-[1]"
        />
      )}
    </div>
  );
};

export default ImageCarousel;
