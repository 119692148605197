import React, { FC } from "react";


export interface TwoColumnImgProps {
  className?: string;
  children?: React.ReactNode;
  img1?: string,
  img2?: string
  };


const TwoColumnImg: FC<TwoColumnImgProps> = ({
  img1 = "",
  img2 = ""
  
}) => {
  return (
    <div className="container mx-auto px-4 py-8">
    <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
      <div className="relative">
        <img src={img1} alt="Placeholder Image 1" className="w-full h-full object-cover rounded-lg shadow-lg" />
       
      </div>
      <div className="relative">
        <img src={img2} alt="Placeholder Image 2" className="w-full h-full object-cover rounded-lg shadow-lg" />
        
      </div>
    </div>
  </div>
  );
};

export default TwoColumnImg;
