import { useState, useEffect } from 'react';
import axios from 'axios';
import { auth } from '../api/firebase';

const useAuthenticateCustomers = (email, password) => {
  const [data, setData] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  const BASE_URL = 'https://workstation.adansitravels.com/website_fetch_customer_with_uid';
  const API_KEY = process.env.REACT_APP_API_KEY;

  useEffect(() => {
    const fetchData = async () => {
      // Validate email and password
      if (!email || !password) {
        console.error('Email and password are required.');
        setIsLoading(false);
        return;
      }

      setIsLoading(true);

      try {
        // Sign in with Firebase Authentication
        const userCredential = await auth.signInWithEmailAndPassword(email, password);
        const userData = await userCredential.user;

        // Make a request to your Firebase function
        const response = await axios.post(
          BASE_URL,
          { authid : userData.uid },
          { headers: { 'adway-key': API_KEY } }
        );

        setData(response.data);
      } catch (error) {
        console.error('Error fetching data:', error);
        setData({ status: false, message: 'Authentication failed' });
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [email, password]);

  return { data, isLoading };
};


const useFetchCustomerBookingWithID = (customerid) => {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const BASE_URL = 'https://workstation.adansitravels.com/website_fetch_customer_bookings';
  const API_KEY = process.env.REACT_APP_API_KEY;

  useEffect(() => {
    const fetchData = async () => {
      if (!customerid) {
        // Handle case where customerid is not valid
        setIsLoading(false);
        return;
      }

      setIsLoading(true);
      try {
        const response = await axios.post(BASE_URL, 
          { customerid }, 
          {
            headers: {
              'adway-key': API_KEY
            }
          });
        setData(response.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [customerid]); // Include customerid in the dependency array to re-fetch when it changes

  return { data, isLoading };
}


// const changePasswordWithVerification = async (oldPassword, newPassword) => {

//   const [data, setData] = useState({});
//   const [isLoading, setIsLoading] = useState(true);

//   useEffect(() => {
//     const fetchData = async () => {
//       // Validate email and password
//       if (!oldPassword || !newPassword) {
//         console.error('passwords are required.');
//         setIsLoading(false);
//         return;
//       }

//       setIsLoading(true);
//       try {
//         const user = auth.currentUser;
//         if (!user) {
//           setData({ status: false, message: 'No authenticated user found' });
//           setIsLoading(false);
//           throw new Error('No authenticated user found');
//         }
       
//     // Re-authenticate user with current password
//     const credential = auth.EmailAuthProvider.credential(user.email, oldPassword);
//     await user.reauthenticateWithCredential(credential);

//     // Update password
//     await user.updatePassword(newPassword);
//     console.log('Password updated successfully');
//     setData({ status: true, message: 'Password updated successfully' });
//   } catch (error) {
//     console.error('Error updating password:', error);
//     setData({ status: false, message: error.message });
//   }
//  finally {
//   setIsLoading(false);
// }
// }
// fetchData();

//   }, [ oldPassword, newPassword ]);
//   return { data, isLoading };
// };



// const changePasswordWithVerification = async (oldPassword, newPassword) => {
//   const [data, setData] = useState({});
//   const [isLoading, setIsLoading] = useState(true);

//   useEffect(() => {
//     const fetchData = async () => {
//       // Validate oldPassword and newPassword
//       if (!oldPassword || !newPassword) {
//         console.error('Both old and new passwords are required.');
//         setData({ status: false, message: 'Both old and new passwords are required.' });
//         setIsLoading(false);
//         return;
//       }

//       setIsLoading(true);

//       try {
//         const user = auth.currentUser;
//         if (!user) {
//           setData({ status: false, message: 'No authenticated user found' });
//           setIsLoading(false);
//           throw new Error('No authenticated user found');
//         }

//         // Re-authenticate user with current password
//         const credential = auth.EmailAuthProvider.credential(user.email, oldPassword);
//         await user.reauthenticateWithCredential(credential);

//         // Update password
//         await user.updatePassword(newPassword);
//         console.log('Password updated successfully');
//         setData({ status: true, message: 'Password updated successfully' });
//       } catch (error) {
//         console.error('Error updating password:', error);
//         setData({ status: false, message: error.message });
//       } finally {
//         setIsLoading(false);
//       }
//     };

//     fetchData();

//   }, [oldPassword, newPassword]);

//   return { data, isLoading };
// };

const useChangePasswordWithVerification = async (oldPassword, newPassword) => {
  const [data, setData] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);

      try {
        const user = auth.currentUser;
        if (!user) {
          throw new Error('No authenticated user found');
        }

        // Re-authenticate user with current password
        const credential = auth.EmailAuthProvider.credential(user.email, oldPassword);
        await user.reauthenticateWithCredential(credential);

        // Update password
        await user.updatePassword(newPassword);
        console.log('Password updated successfully');
        setData({ status: true, message: 'Password updated successfully' });
      } catch (error) {
        console.error('Error updating password:', error);
        setData({ status: false, message: error.message });
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();

  }, [oldPassword, newPassword]);

  return { data, isLoading };
};


export { useAuthenticateCustomers ,useFetchCustomerBookingWithID , useChangePasswordWithVerification };
