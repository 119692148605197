import { FaGlobe, FaPassport, FaLaptopCode, FaSmile, FaBalanceScale, FaLightbulb } from "react-icons/fa";
import moduleImg from "../../../../images/marshall/module-image.jpeg";
import ModulesCard from "./ModuleCard";

const ModulesSection = () => {
  return (
    <div className="flex flex-col md:flex-row items-center md:x-20 bg-gray-50 md:pt-4">
        {/* Left Column */}
        <div className="relative md:w-full flex-1 flex justify-center mt-5  md:mt-0">
          <img
            src={moduleImg}
            alt="Course Benefits"
            className="w-full h-[460px] md:h-auto md:max-w-full md:rounded-sm md:shadow-lg object-cover"
          />
          <div className="absolute md:top-10 md:right-10 top-0 right-auto opacity-90 md:opacity-100">
            <ModulesCard />
          </div>
        </div>

        {/* Right Column */}
        {/* <div className="md: px-5 pb-10 flex-1 md:pr-5 bg-white md:p-10 md:shadow-lg">
        </div> */}
      
      </div>
  );
};

export default ModulesSection;
