import { formatAmount, formatFBdate, formatTripName, getCurrencySymbol } from 'hooks/Global';
import React from 'react';

// Utility function to convert a timestamp to a readable date
// const formatDate = (timestamp) => {
//   const date = new Date(timestamp * 1000); // Convert seconds to milliseconds
//   return date.toLocaleDateString();
// };

const PriceCostDateTable = ({ tours }) => {
  return (
    <div className="overflow-x-auto shadow-lg bg-white">
    
      <table className="min-w-full table-auto border-collapse border border-gray-200">
        <thead>
          <tr className='text-green-700 '>
            <th className="px-1 sm:px-4 text-sm py-2 border text-left border-gray-200">Tour Name</th>
            <th className="px-1 sm:px-4 text-sm py-2 border text-left border-gray-200">Date</th>
            <th className="px-1 sm:px-6 text-sm py-2 border text-left border-gray-200">Adult Rate</th>
            <th className="px-1 sm:px-4 text-sm py-2 border text-left border-gray-200">Couple Rate</th>
          </tr>
        </thead>
        <tbody>
          {tours.map((tour) => {
            // Extracting rates
            const adultRate = tour.costing.find((c) => c.category === 'Adult')?.amount || 'N/A';
            const coupleRate = tour.costing.find((c) => c.category === 'COUPLE')?.amount || 'N/A';

            return (
              <tr key={tour.id}>
                <td className="px-1 sm:px-4 text-xs text-left font-medium py-3 border border-gray-200">{formatTripName(tour.name)}</td>
                <td className="px-1 sm:px-4 text-sm py-3 border font-semibold border-gray-200">{formatFBdate(tour.startdate)}</td>
                <td className="px-1 sm:px-4 text-sm py-3 text-red-600 font-semibold border border-gray-200">{`${getCurrencySymbol(tour.currency)} ${formatAmount(adultRate)}`}</td>
                <td className="px-1 sm:px-4 text-sm py-3 text-red-600 border font-semibold border-gray-200">{`${getCurrencySymbol(tour.currency)} ${formatAmount(coupleRate)}`}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default PriceCostDateTable;
