import React from "react";
import ContentLoader from "react-content-loader";

const Theme3LoaderDesktop = (props) => (
  <ContentLoader
    speed={2}
    width="100%"
    height={900}
    viewBox="0 0 800 900"
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
    {...props}
  >
    {/* Top Image */}
    <rect x="0" y="0" rx="10" ry="10" width="900" height="300" />


    {/* price Heading */}
    <rect x="300" y="310" rx="10" ry="10" width="200" height="40" />
    {/**describtion */}
    <rect x="150" y="360" rx="5" ry="5" width="500" height="20" />

    {/* left: date card */}
    <rect x="30" y="410" rx="5" ry="5" width="350" height="150" />
    {/* right: price cards */}
    <rect x="420" y="410" rx="5" ry="5" width="350" height="70" />
    <rect x="420" y="490" rx="5" ry="5" width="350" height="70" />
    {/* book now */}
    <rect x="350" y="580" rx="10" ry="10" width="100" height="40" />

    <rect x="5" y="630" rx="10" ry="10" width="180" height="250" />
    <rect x="195" y="630" rx="10" ry="10" width="180" height="250" />
    <rect x="385" y="630" rx="10" ry="10" width="180" height="250" />
    <rect x="600" y="630" rx="10" ry="10" width="180" height="250" />
    

  </ContentLoader>
);


const Theme3LoaderMobile = (props) => (
    <ContentLoader
      speed={2}
      width="100%"
      height="100%"
      viewBox="0 0 800 900"
      backgroundColor="#f3f3f3"
      foregroundColor="#ecebeb"
      {...props}
    >
      {/* Top Image */}
      <rect x="90" y="0" rx="10" ry="10" width="625" height="300" />
      <rect x="90" y="310" rx="10" ry="10" width="625" height="300" />
  
  
      {/* price Heading */}
      <rect x="140" y="630" rx="10" ry="10" width="515" height="40" />
      <rect x="190" y="680" rx="10" ry="10" width="415" height="40" />
      {/**describtion */}
      <rect x="140" y="740" rx="5" ry="5" width="515" height="150" />
  
      
      
  
    </ContentLoader>
  );

  

export {Theme3LoaderDesktop, Theme3LoaderMobile};
