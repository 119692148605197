import React, { useState, useEffect } from "react";
import hero_img from "../../../images/marshall/main-4-marshall.jpeg";
import sales_img from "../../../images/marshall/sales.jpeg";
import tech_img from "../../../images/marshall/tech.jpeg";
import visalegal_img from "../../../images/marshall/visa-legal.jpeg";
import expertKnow from "../../../images/marshall/expertKnow.jpeg";
import BgGlassmorphism from "../../BgGlassmorphism/BgGlassmorphism";
import marshallLogo from "../../../images/marshall/logo.jpeg";
import adansiLogo from "../../../images/logo.png";
import HeroSection from "./Sections/HeroSection";
import SecondarySectionWithServices from "./Sections/SecondarySectionWithServices";
import TwoColumnSection from "./Sections/TwoColumnSection";
import TheAboutUsSection from "./Sections/AboutUsSection";
import LunchYourCarrerSection from "./Sections/LunchYourCarrerSection";
import ServiceSection from "./Sections/ServiceSection";
import ModulesSection from "./Sections/ModulesSection";

// Tailwind CSS utility classes
const IconGrid: React.FC = () => (
  <div className="text-center mt-10 mb-10"> {/* Centering "Powered By" text */}
    <p className="font-bold">Powered By</p>
    <div className="grid grid-cols-2 gap-2 md:p-4 max-w-[400px] mx-auto">
      {[
        { imageSrc: adansiLogo, text: "Adansi Travels" },
        { imageSrc: marshallLogo, text: "Marshalls University College" },
      ].map((item, index) => (
        <div key={index} className="flex flex-col items-center">
          <img
            src={item.imageSrc}
            alt={item.text}
            className="w-16 h-16 object-contain mb-2"
          />
          <p>{item.text}</p>
        </div>
      ))}
    </div>
  </div>
);

const Career_in_travel: React.FC = () => {
  return (
    <>
      <HeroSection onOpenModal={() => {}} />
      {/* <LunchYourCarrerSection/> */}
      <BgGlassmorphism />
      <SecondarySectionWithServices />
      <ModulesSection />
      <ServiceSection />
      <TwoColumnSection />
      <TheAboutUsSection />
      <IconGrid />
    </>
  );
};

export default Career_in_travel;
