import React from "react";

const TheAboutUsSection: React.FC = () => (
  <div className="flex mx-auto max-w-7xl flex-col md:flex-row items-center px-4 py-20 bg-white">
    {/* Left Column */}
    <div className="flex-1 max-w-6xl mx-auto md:pr-4">
      <h3 className="text-xl md:text-4xl font-bold text-black mb-4 md:mb-7 text-center">
        About Marshalls University College and Adansi Travels
      </h3>

      <p className="px-3 text-base md:px-10 mb-4">
        Marshalls University College and Adansi Travels bring together academic excellence and
        industry expertise to prepare the next generation of travel sales consultants. With a
        curriculum designed by experts and practical training from industry leaders, this course
        provides a complete pathway into the travel industry.
      </p>

      <p className="px-4 text-2xl mt-10 md:px-10 font-bold">Questions?</p>
      <p className="px-4 md:px-10">
        For any questions about the course, please email us at{" "}
        <a href="mailto:registrar@marshalls.edu.gh" className="text-blue-600 underline">
          registrar@marshalls.edu.gh
        </a>{" "}
        or call us at{" "}
        <a href="tel:+233277741576" className="text-blue-600 underline">
          (+233) 277741576
        </a>.
      </p>
    </div>
  </div>
);

export default TheAboutUsSection;
