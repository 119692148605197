import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  customer: [],

};

const customerSlice = createSlice({
  name: 'customer',
  initialState,
  reducers: {
    setCustomerInfo: (state , action ) => {
      state.customer = action.payload;
    },
    clearCustomerInfo: (state) => {
      state.customer = null;
    },
    
  },
});

export default customerSlice.reducer;
export const { setCustomerInfo , clearCustomerInfo } = customerSlice.actions;
