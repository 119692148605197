import { Tab } from "@headlessui/react";
import CarCard from "components/CarCard/CarCard";
import ExperiencesCard from "components/ExperiencesCard/ExperiencesCard";
import StayCard from "components/StayCard/StayCard";
import {
  DEMO_CAR_LISTINGS,
  DEMO_EXPERIENCES_LISTINGS,
  DEMO_STAY_LISTINGS,
} from "data/listings";
import React, { Fragment, useState,useEffect } from "react";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import CommonLayout from "./CommonLayout";
import { useFetchCustomerBookingWithID } from "../../hooks/customers";
import { useSelector, useDispatch } from "react-redux";
import BookingTable from "./Booking_details"
import Table from "../../components/Adways/ContentLoaders/Table";

const AccountSavelists = () => {

  const [customerId , setCustomerId]=useState();
    // Use //@ts-ignore to ignore TypeScript error
  //@ts-ignore
  const customer_info = useSelector((state) => state.customer_data?.customer); // Access state

  const { data : customerBookingsInfo, isLoading, error } = useFetchCustomerBookingWithID( customerId );


  useEffect(() => {
    if (customer_info) {
      setCustomerId( customer_info?.customerid );
    }
  }, [customer_info]);

  const renderSection1 = () => {
    return (
      <div className="space-y-6 sm:space-y-8">
        <div>
          {isLoading ?<div className="overflow-x-auto"><Table/></div>: 
            // Use //@ts-ignore to ignore TypeScript error
  //@ts-ignore
         <BookingTable bookings={ customerBookingsInfo?.bookings } loadingState={isLoading} /> }
        </div>
      </div>
    );
  };

  return (
    <div>
      <CommonLayout>{renderSection1()}</CommonLayout>
    </div>
  );
};

export default AccountSavelists;
