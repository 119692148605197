import React, { FC } from "react";
import { formatFBdate3LETTER } from "hooks/Global/index"; 

export interface StayDatesRangeInputProps {
  className?: string;
  toursData: string;
}

const StayDatesRangeInput: FC<StayDatesRangeInputProps> = ({
  className = "flex-1",
  toursData,
}) => {
  const renderInput = () => {
    return (
      <div className={`StayDatesRangeInput relative flex ${className}`}>
        <div className="flex-grow text-left">
          <span className="block xl:text-lg font-semibold">
            
            {
              // Use //@ts-ignore to ignore TypeScript error
  //@ts-ignore
            formatFBdate3LETTER(toursData?.checkIn)} {" - "} 
            {
              // Use //@ts-ignore to ignore TypeScript error
  //@ts-ignore
            formatFBdate3LETTER(toursData?.checkOut)}
          </span>
          <span className="block mt-1 text-sm text-neutral-400 leading-none font-light">
            {"Start Date - End Date"}
          </span>
        </div>
      </div>
    );
  };

  return (
    <div>
      {renderInput()}
    </div>
  );
};

export default StayDatesRangeInput;

