import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import Select from "react-select"; // Importing react-select
import FormItem from "../../../containers/PageAddListing1/FormItem";
import {getDropDownSelectOptions} from "../../../hooks/Global";
import SuccessModal from "./success";
import { initGA, logPageView, getOrCreateUserId, logEvent } from "../../../../src/google-analytics";

 
import {
  useFetchAllToursByDestination,
  useSubmitCustomereedbackForm,
  useFetchTourDataWithName
} from "../../../hooks/tours";

interface FeedbackFormValues {
  tourName: string;
  serviceRating: string;
  briefingRating: string;
  leaderRating: string;
  itineraryRating: string;
  accommodationRating: string;
  overallExperience: string;
  likelihoodToRecommend: string;
  additionalComments: string;
}




const validationSchema = Yup.object().shape({
  tourName: Yup.string().required("Tour Name is required"),
  //heardFrom: Yup.string().required("This field is required"),
  serviceRating: Yup.string().required("Rating is required"),
  briefingRating: Yup.string().required("Rating is required"),
  leaderRating: Yup.string().required("Rating is required"),
  itineraryRating: Yup.string().required("Rating is required"),
  accommodationRating: Yup.string().required("Rating is required"),
  overallExperience: Yup.string().required("Rating is required"),
  likelihoodToRecommend: Yup.string().required("Rating is required"),
});

const FeedbackFormPage = ({ applyBtn }) => {
  const [submitData, setSubmitData] = useState<FeedbackFormValues | null>(null);


  const [isModalOpen, setIsModalOpen] = useState(false);
  const [tour4rmquery , settour4rmquery]=useState("");
 

  const {
    data: tourData,
    isLoading: isLoadingToursData,
  } = useFetchTourDataWithName( tour4rmquery );

//onsole.log(tourData)

  const formik = useFormik<FeedbackFormValues>({
    initialValues: {
      tourName: "",
      //heardFrom: "",
      serviceRating: "",
      briefingRating: "",
      leaderRating: "",
      itineraryRating: "",
      accommodationRating: "",
      //generalComments: "",
      overallExperience: "",
      likelihoodToRecommend: "",
      additionalComments: "",
    },
    validationSchema,
    onSubmit: (values) => {
      setSubmitData(values);
     //console.log("Form Submitted with values:", values);
      //openPopup("success", "Feedback submitted successfully!");
    },
  });



  //google analytics event trigger
  useEffect(() => {

    const queryParams = new URLSearchParams(window.location.search);
    const seltour = queryParams.get('t');
    settour4rmquery( seltour );

    initGA();
    const userId = getOrCreateUserId();
    logPageView();
    logEvent("User", "Feedback page", userId);
    
  }, []);



  const popular_destinations = [
    "11TH ANNIVERSARY",
    // "DUBAI",
    // "EUROPE",
    // "ASIA",
    // "GHANA",
    // "AFRICA",
    // "SINGAPORE - DUBAI",
  ];
  const {
    data: toursByDestination,
    isLoading: isLoadingDestination,
    alreadyFilledForm:alreadyFilled
  } = useFetchAllToursByDestination(popular_destinations);

  const {
    data: afterSavingFeedback,
    isLoading: isLoadingSavingFeedback,
  } = useSubmitCustomereedbackForm( submitData );

  const filterDataByLabel = (data, list) => {
    return data.filter(item => !list.includes(item.label));
  };
  

  let tourOptions = getDropDownSelectOptions( tourData || toursByDestination , "id" , "tourname");
  tourOptions = filterDataByLabel( tourOptions  , ["DUBAI YATCH CRUISE PARTY WITH KINAATA-17-8-24-20-8-24","CANADA TOUR PROMO (TORONTO & MONTREAL)-7-8-24-17-8-24-7-8-24-17-8-24","Barbados Charter - NO FLIGHT (3 STAR) -30-7-24-8-8-24" ,"ASIA STUDENTS SUMMER CAMP-4-8-24-18-8-24","NAIROBI - ZANZIBAR-9-9-24-16-9-24" , "DUBAI ANNIVERSARY (5 STAR)-11-8-24-21-8-24" , "DUBAI & ADDIS ABABA-1-8-24-11-8-24" , "UK STUDENTS SUMMER CAMP (P.G.L)-19-7-24-29-7-24","DUBAI ANNIVERSARY (5 STAR)-17-8-24-27-8-24","NAIROBI-DUBAI-11-8-24-21-8-24","JOBURG AND CAPETOWN (3 Star) -21-8-24-28-8-24-21-8-24-28-8-24"]);


  const sourceOptions = [
    { value: "Club Member", label:  "Club Member" },
    { value: "Facebook", label: "Facebook" },
    { value: "Instagram", label: "Instagram" },
    { value: "Twitter", label:  "Twitter" },
    { value: "LinkedIn", label: "LinkedIn" },
    { value: "Google", label: "Google" },
    { value: "Radio Advert", label:  "Radio Advert" },
    { value: "TV Advert", label: "TV Advert" },
    { value: "Brochure & Flyer", label: "Brochure & Flyer" },
    { value: "Staff", label: "Staff" },
  ];


  if (isLoadingDestination || isLoadingSavingFeedback) {
    return (
    <div className="flex justify-center items-center h-full" style={{ margin : 100 }}>
      <div className="animate-spin rounded-full border-4 border-gray-200 border-t-4 border-t-[#03844e] h-12 w-12"></div>
    </div>
    );
  }


  if(alreadyFilled=="true"){    
    return<div className="container mx-auto py-8" style={{ maxWidth: "700px", border: "1px solid #dcdcdc", borderRadius: "8px", padding: "20px", marginTop: "30px", marginBottom: "30px", boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)" }}>
    <div
    className="mb-6 p-4 bg-blue-100 text-blue-800 rounded-lg"
    style={{
      backgroundColor: "#e0f7fa",
      color: "#00796b",
      border: "1px solid #00796b",
    }}
  >
    <p className="text-start mb-1 text-lg font-medium">
     Thank you for your Feedback
    </p>
    <p className="text-start mb-6 text-gray-700 leading-relaxed">
    You can only fill out the feedback form once!
    </p>
  </div></div>
  }



  return (
<div className="container mx-auto py-8" style={{ maxWidth: "700px", border: "1px solid #dcdcdc", borderRadius: "8px", padding: "20px", marginTop: "30px", marginBottom: "30px", boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)" }}>
  <div className="text-center text-2xl font-semibold border-b border-neutral-200 dark:border-neutral-700 pb-4 mb-8">
    Customer Feedback Form
  </div>
  
  <form className="grid grid-cols-1 gap-6" onSubmit={formik.handleSubmit}>
    <p className="text-start mb-6 text-gray-700 leading-relaxed">
      Thank you for choosing Adansi Travels! We value your experience with us and would love to hear your feedback. 
      Please take a moment to complete the form below and help us serve you better.
    </p>

    <div
        className="mb-6 p-4 bg-blue-100 text-blue-800 rounded-lg"
        style={{
          backgroundColor: "#e0f7fa",
          color: "#00796b",
          border: "1px solid #00796b",
        }}
      >
        <p className="text-start mb-1 text-lg font-medium">
          Rating Guide:
        </p>
        <ul className="text-start list-disc list-inside">
          <li>1 - Poor</li>
          <li>2 - Below Average</li>
          <li>3 - Average</li>
          <li>4 - Above Average</li>
          <li>5 - Excellent</li>
        </ul>
      </div>
    
    <div className="text-start  text-gray-800 text-xl font-medium border-b border-neutral-200 dark:border-neutral-700 pb-2 mb-2">
   1. Pre tour - Pre Tour Experience Feedback 
  </div>

    <FormItem label="Tour Name *">
      <Select
        name="tourName"
        options={tourOptions}
        onChange={(option) => formik.setFieldValue("tourName", option?.value)}
        onBlur={formik.handleBlur}
        value={tourOptions.find(option => option.value === formik.values.tourName)}
        isSearchable
      />
      {formik.touched.tourName && formik.errors.tourName && (
        <div className="text-red-500 text-sm mt-1">{formik.errors.tourName}</div>
      )}
    </FormItem>

    <FormItem label="Rate the service *">
      <div role="group" aria-labelledby="serviceRating" className="flex space-x-4">
        {[1, 2, 3, 4, 5].map((val) => (
          <label key={val} className="flex items-center space-x-2">
            <input
              type="radio"
              name="serviceRating"
              value={val}
              checked={formik.values.serviceRating === String(val)}
              onChange={formik.handleChange}
              className="form-radio"
            />
            <span>{val}</span>
          </label>
        ))}
      </div>
      {formik.touched.serviceRating && formik.errors.serviceRating && (
        <div className="text-red-500 text-sm mt-1">{formik.errors.serviceRating}</div>
      )}
    </FormItem>

    <FormItem label="Was the tour briefing informative? *">
      <div role="group" aria-labelledby="briefingRating" className="flex space-x-4">
        {[1, 2, 3, 4, 5].map((val) => (
          <label key={val} className="flex items-center space-x-2">
            <input
              type="radio"
              name="briefingRating"
              value={val}
              checked={formik.values.briefingRating === String(val)}
              onChange={formik.handleChange}
              className="form-radio"
            />
            <span>{val}</span>
          </label>
        ))}
      </div>
      {formik.touched.briefingRating && formik.errors.briefingRating && (
        <div className="text-red-500 text-sm mt-1">{formik.errors.briefingRating}</div>
      )}
    </FormItem>

    <div className="text-start  text-gray-800 text-xl font-medium border-b border-neutral-200 dark:border-neutral-700 pb-2 mb-2">
   2. During tour - Tour Experience Feedback 
  </div>

    <FormItem label="Rate the tour leader *">
      <div role="group" aria-labelledby="leaderRating" className="flex space-x-4">
        {[1, 2, 3, 4, 5].map((val) => (
          <label key={val} className="flex items-center space-x-2">
            <input
              type="radio"
              name="leaderRating"
              value={val}
              checked={formik.values.leaderRating === String(val)}
              onChange={formik.handleChange}
              className="form-radio"
            />
            <span>{val}</span>
          </label>
        ))}
      </div>
      {formik.touched.leaderRating && formik.errors.leaderRating && (
        <div className="text-red-500 text-sm mt-1">{formik.errors.leaderRating}</div>
      )}
    </FormItem>

    <FormItem label="Rate the itinerary *">
      <div role="group" aria-labelledby="itineraryRating" className="flex space-x-4">
        {[1, 2, 3, 4, 5].map((val) => (
          <label key={val} className="flex items-center space-x-2">
            <input
              type="radio"
              name="itineraryRating"
              value={val}
              checked={formik.values.itineraryRating === String(val)}
              onChange={formik.handleChange}
              className="form-radio"
            />
            <span>{val}</span>
          </label>
        ))}
      </div>
      {formik.touched.itineraryRating && formik.errors.itineraryRating && (
        <div className="text-red-500 text-sm mt-1">{formik.errors.itineraryRating}</div>
      )}
    </FormItem>

    <FormItem label="Rate the accommodations *">
      <div role="group" aria-labelledby="accommodationRating" className="flex space-x-4">
        {[1, 2, 3, 4, 5].map((val) => (
          <label key={val} className="flex items-center space-x-2">
            <input
              type="radio"
              name="accommodationRating"
              value={val}
              checked={formik.values.accommodationRating === String(val)}
              onChange={formik.handleChange}
              className="form-radio"
            />
            <span>{val}</span>
          </label>
        ))}
      </div>
      {formik.touched.accommodationRating && formik.errors.accommodationRating && (
        <div className="text-red-500 text-sm mt-1">{formik.errors.accommodationRating}</div>
      )}
    </FormItem>

    <div className="text-start  text-gray-800 text-xl font-small border-b border-neutral-200 dark:border-neutral-700 pb-4 mb-2">
  3. Post tour - Overall Adansi Experience Feedback
  </div>

    <FormItem label="How was your overall experience? *">
      <div role="group" aria-labelledby="overallExperience" className="flex space-x-4">
        {[1, 2, 3, 4, 5].map((val) => (
          <label key={val} className="flex items-center space-x-2">
            <input
              type="radio"
              name="overallExperience"
              value={val}
              checked={formik.values.overallExperience === String(val)}
              onChange={formik.handleChange}
              className="form-radio"
            />
            <span>{val}</span>
          </label>
        ))}
      </div>
      {formik.touched.overallExperience && formik.errors.overallExperience && (
        <div className="text-red-500 text-sm mt-1">{formik.errors.overallExperience}</div>
      )}
    </FormItem>

    <FormItem label="How likely are you to recommend this tour? *">
      <div role="group" aria-labelledby="likelihoodToRecommend" className="flex space-x-4">
        {[1, 2, 3, 4, 5].map((val) => (
          <label key={val} className="flex items-center space-x-2">
            <input
              type="radio"
              name="likelihoodToRecommend"
              value={val}
              checked={formik.values.likelihoodToRecommend === String(val)}
              onChange={formik.handleChange}
              className="form-radio"
            />
            <span>{val}</span>
          </label>
        ))}
      </div>
      {formik.touched.likelihoodToRecommend && formik.errors.likelihoodToRecommend && (
        <div className="text-red-500 text-sm mt-1">{formik.errors.likelihoodToRecommend}</div>
      )}
    </FormItem>

    <FormItem label="General Comments or Recommendations">
      <textarea
        name="additionalComments"
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        value={formik.values.additionalComments}
        className="form-textarea mt-1 block w-full"
         // Use //@ts-ignore to ignore TypeScript error
  //@ts-ignore
        rows="3"
      />
    </FormItem>

  

<div className="mt-6 text-center">
  <button
    type="submit"
    className={`px-6 py-2 rounded-md text-white ${
      formik.isValid && formik.dirty ? 'bg-green-600 hover:bg-green-700' : 'bg-gray-400 cursor-not-allowed'
    }`}
    disabled={!formik.isValid || !formik.dirty}
  >
    Submit Feedback
  </button>
</div>

  </form>

  <SuccessModal
       // Use //@ts-ignore to ignore TypeScript error
  //@ts-ignore
          isOpen={afterSavingFeedback?.status}
          //fbPixelTriggered={fbPixelTriggered}
          onClose={() => setIsModalOpen(false)}
         // onfbPixelTriggered= {() => setfbPixelTriggered(true)}
        />

</div>

  );
};

export default FeedbackFormPage;
