import { usePaystackPayment } from "react-paystack";
const API_Keys = process.env.REACT_APP_PAYSTACK_API_KEY;

// const config = {
//     reference: (new Date()).getTime().toString(),
//     email: "user@example.com",
//     amount: 20000, //Amount is in the country's lowest currency. E.g Kobo, so 20000 kobo = N200
//     publicKey: API_Keys,
//     currency: "GHS",
// };

const PaystackHook = ({
  config,
  onClose,
  onSuccess,
}: {
  config: any;
  onClose: () => void;
  onSuccess: (reference) => void;
}) => {
  // you can call this function anything
  //console.log("before Initialize",config)

  const initializePayment = usePaystackPayment(config);
  initializePayment({ onSuccess, onClose });
  return <></>;
};

export default PaystackHook;
