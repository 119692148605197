import React, { FC } from "react";

export interface Theme2HeroProps {
  className?: string;
  children?: React.ReactNode;
  img?: string;
}

const Theme2Hero: FC<Theme2HeroProps> = ({
  className = "",
  children,
  img,
}) => {
  return (
    <div className={`nc-SectionHero2 relative ${className}`}>
   
      <div className="flex flex-wrap">
        {/* First Column */}
        <div className="w-full md:w-1/2 h-[250px] sm:h-[450px]">
          <img className="w-full h-full object-cover" src={img} alt="hero" />
        </div>
        {/* Second Column */}
        <div className="w-full md:w-1/2  sm:h-[450px] bg-[#121212] flex items-center justify-center">
          <p className="text-[18px] sm:text-[20] leading-relaxed font-light text-white text-center p-5 sm:p-12">{children}</p>
        </div>
      </div>
    </div>
  );
};

export default Theme2Hero;