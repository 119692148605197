import { createSlice } from '@reduxjs/toolkit';

const initialState = {
selected_destination:"",
selected_dates : ""
};

const tourFilterSlice = createSlice({
  name: 'Filters',
  initialState,
  reducers: {
    set_destination: (state , action ) => {
      state.selected_destination = action.payload;
    },
    set_dates: (state , action ) => {
        state.selected_dates = action.payload;
      },
  },
});

export default tourFilterSlice.reducer;
export const { set_destination , set_dates } = tourFilterSlice.actions;
