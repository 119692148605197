import React from "react";
import ContentLoader from "react-content-loader";

const ToursListingsLoader = (props) => (
  <ContentLoader 
    speed={2}
    width={1200}
    height={800}
    viewBox="0 0 1200 800"
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
    {...props}
  >
    {/* Header Section */}
    <rect x="20" y="20" rx="5" ry="5" width="1160" height="50" />

    {/* Navigation Tabs */}
    <rect x="20" y="80" rx="5" ry="5" width="180" height="30" />
    <rect x="210" y="80" rx="5" ry="5" width="180" height="30" />
    <rect x="400" y="80" rx="5" ry="5" width="180" height="30" />
    <rect x="590" y="80" rx="5" ry="5" width="180" height="30" />
    <rect x="780" y="80" rx="5" ry="5" width="180" height="30" />
    <rect x="970" y="80" rx="5" ry="5" width="210" height="30" />

    {/* Hotel Cards */}
    {/* Row 1 */}
    <rect x="20" y="120" rx="10" ry="10" width="380" height="220" />
    <rect x="410" y="120" rx="10" ry="10" width="380" height="220" />
    <rect x="800" y="120" rx="10" ry="10" width="380" height="220" />
    {/* Row 2 */}
    <rect x="20" y="350" rx="10" ry="10" width="380" height="220" />
    <rect x="410" y="350" rx="10" ry="10" width="380" height="220" />
    <rect x="800" y="350" rx="10" ry="10" width="380" height="220" />

    {/* Footer Section */}
    <rect x="20" y="580" rx="10" ry="10" width="1160" height="60" />
  </ContentLoader>
);

export default ToursListingsLoader;
