// import React from 'react';
// import { Provider } from 'react-redux';
// import store from './store';

// export function ReduxProvider({ children }){
//   return <Provider store={store}>{children}</Provider>;
// };
 

// ReduxProvider.js
import React from 'react';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import store, { persistor } from './store';
//import homepageLoader from  '../components/Adways/ContentLoaders/homepageLoader'; // Import your loader

export function ReduxProvider({ children }) {
  return (
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        {children}
      </PersistGate>
    </Provider>
  );
}
