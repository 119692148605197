import React, { FC } from "react";
import vibeImage from "../../images/whatsYourVibe.png";
import HeroSlider from "../../components/Adways/LandPackagesSlider";

export interface SectionHero2Props {
  className?: string;
  children?: React.ReactNode;
}

const SectionHero2: FC<SectionHero2Props> = ({ className = "", children }) => {
  return (
    <div className={`nc-SectionHero2 relative h-[300px] sm:h-[560px] ${className}`} style={{ }}>
      <div className="flex-grow">
        {/* <img className="w-full" src={vibeImage} alt="hero" /> */}
        <div className=" h-[250px] sm:h-[450px] md:h-auto">
          <HeroSlider />
        </div>
      </div>
      <div className="relative py-14 lg:py-20">
        <div className="relative inline-flex">
          <div className="w-screen right-20 md:right-52 inset-y-0 absolute bg-primary-500"></div>
        </div>
        <div className="hidden lg:block lg:mt-40 w-full"></div>
      </div>
    </div>
  );
};

export default SectionHero2;
