
import React, { FC, useState, useEffect } from "react";
import BgGlassmorphism from "../../../components/BgGlassmorphism/BgGlassmorphism";
import SectionHero from "./SectionHero";
import staff from "../../../images/staff.jpg";
import ourstory from "../../../images/adansi-story.jpg";
import aboutImg from "./images/about-hero-right (1).png";
import { useLocation } from 'react-router-dom';

interface PageServicesProps {}

const PageServices: FC<PageServicesProps> = () => {
  const [expandedCard, setExpandedCard] = useState<number | null>(null);

  const toggleExpanded = (index: number) => {
    setExpandedCard(expandedCard === index ? null : index);
  };

  const location = useLocation();

  useEffect(() => {
    if (location.hash) {
      const element = document.querySelector(location.hash);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [location]);


  const services = [
    {
      icon: "las la-plane",
      title: "Packaged Holidays",
      description:
        "This is our specialty service and currently industry Leader and pace setter in Ghana. In this service, we bulk together flight, visa, hotel, transfers and tours together as a single unit and sell to our clients. We have domestic tours (Ghana packages designed for residents), inbound tours(tour packages exploring Ghana for visitors) and outbound tours (packages exploring other parts of the world). The packages are delivered either as groups or Free Individual Travelers(FIT). In 2019 prior to covid, we sold around 1,500 units of international tour packages and we surpassed this record last year 2022 where we sold over 1,800 tours. We were able to put together packages for the 2022 world cup and over 150 football fans travelled with us. In 2022 during our 9th anniversary, we successfully delivered 20 groups in 2 months with over 600 travelers across the world. The most popular outbound destination we currently have are: • UAE: Dubai and Abu Dhabi • Asia: Singapore, Malaysia, Indonesia and Maldives • Africa: Kenya, South Africa, Tanzania, Mauritius, Seychelles, Rwanda, Ethiopia etc • Europe: UK, Turkey, France, Germany, Netherlands, Italy, Belgium, etc • USA: New York, Washington, Orlando, Miami and Las Vegas We have a high level of knowledge and understanding of our destinations like no other agency in Ghana as we invest heavily conducting familiarization trips to all our destinations.",
      color: "text-green-600",
    },
    {
      icon: "las la-passport",
      title: "Airline Ticketing",
      description:
        "We use three popular travel agency selling platforms, Amadeus, Galileo and Sabre to sell airline tickets of all carriers to many destinations around the globe. Our clients comprise of individual travelers and some high-profile corporates like Newmont Ghana. We endeavor to invest time in searching for low and competitive fares for our clients while at the same time ensuring better route to keep their trips stress free. Our excellent customer service after purchase to ensure clients do not miss their scheduled flights through text message reminders and other verbal communication endear our clients to us and ensures repeat business. We are IATA licensed and have qualified team of reservation specialists ",
      color: "text-green-600",
    },
    {
      icon: "las la-shield-alt",
      title: "Travel insurance",
      description:
        "We partner with Insurance companies like Enterprise insurance, Prudential insurance, GLICO and Hollard to provide travel insurance cover for our clients. We have trained staffs who are able to provide this service at the office. All our Europe tour packages are automatically covered by travel insurance while we sell insurance as optional for the other destinations.",
      color: "text-green-600",
    },
    {
      icon: "las la-search",
      title: "Visa Consultation",
      description:
        "We deliver the best service in helping our clients secure visas to destinations of their choice. Our team of experts provide guidance and counselling in preparing applicants for visa submission and interviews where applicable. This is a growing segment of our business with high yielding returns that require more attention.",
      color: "text-green-600",
    },
    {
      icon: "las la-hotel",
      title: "Hotel Bookings",
      description:
        "For travelers that just need hotel booking for their journey, we are the best go to agent as we book thousands of hotel nights each year and therefore have experience in arranging the best hotel for every kind of trip. We have B2B portals that allow us to get instant hotel confirmations for individuals and groups of all sizes",
      color: "text-green-600",
    },
    {
      icon: "las la-users",
      title: "MICE",
      description:
        "This is a growing service area we started few years back. In 2019, we successfully delivered 3 conferences to Las Vegas, Chicago and Dubai and handled logistics for CEIBS executive program at Harvard, Boston and IESE, New York. We have also handled a couple of incentive groups for prestigious companies like Prudential Life Insurance, Wilmar Africa, Vanguard Assurance, GLICO, Apotica Company, Ghana Armed Forces, Multimedia, etc. More recently in February 2022, we successfully handled over 100 people from Prudential Life for an incentive trip to Turkey and Dubai. Our many years of experience in delivering group tours have prepared us to be capable of handling more of this service and we plan to do more under this through strategic partnerships with the business community",
      color: "text-green-600",
    },
  ];

  const contactInfo = [
    {
      title: "🗺 ADDRESS",
      desc: "Visit our office located at 136, La Bawaleshie Road, East Legon American House, AccraGhana. Our team will be happy to assist you with any inquiries.",
    },
    {
      title: "💌 EMAIL",
      desc: "adansitravels@gmail.com / clients@adansitravels.com",
    },
    {
      title: "☎ PHONE",
      desc: "+233 302548674 / +233 243606648",
    },
  ];

  return (
    <div className="nc-PageServices overflow-hidden relative">
      {/* BG GLASS */}
      <BgGlassmorphism />

      <div className="container py-16 lg:py-28 space-y-16 lg:space-y-28 text-left">
        {/* HERO SECTION */}
        <SectionHero
          rightImg={aboutImg} // Update with your image path
          heading="About Us"
          subHeading="Adansi Travels is a leading travel agency based in Ghana that specializes in selling packaged
          tours to destinations around the globe. We are a leader in organizing leisure and business travels
          for individuals, groups and business organizations. The founders and employees of Adansi
          Travels are experienced travelers and travel professionals who are knowledgeable, trustworthy
          and passionate about the services they promote and offer.
          Within the growing travel industry, Adansi Travels is focused on the niche market of leisure
          and incentive travel packages. "
          btnText=""
        />

        <p className="space-y-14 lg:space-y-24 block text-base xl:text-lg text-neutral-6000 dark:text-neutral-400">
          Its primary clients are individuals, couples and affinity groups of
          middle-level income earners who travel for leisure. Seeking the
          ultimate travel experience, the target clients demand the finest
          quality activities and accommodations accompanied by a superior level
          of service. Adansi Travels have a competitive advantage over others in
          this market through its extensive connections with Destination
          Management Companies (DMCs), hotels and tour agencies in most parts of
          the world and airline companies. We have a vibrant travel club since
          2016 consisting of over 5,000 Travel enthusiasts who had all
          patronized our tour packages.
        </p>

        <div className="flex justify-center items-center w-full">
      <div className="relative w-full md:h-auto md:max-w-screen-lg border-4 border-custom-green overflow-hidden">
      <img
              src={staff}
              alt="our story"
              style={{ width: '100%', height: 'auto' }}
            />
     
      </div>
    </div>


        <div className="grid grid-cols-1 md:grid-cols-3 gap-5 md:gap-2 ">
          <div className="listingSection__wrap shadow-md adansi-yellow pl-16">
            <div>
              <h2 className="text-2xl font-semibold">Vision </h2>
              <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>

              <span className="block mt-2 text-neutral-800 dark:text-neutral-400 ">
                To become the most trusted outbound tour agency in Africa.
              </span>
            </div>

            <div className="flex justify-between lg:flex-row">
              <img
                className="w-3/4 rounded-lg"
                // src="https://media.istockphoto.com/id/1392494719/photo/woman-with-pink-suitcase-and-passport-with-boarding-pass-standing-on-passengers-ladder-of.webp?b=1&s=170667a&w=0&k=20&c=kTdrcN7Hvy2gZitrR8OgJvFWBzH1MrM_faEAGFzLJoo="
                alt=""
              />
            </div>
          </div>
          <div className="listingSection__wrap md:col-span-2 shadow-md">
            <div>
              <h2 className="text-2xl font-semibold">Mission </h2>
              <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>

              <span className="block mt-2  dark:text-neutral-400">
                To create memorable travel experiences for our clients through
                innovative packages delivered by well-motivated customer
                -oriented staff. Adansi Travels seeks to distinguish itself as a
                specialist in the field of tour packages and to be recognized
                for offering exceptional service and anticipating the needs and
                desires of its clients.{" "}
              </span>
            </div>

            {/* <div className="flex  justify-between lg:flex-row">
              <img
                className="w-1/2 rounded-lg"
                src="https://media.istockphoto.com/id/1526986072/photo/airplane-flying-over-tropical-sea-at-sunset.webp?b=1&s=170667a&w=0&k=20&c=_zGG67R5rDadWgB-FeruXnA_k2Mbwy3Iz_AbI9ld4io="
                alt=""
              />
            </div> */}
          </div>
        </div>

        {/* CORE VALUES */}

        <div className="">
          <h2 className="text-xl  font-semibold text-neutral-600 md:text-4xl xl:text-3xl dark:text-neutral-100 ">
            Our Core Values
          </h2>
          <div className="w-14 border-b border-neutral-200 dark:border-neutral-700 mb-6"></div>

          <div className="grid grid-cols-2 gap-6 md:gap-8 xl:grid-cols-4">
            {/* section 1 */}

            <div
              className={`nc-CardAuthorBox2 flex flex-col overflow-hidden bg-neutral-100 dark:bg-neutral-900 rounded-3xl hover:shadow-xl transition-shadow shadow-sm `}
              data-nc-id="CardAuthorBox2"
            >
              <div className="pt-[1px] px-6 text-center flex flex-col items-center relative -translate-y-7">
                <span className="absolute top-2 mt-8 w-12 h-12 text-2xl rounded-full mb:mb-4">
                  <i className="las la-key md:text-4xl text-3xl  text-green-700"></i>
                </span>

                <div className="mt-20">
                  <h2 className={`text-base font-medium`}>
                    <span className="line-clamp-1">Trust</span>
                  </h2>
                  <span
                    className={`block mt-1 text-sm text-neutral-500 dark:text-neutral-400 text-left`}
                  >
                    We safegauard your information with utmost care and
                    discreetion
                  </span>
                </div>
              </div>
            </div>

            {/* section 2 */}
            <div
              className={`nc-CardAuthorBox2 flex flex-col overflow-hidden bg-neutral-100 dark:bg-neutral-900 rounded-3xl hover:shadow-xl transition-shadow shadow-sm `}
              data-nc-id="CardAuthorBox2"
            >
              <div className="pt-[1px] px-6 text-center flex flex-col items-center relative -translate-y-7">
                <span className="absolute top-2 mt-8 w-12 h-12 text-2xl rounded-full mb:mb-4">
                  <i className="las la-balance-scale md:text-4xl text-3xl  text-green-700"></i>
                </span>

                <div className="mt-20">
                  <h2 className={`text-base font-medium`}>
                    <span className="line-clamp-1">Integrity</span>
                  </h2>
                  <span
                    className={`block mt-1 text-sm text-neutral-500 dark:text-neutral-400 text-left`}
                  >
                    We uphold honesty and ethical standards in every aspect of
                    our work
                  </span>
                </div>
              </div>
            </div>

            {/* section 3 */}
            <div
              className={`nc-CardAuthorBox2 flex flex-col overflow-hidden bg-neutral-100 dark:bg-neutral-900 rounded-3xl hover:shadow-xl transition-shadow shadow-sm `}
              data-nc-id="CardAuthorBox2"
            >
              <div className="pt-[1px] px-6 text-center flex flex-col items-center relative -translate-y-7">
                <span className="absolute top-2 mt-8 w-12 h-12 text-2xl rounded-full mb:mb-4">
                  <i className="las la-heart md:text-4xl text-3xl  text-green-700"></i>
                </span>

                <div className="mt-20">
                  <h2 className={`text-base font-medium`}>
                    <span className="line-clamp-1">Love</span>
                  </h2>
                  <span
                    className={`block mt-1 text-sm text-neutral-500 dark:text-neutral-400 text-left`}
                  >
                    We approach our relationships and tasks with empathy, care
                    and dedication
                  </span>
                </div>
              </div>
            </div>

            {/* section 4 */}
            <div
              className={`nc-CardAuthorBox2 flex flex-col overflow-hidden bg-neutral-100 dark:bg-neutral-900 rounded-3xl hover:shadow-xl transition-shadow shadow-sm `}
              data-nc-id="CardAuthorBox2"
            >
              <div className="pt-[1px] px-6 text-center flex flex-col items-center relative -translate-y-7">
                <span className="absolute top-2 mt-8 w-12 h-12 text-2xl rounded-full mb:mb-4">
                  <i className="las la-briefcase md:text-4xl text-3xl  text-green-700"></i>
                </span>

                <div className="mt-20">
                  <h2 className={`text-base font-medium`}>
                    <span className="line-clamp-1">Professionalism</span>
                  </h2>
                  <span
                    className={`block mt-1 text-sm text-neutral-500 dark:text-neutral-400 text-left`}
                  >
                    We maintain a high level of competence in all interactions
                    and endeavor
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* COMPANY HISTORY */}
        <div className="flex-shrink-0 grid grid-cols-1 sm:grid-cols-1 gap-12 md:grid-cols-2  w-full flex-col sm:rounded-2xl border-b sm:border-t sm:border-l sm:border-r border-neutral-200 dark:border-neutral-700 space-y-6 sm:space-y-8 pb-10  sm:p-4 xl:p-8 bg-neutral-100 pt-3 px-3">
          <div>
            <h2 className="text-2xl font-semibold">Our Story </h2>
            <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>

            <span className="block mt-2 text-neutral-500 dark:text-neutral-400 ">
              In 2012 a group of friends travelled to Dubai on a business trip.
              While there, they saw the tourism potential in that country and
              the amazing opportunity it presents to them. 7 months later, July
              2013 after their return to Ghana, Adansi Travels was formed in
              Obuasi, a municipal city in the Ashanti Region of Ghana. The name
              “Adansi” was chosen to reflect the locality where the business was
              started. In 2016 the company relocated to Accra in order to take
              advantage of the bigger market and now serving clients all over
              the country through its Accra office. The passion and dedication
              of the founders in seeing their dream realized has propelled the
              business to break many bounds and become very popular in Ghana.
            </span>
          </div>

          <div className="">
          <img
              src={ourstory}
              alt="our story"
              style={{ width: '100%', height: 'auto' }}
            />

            {/* <img
              className="w-full rounded-lg"
              src={ourstory}
              alt=""
            /> */}
          </div>
        </div>

       

        {/* SERVICE CARDS SECTION */}

        {/* SERVICES */}
        <div className="w-screen max-w-full  space-y-6 lg:space-y-5" id="services">
          <h2 className="text-3xl !leading-tight font-semibold text-neutral-900 md:text-4xl xl:text-5xl dark:text-neutral-100">
            Services
          </h2>
          <p className=" text-base xl:text-lg text-neutral-6000 dark:text-neutral-400">
            The tourism sector has a very wide range of services, and as a
            travel agency we are poised to take advantage of this to diversify
            our business in the industry. We provide the following services even
            though 80% of our business focus is on tour delivery highlighted in
            number one.
          </p>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8" style={{marginTop:35}}>
          {services.map((service, index) => (
            <div
              key={index}
              className="bg-white rounded-lg shadow-md p-6 flex flex-col items-center justify-center space-y-4 hover:shadow-lg hover:shadow-green-100"
            >
              <i
                className={`${service.icon} md:text-4xl text-3xl text-primary ${service.color}`}
              ></i>
              <h3 className="text-xl font-semibold text-gray-700">
                {service.title}
              </h3>
              <div
                className={`text-center text-gray-600 ${
                  expandedCard === index ? "" : "line-clamp-2"
                }`}
              >
                {service.description}
              </div>
              <button
                className="text-primary text-green-600 cursor-pointer"
                onClick={() => toggleExpanded(index)}
              >
                {expandedCard === index ? "View less..." : "Read more..."}
              </button>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default PageServices;
