import React, { FC } from "react";

export interface SectionHero2Props {
  className?: string;
  children?: React.ReactNode;
  img?: string;
}

const SectionHero2: FC<SectionHero2Props> = ({ className = "", children, img }) => {
  return (
    <div className={`nc-SectionHero2 relative ${className}`}>
      <div className="flex-grow">
        <img
          className="w-full h-[250px] sm:h-[450px] object-cover"
          src={img}
          alt="hero"
        />
      </div>
    </div>
  );
};

export default SectionHero2;

