import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Heading from "../../components/Heading/AdWayHeading";

//import theme2Heros from "./componetSrc/theme2HerosSection";
import { formatTripName, formatFBdate } from "hooks/Global/index";
import { useFetchHomeActiveTourByName } from "hooks/tours";
// Use //@ts-ignore to ignore TypeScript error
//@ts-ignore
import PackageInclusions from "./componetSrc/PackageInclusion";
import ImageCarousel from "./carouselSlider";
import PriceDateSection from "./LandingPageSections/PriceDateSection";
import Theme2Hero from "./LandingPageSections/theme2HerosSection";
import {
  Theme3LoaderDesktop,
  Theme3LoaderMobile,
} from "components/Adways/ContentLoaders/LandingPageLoaders";
import useWindowSize from "hooks/useWindowResize";
import Theme1Hero from "./LandingPageSections/theme1HerosSection";
import PriceInclusionSection from "./LandingPageSections/PriceInclusionSection";
import { EndStartDate, MultiDateTag } from "./componetSrc/DateComponent";
import TwoColumnImg from "./LandingPageSections/TwoColumnImgSection";
import {
  initGA,
  logPageView,
  getOrCreateUserId,
  logEvent,
} from "../../../src/google-analytics";
import { PricingTable } from "./componetSrc/PriceComponent";
import {MergerPriceTableInclusionSec, MergerUniversalPriceTableInclusion} from "./LandingPageSections/MergerPriceTableInclusion";
import { includes, merge } from "lodash";
import { getCurrencySymbol } from "hooks/Global/index";
import {SiteErrorModal} from "./LandingPageSections/SiteError";

function PageHome3() {
  // CUSTOM THEME STYLE

  const { theme, tour } = useParams(); // Fetching the ID from URL params
  //fetch tour data
  const {
    data: toursData,
    isLoading: isLoadingTour,
    hasFailed: hasFailedToLoadData,
  } = useFetchHomeActiveTourByName(tour);

  
  /*Extract text from html in toursData.Overview*/
  const extractTextFromHTML = (html: string): string => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, "text/html");
    return doc.body.textContent || "loading...";
  };
  
  
  const navigate = useNavigate();
  // Ensure images is an array, if toursData or images is undefined, default to an empty array
  // Use //@ts-ignore to ignore TypeScript error
  //@ts-ignore
  const images = (Object.keys(toursData).length > 0 && toursData?.images) || [];

  //@ts-ignore
  const tourId = (Object.keys(toursData).length > 0 && toursData?.id) || [];
  
  //@ts-ignore
  const extra_info = (Object.keys(toursData).length > 0 && toursData?.extra_info) || {
    "has_couple": true,
    "has_sharing": true,
};

//@ts-ignore
const specialSource = (Object.keys(toursData).length > 0 && toursData?.specialSource) || [];

//@ts-ignore
// const tourOverview = extractTextFromHTML(toursData?.overview);
const tourOverview = toursData?.overview || "";

  //@ts-ignore
  const middle_pitch = "" + (Object.keys(toursData).length > 0 && toursData?.landing_page.middlePitch) || "";
  
  //@ts-ignore
  const closing_pitch = "" + (Object.keys(toursData).length > 0 && toursData?.landing_page.closingPitch) || "";
  
  //@ts-ignore
  const tag = "" + (Object.keys(toursData).length > 0 && toursData?.tag) || [];
  
  //@ts-ignore
  const currency_symbol = "" + getCurrencySymbol((Object.keys(toursData).length > 0 && toursData?.startsFromCurrency) || "");
  
  // Use //@ts-ignore to ignore TypeScript error
  //@ts-ignore
  const theme_style = (Object.keys(toursData).length > 0 && toursData?.theme) || [];
  
  // Use //@ts-ignore to ignore TypeScript error
  //@ts-ignore
  const start_date = formatFBdate((Object.keys(toursData).length > 0 && toursData.checkIn) || []
);

// Use //@ts-ignore to ignore TypeScript error
//@ts-ignore
const end_date = formatFBdate((Object.keys(toursData).length > 0 && toursData.checkOut) || []
);

// Use //@ts-ignore to ignore TypeScript error
//@ts-ignore
const number_of_days = (Object.keys(toursData).length > 0 && toursData.days) || [];

// Use //@ts-ignore to ignore TypeScript error
//@ts-ignore
const tourName = (Object.keys(toursData).length > 0 && toursData?.name) || [];

// Function to get an array of startdates
//@ts-ignore
const MergerData = (Object.keys(toursData).length > 0 && toursData?.landing_page.tours2Merge) || [];

  //Handle Query Parameters
  const allowedOptions = [
    'website',
    'facebook',
    'instagram',
    'twitter',
    'linkedIn',
    'youTube',
    'whatsApp',
    'staff',
    'flyer',
    'tv',
    'radio',
  ].concat(specialSource);



  
  //Get Source name for passing to form link
  const [marketingSource, setSourceLink] = useState<string>("website");
  const formLink = "/ads/booking_form";
  
  
  const location = useLocation();
  const getQueryParam = (param) => {
    const queryParams = new URLSearchParams(location.search);
    const value = queryParams.get(param);
    if (value !== null && allowedOptions.includes(value)) {
      setSourceLink(value);
    }
  };


  function formatTripNameForMerger(text: string): string {
    const match = text.match(/\d/);
    if (match) {
        const index = text.indexOf(match[0]);
        // Return the substring before the first digit
        return text.slice(0, index).trim();
    }
    // Return the original text if no digit is found
    return text;
}

  
  //get param from url
  useEffect(() => {
    getQueryParam("ms");
  }, []);
  
  

  
  // Assign images to background_image, image1, and image2 by index
  const background_image = images[0] || null;
  const image1 = images[1] || null;
  const image2 = images[2] || null;
  
  // Create a copy of images and remove the first three elements
  const carousel_theme1_images = images.slice(1);
  
  
  // ensure the assigned images are not in the carousel_theme1_images
  const carousel_theme1_images_filtered = carousel_theme1_images.filter(
    img => img !== background_image && img !== image1 && img !== image2
  );
  
  
let mergerType = "";
// //@ts-ignore
// if(Object.keys(toursData).length > 0 && toursData?.landing_page.pagetype !== "One Tour"){
//   mergerType = ""; 
  
// } 

const getStartDates = (tours) => {
  return tours.map(tour => formatFBdate(tour.startdate));
};

// Function to get an array of names
const getTourNames = (tours) => {
  return tours.map(tour => tour.name);
};

const getTourNamesAndIds = (tours) => {
  return tours.map(tour => ({
    name: tour.name,
    id: tour.id
  }));
};
const getTourNamesAndDates = (tours) => {
  return tours.map(tour => ({
    name: tour.name,
    date: formatFBdate(tour.startdate),
  }));
};

const handleBookingClick = () => {
  let tourNames, tourNamesAndIds, tourNamesAndDates, tourDates;
    //@ts-ignore
  if(true){
    tourNames = [tourName];
    tourDates = [start_date];
    tourNamesAndIds = [{name:tourName, id:tourId}];

  }
  
  else{
    tourNames =  getTourNames(MergerData);
    tourDates = getStartDates(MergerData);
    tourNamesAndIds = getTourNamesAndIds(MergerData);
    tourNamesAndDates = getTourNamesAndDates(MergerData);
  }

  const tourIdData = {
    mergerType: mergerType,
    tourNamesAndIds: tourNamesAndIds,
    tourNamesAndDates: tourNamesAndDates,
    tourNames: tourNames,
    tourDates: tourDates,
    marketingSource: marketingSource,
    specialSource: specialSource,
  };
  navigate(formLink, { state: tourIdData });
};

//console.log(theme, tour, toursData, "-------");

let prices = [
  {
    regular: {
        single_sharing: "$1,750.00",
        couple: "$3,450.00"
      },
      clubMember: {
        single_sharing: "$1,650.00",
        couple: "$3,290.00"
      }
    },
    {
      regular: {
        single_sharing: "$1,890.00",
        couple: "$3,750.00"
      },
      clubMember: {
        single_sharing: "$1,790.00",
        couple: "$3,550.00"
      }
    }
  ];


    const [isSiteErrorModalOpen, setIsSiteErrorModalOpen] = useState(true);
  
    const showErrorModal = () => {
      setIsSiteErrorModalOpen(true);
    };
  
    const closeModal = () => {
      window.location.href = "https://adansitravels.com";
      setIsSiteErrorModalOpen(false);
    };




  // const isObjectEmpty = (obj) => {
  //   return obj && Object.keys(obj).length === 0 && obj.constructor === Object;
  // };

  useEffect(() => {
    const $body = document.querySelector("body");
    if (!$body) return;
    $body.classList.add("theme-purple-blueGrey");
    return () => {
      $body.classList.remove("theme-purple-blueGrey");
    };
  }, []);


  //google analytics event
  useEffect(() => {
    initGA();
    const userId = getOrCreateUserId();
    logPageView();
    logEvent("User", "Visited Landing Page", userId);
  }, []);


  // Use //@ts-ignore to ignore TypeScript error
  const { width } = useWindowSize();
  const isMobile = width <= 768;


  return (
    <div className="nc-PageHome3 relative overflow-hidden">
      {/* GLASSMOPHIN */}

      {theme === "1" ? ( //Style1
        isLoadingTour ? (
          isMobile ? (
            <div className="mx-auto">
              <Theme3LoaderMobile />
            </div>
          ) : (
            <div className="mx-auto">
              <Theme3LoaderDesktop />
            </div>
          )
        ) : (hasFailedToLoadData ? (
        <>
        <div className="h-screen w-screen bg-white flex items-center justify-center">

        </div>
      
      {/* Error Modal */}
      <SiteErrorModal
        isOpen={isSiteErrorModalOpen}
        onClose={closeModal}
        errorMessage="The site cannot be reached."
        details="Please try again later."
        buttonText="Goto Home"
      />
        </>): (
          <div className=" mx-auto">
            <div style={{ backgroundColor: "#090909", color: "white" }}>
              {/*Hero Section */}
              <Theme2Hero img={background_image}>
                {/* <div className="text-left">{tourOverview}</div> */}
                <div  style={{
        fontFamily: 'var(--font-display, Poppins), var(--font-body, Poppins)', textAlign: "left", lineHeight: "2.0",
      }} dangerouslySetInnerHTML={{ __html: tourOverview }} />

                <div>
                  <button
                    onClick={handleBookingClick}
                    className="bg-red-600 text-white py-3 px-6 rounded-lg shadow-md hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 mt-10"
                  >
                    Book Now
                  </button>
                </div>
              </Theme2Hero>
            </div>
            <div
              className="nc-SectionGridCategoryBox relative "
              style={{ backgroundColor: "#03844e" }}
            >
              {/**Heading with tour name */}
              <Heading isCenter={true}>
                <p
                 
                  className="text-gray-100 p-5 pb-2 sm:text-3xl text-2xl"
                >
                  {formatTripName(tour)}
                </p>
                <p
                  style={{
                    color: "white",
                    padding: 2,
                    paddingBottom: 20,
                    marginTop: -12,
                    fontSize: 18,
                    fontWeight: 200,
                  }}
                >
                  {tag}
                </p>
              </Heading>
            </div>

            <div className="w-full max-w-[950px] mx-auto mb-20 py-0 text-center">
              <div>
                <PriceDateSection
                  //@ts-ignore
                  startDate={start_date}
                  //@ts-ignore
                  endDate={end_date}
                  //@ts-ignore
                  duration={number_of_days}
                  //@ts-ignore
                  rate={toursData.rates}
                  currency_symbol={currency_symbol}
                  extra_info={extra_info}

                />
              </div>
              <div className="mx-3  sm:mx-12  text-[18px] leading-relaxed sm:mt-10 text-gray-900 item-center justify-center">
              <div className="text-left pt-10 pb-6 px-1 sm:p-4">
                 {/* {middle_pitch} */}
                 <div  style={{
        fontFamily: 'var(--font-display, Poppins), var(--font-body, Poppins)',
      }} dangerouslySetInnerHTML={{ __html: middle_pitch }} />

                 </div>

              </div>
              <button
                onClick={handleBookingClick}
                className="bg-red-600 text-white py-3 px-6 rounded-lg shadow-md hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 "
              >
                Book Now
              </button>
            </div>
            <div className="w-full pt-1 sm:max-w-[650px] mx-auto mb-20 py-0 text-center">
              <div className="mx-2">
                <PackageInclusions
                  inclusions={
                    // Use //@ts-ignore to ignore TypeScript error
                    //@ts-ignore
                    toursData.tripInclusions
                  }
                ></PackageInclusions>
              </div>
            </div>
                  <ImageCarousel
                    // Use //@ts-ignore to ignore TypeScript error
                    //@ts-ignore
                    images={carousel_theme1_images}
                  />

            <div className="mx-auto max-w-[950px] pt-10  item-center justify-center">
            <div className="text-center py-10 text-base sm:text-[18px] font-semibold">
               {/* {closing_pitch } */}
               <div  style={{
        fontFamily: 'var(--font-display, Poppins), var(--font-body, Poppins)',
      }} dangerouslySetInnerHTML={{ __html: closing_pitch }} />
                  
                 </div>
              <div className="w-full  mx-auto mb-20 py-30 text-center">
                <button
                  onClick={handleBookingClick}
                  className="bg-red-600 text-white px-6 rounded-lg shadow-md py-3 mt-3 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
                >
                  Book Now
                </button>
              </div>
            </div>
          </div>
          )
        )) : theme === "2" ? (
          isLoadingTour ? (
            isMobile ? (
            <div className="mx-auto">
              <Theme3LoaderMobile />
            </div>
          ) : (
            <div className="mx-auto">
              <Theme3LoaderDesktop />
            </div>
          )
        ): (hasFailedToLoadData ? (
          <>
          <div className="h-screen w-screen bg-white flex items-center justify-center">
  
          </div>
        
        {/* Error Modal */}
        <SiteErrorModal
          isOpen={isSiteErrorModalOpen}
          onClose={closeModal}
          errorMessage="The site cannot be reached."
          details="Please try again later."
          buttonText="Goto Home"
        />
          </>): (
          <div className=" mx-auto">
            <div style={{ backgroundColor: "#090909", color: "white" }}>
              {/*Hero Section */}
              <Theme1Hero img={background_image}></Theme1Hero>
            
            </div>
            <div
              className="nc-SectionGridCategoryBox relative "
              style={{ backgroundColor: "#03844e" }}
            >
              {/**Heading with tour name */}
              <Heading isCenter={true}>
                <p
                  style={{
                    color: "white",
                    padding: 20,
                    paddingBottom: 5,
                    fontSize: 25,
                  }}
                >
                  {formatTripName(tour)}
                </p>
                <p
                  style={{
                    color: "white",
                    padding: 2,
                    paddingBottom: 20,
                    marginTop: -12,
                    fontSize: 18,
                    fontWeight: 200,
                  }}
                >
                  {tag}
                </p>
              </Heading>
            </div>

            <div className="w-full max-w-[950px] mx-auto mb-20 py-0 text-center">
              <div >
                {/* <div className="text-left leading-relaxed text-base sm:text-[18px] m-9">{tourOverview}</div> */}
                <div className="text-left leading-relaxed text-base sm:text-[18px] m-9"  style={{
        fontFamily: 'var(--font-display, Poppins), var(--font-body, Poppins)',
      }} dangerouslySetInnerHTML={{ __html: tourOverview }} />

                <div>
                  <button
                    onClick={handleBookingClick}
                    className="bg-red-600 text-white py-3 px-6 rounded-lg shadow-md hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 mt-2 mb-10"
                  >
                    Book Now
                  </button>
                </div>
              </div>

              <TwoColumnImg img1={image1} img2={image2} />
              <div className="mx-auto grid ">
                <EndStartDate
                  startDate={start_date}
                  endDate={end_date}
                  duration={number_of_days}
                />
              </div>

             
              <div>
                <PriceInclusionSection
                  // Use //@ts-ignore to ignore TypeScript error
                  //@ts-ignore
                  inclusions={toursData.tripInclusions}
                  // Use //@ts-ignore to ignore TypeScript error
                  //@ts-ignore
                  rate={toursData.rates}
                  currency_symbol={currency_symbol}
                  extra_info={extra_info}
                  
                />
              </div>
              <div className="mx-3  leading-relaxed text-base sm:text-[18px] text-gray-900 item-center justify-center">
              <div className="text-left p-10 sm:p-4">
                 {/* {middle_pitch } */}
                 <div  style={{
        fontFamily: 'var(--font-display, Poppins), var(--font-body, Poppins)',
      }} dangerouslySetInnerHTML={{ __html: middle_pitch }} />
                  
                 </div>

              </div>
              <button
                onClick={handleBookingClick}
                className="bg-red-600 text-white py-3 px-6 rounded-lg shadow-md hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 mt-0"
              >
                Book Now
              </button>
            </div>
            <ImageCarousel
              // Use //@ts-ignore to ignore TypeScript error
              //@ts-ignore
              images={carousel_theme1_images_filtered}
            />

<div className="mx-auto pt-10 max-w-[950px] item-center justify-center">
            <div className="text-center py-10 text-base sm:text-[18px] font-semibold">
               {/* {closing_pitch } */}
               <div  style={{
        fontFamily: 'var(--font-display, Poppins), var(--font-body, Poppins)',
      }} dangerouslySetInnerHTML={{ __html: closing_pitch }} />
                  
                 </div>
              <div className="w-full mx-auto mb-20 py-30 text-center">
                <button
                  onClick={handleBookingClick}
                  className="bg-red-600 text-white px-6 rounded-lg shadow-md py-3 mt-3 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
                >
                  Book Now
                </button>
              </div>
            </div>
          </div>
            //
        )
      ) ): theme === "3" ? (
        isLoadingTour ? (
          isMobile ? (
            <div className="mx-auto">
              <Theme3LoaderMobile />
            </div>
          ) : (
            <div className="mx-auto">
              <Theme3LoaderDesktop />
            </div>
          )
        ) : (hasFailedToLoadData ? (
          <>
          <div className="h-screen w-screen bg-white flex items-center justify-center">
  
          </div>
        
        {/* Error Modal */}
        <SiteErrorModal
          isOpen={isSiteErrorModalOpen}
          onClose={closeModal}
          errorMessage="The site cannot be reached."
          details="Please try again later."
          buttonText="Goto Home"
        />
          </>): (
          <div className=" mx-auto">
            <div style={{ backgroundColor: "#090909", color: "white" }}>
              {/*Hero Section */}
              <Theme1Hero img={images[0]}></Theme1Hero>
            </div>
            <div
              className="nc-SectionGridCategoryBox relative "
              style={{ backgroundColor: "#03844e" }}
            >
              {/**Heading with tour name */}
              <Heading isCenter={true}>
                <p
                  style={{
                    color: "white",
                    padding: 20,
                    paddingBottom: 5,
                    fontSize: 25,
                  }}
                >
                  {formatTripName(tour)}
                </p>
                <p
                  style={{
                    color: "white",
                    padding: 2,
                    paddingBottom: 20,
                    marginTop: -12,
                    fontSize: 18,
                    fontWeight: 200,
                  }}
                >
                  {tag}
                </p>
              </Heading>
            </div>

            <div className="w-full max-w-[950px] mx-auto mb-20 py-0 text-center">
              <div className="text-left m-9">
                {/* <div >{tourOverview}</div> */}
                <div  style={{
        fontFamily: 'var(--font-display, Poppins), var(--font-body, Poppins)',
      }} dangerouslySetInnerHTML={{ __html: tourOverview }} />

                <div>
                  <button
                    onClick={handleBookingClick}
                    className="bg-red-600 text-white py-3 px-6 rounded-lg shadow-md hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 mt-2 mb-10"
                  >
                    Book Now
                  </button>
                </div>
            
              <TwoColumnImg img1={image1} img2={image2} />
              <div
                className="mx-auto sm:max-w-[768px] md:max-w-[1924px] p-1 py-4 sm:px-4"
                style={{textAlign: "center" }}
              >

              <div className="mx-auto grid ">
                <EndStartDate
                  startDate={start_date}
                  endDate={end_date}
                  duration={number_of_days}
                />
              </div>
              </div>
              
              
                < MergerPriceTableInclusionSec 
                // Use //@ts-ignore to ignore TypeScript error
                  //@ts-ignore
                prices={prices} 
                // Use //@ts-ignore to ignore TypeScript error
                  //@ts-ignore
                  inclusions={toursData.tripInclusions}/>
              </div>

              <div className="mx-3  sm:mx-12 text-base text-gray-900 item-center justify-center">
              <div className="text-left p-8 sm:p-4">
                {/* {middle_pitch } */}
                <div  style={{
        fontFamily: 'var(--font-display, Poppins), var(--font-body, Poppins)',
      }} dangerouslySetInnerHTML={{ __html: middle_pitch }} />
                  
                 </div>

              </div>

              <button
                onClick={handleBookingClick}
                className="bg-red-600 text-white py-3 px-6 rounded-lg shadow-md hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 mt-0"
              >
                Book Now
              </button>
            </div>
            <ImageCarousel
              // Use //@ts-ignore to ignore TypeScript error
              //@ts-ignore
              images={images}
            />

          <div className="mx-auto max-w-[950px] pt-0 item-center justify-center">
            <div className="text-center text-base font-semibold">
            {/* {closing_pitch } */}
            <div  style={{
        fontFamily: 'var(--font-display, Poppins), var(--font-body, Poppins)',
      }} dangerouslySetInnerHTML={{ __html: closing_pitch }} />
                  
                 </div>
              <div className="w-full  mx-auto mb-20 py-30 text-center">
                <button
                  onClick={handleBookingClick}
                  className="bg-red-600 text-white px-6 rounded-lg shadow-md py-3 mt-3 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
                >
                  Book Now
                </button>
              </div>
            </div>
          </div>
            //


        )
      )) : theme === "4" ? ( //Style1
        isLoadingTour ? (
          isMobile ? (
            <div className="mx-auto">
              <Theme3LoaderMobile />
            </div>
          ) : (
            <div className="mx-auto">
              <Theme3LoaderDesktop />
            </div>
          )
        ) : (hasFailedToLoadData ? (
          <>
          <div className="h-screen w-screen bg-white flex items-center justify-center">
  
          </div>
        
        {/* Error Modal */}
        <SiteErrorModal
          isOpen={isSiteErrorModalOpen}
          onClose={closeModal}
          errorMessage="The site cannot be reached."
          details="Please try again later."
          buttonText="Goto Home"
        />
          </>): (
          <div className=" mx-auto">
            <div style={{ backgroundColor: "#090909", color: "white" }}>
              {/*Hero Section */}
              <Theme1Hero img={images[0]}></Theme1Hero>
            </div>
            <div
              className="nc-SectionGridCategoryBox relative "
              style={{ backgroundColor: "#03844e" }}
            >
              {/**Heading with tour name */}
              <Heading isCenter={true}>
                <p
                  style={{
                    color: "white",
                    padding: 20,
                    paddingBottom: 5,
                    fontSize: 25,
                  }}
                >
                  {formatTripName(tour)}
                </p>
                <p
                  style={{
                    color: "white",
                    padding: 2,
                    paddingBottom: 20,
                    marginTop: -12,
                    fontSize: 18,
                    fontWeight: 200,
                  }}
                >
                  {tag}
                </p>
              </Heading>
            </div>

            <div className="w-full max-w-[950px] mx-auto mb-20 py-0 text-center">
              <div>
                {/* <div >{tourOverview}</div> */}
                <div className="text-left m-9" style={{
        fontFamily: 'var(--font-display, Poppins), var(--font-body, Poppins)',
      }} dangerouslySetInnerHTML={{ __html: tourOverview }} />

                <div>
                  <button
                    onClick={handleBookingClick}
                    className="bg-red-600 text-white py-3 px-6 rounded-lg shadow-md hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 mt-2 mb-10"
                  >
                    Book Now
                  </button>
                </div>
              </div>

              <TwoColumnImg img1={image1} img2={image2} />
              <div className="">
                <div className="mx-auto grid p-6 mt-4">
                  <div className="text-3xl text-green-700 font-semibold">
                    Available Dates
                  </div>
                  <MultiDateTag className="text-gray-700" dates={[] /**for date merger */}/>
                </div>

                <div>
                  <PriceInclusionSection
                    // Use //@ts-ignore to ignore TypeScript error
                    //@ts-ignore
                    inclusions={toursData.tripInclusions}
                    // Use //@ts-ignore to ignore TypeScript error
                    //@ts-ignore
                    rate={toursData.rates}
                  />
                </div>
              </div>
              <div className="mx-3  sm:mx-12 text-base text-gray-900 item-center justify-center">
              <div className="text-left p-8 sm:p-4" > 
                {/* {middle_pitch } */}
                <div  style={{
        fontFamily: 'var(--font-display, Poppins), var(--font-body, Poppins)',
      }} dangerouslySetInnerHTML={{ __html: middle_pitch }} />
                  
                 </div>

              </div>
              <button
                onClick={handleBookingClick}
                className="bg-red-600 text-white py-3 px-6 rounded-lg shadow-md hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 mt-10"
              >
                Book Now
              </button>
            </div>
            <ImageCarousel
              // Use //@ts-ignore to ignore TypeScript error
              //@ts-ignore
              images={images}
            />

            <div className="mx-auto max-w-[950px] pt-10 item-center justify-center">
              <div className="text-center text-base font-semibold">
                {/* {closing_pitch } */}
                <div  style={{
        fontFamily: 'var(--font-display, Poppins), var(--font-body, Poppins)',
      }} dangerouslySetInnerHTML={{ __html: closing_pitch }} />
                  
             
                  
                </div>
                <div className="w-full mx-auto mb-20 py-30 text-center">
                  <button
                    onClick={handleBookingClick}
                    className="bg-red-600 text-white px-6 rounded-lg shadow-md py-3 mt-3 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
                   >
                  Book Now
                </button>
              </div>
            </div>
          </div>
        )
      ) ): theme === "5" ? (
        isLoadingTour ? (
          isMobile ? (
            <div className="mx-auto">
              <Theme3LoaderMobile />
            </div>
          ) : (
            <div className="mx-auto">
              <Theme3LoaderDesktop />
            </div>
          )
        ) : (hasFailedToLoadData ? (
          <>
          <div className="h-screen w-screen bg-white flex items-center justify-center">
  
          </div>
        
        {/* Error Modal */}
        <SiteErrorModal
          isOpen={isSiteErrorModalOpen}
          onClose={closeModal}
          errorMessage="The site cannot be reached."
          details="Please try again later."
          buttonText="Goto Home"
        />
          </>):(
          <div className=" mx-auto">
            <div style={{ backgroundColor: "#090909", color: "white" }}>
              {/*Hero Section */}
              <Theme1Hero img={images[0]}></Theme1Hero>
            </div>
            <div
              className="nc-SectionGridCategoryBox relative "
              style={{ backgroundColor: "#03844e" }}
            >
              {/**Heading with tour name */}
              <Heading isCenter={true}>
                <p
                  style={{
                    color: "white",
                    padding: 20,
                    paddingBottom: 5,
                    fontSize: 25,
                  }}
                >
                  {formatTripNameForMerger(tour)}
                </p>
                <p
                  style={{
                    color: "white",
                    padding: 2,
                    paddingBottom: 20,
                    marginTop: -12,
                    fontSize: 18,
                    fontWeight: 200,
                  }}
                >
                  {tag}
                </p>
              </Heading>
            </div>

            <div className="w-full max-w-[950px] mx-auto mb-20 py-0 text-center">
              <div>
                {/* <div">{tourOverview}</div> */}
                <div className="text-left m-9"
      style={{
        fontFamily: 'var(--font-display, Poppins), var(--font-body, Poppins)',
      }}
      dangerouslySetInnerHTML={{ __html: tourOverview }}
    />
                <div>
                  <button
                    onClick={handleBookingClick}
                    className="bg-red-600 text-white py-3 px-6 rounded-lg shadow-md hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 mt-2 mb-10"
                  >
                    Book Now
                  </button>
                </div>
            
                <TwoColumnImg img1={image1} img2={image2} />
              <div
                className="mx-auto sm:max-w-[768px] md:max-w-[1924px] p-1 py-4 sm:px-4"
                style={{textAlign: "center" }}
              >

            
              </div>
              
              
                < MergerUniversalPriceTableInclusion
                // Use //@ts-ignore to ignore TypeScript error
                  //@ts-ignore
                tableData={MergerData}
                // Use //@ts-ignore to ignore TypeScript error
                  //@ts-ignore
                  inclusions={toursData.tripInclusions}/>
              </div>

              <div className="mx-3  leading-relaxed text-base sm:text-[18px] text-gray-900 item-center justify-center">
              <div className="text-left p-8 sm:p-4">
                 {/* {middle_pitch } */}
                 <div style={{
        fontFamily: 'var(--font-display, Poppins), var(--font-body, Poppins)',
      }} dangerouslySetInnerHTML={{ __html: middle_pitch }} />
                  
                 </div>

              </div>
              <button
                onClick={handleBookingClick}
                className="bg-red-600 text-white py-3 px-6 rounded-lg shadow-md hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 mt-0"
              >
                Book Now
              </button>
            </div>
            <ImageCarousel
              // Use //@ts-ignore to ignore TypeScript error
              //@ts-ignore
              images={images}
            />

          <div className="mx-auto max-w-[950px] pt-10 item-center justify-center">
            <div className="text-center p-10 text-base font-semibold">
            {/* {closing_pitch } */}
            <div style={{
        fontFamily: 'var(--font-display, Poppins), var(--font-body, Poppins)',
      }} dangerouslySetInnerHTML={{ __html: closing_pitch }} />
                  
                 </div>
              <div className="w-full mx-auto mb-20 py-30 text-center">
                <button
                  onClick={handleBookingClick}
                  className="bg-red-600 text-white px-6 rounded-lg shadow-md py-3 mt-3 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
                >
                  Book Now
                </button>
              </div>
            </div>
          </div>
            //


        )
      )) : null}

      {/* <div className="container relative space-y-24 mb-24 ">
      <SectionGridCategoryBox />
      </div>
      */}

      {/* <div className="container relative space-y-24 mb-24 ">
        <div className="grid grid-cols-12 gap-6">
        <div className="col-span-12 sm:col-span-6 lg:col-span-4 flex">
        <CardCategory6 taxonomy={DEMO_CATS_2[0]} />
        </div>
        <div className="col-span-12 sm:col-span-6 lg:col-span-4 grid grid-rows-2 gap-6">
        <CardCategory6 taxonomy={DEMO_CATS_2[3]} />
        <CardCategory6 taxonomy={DEMO_CATS_2[1]} />
        </div>
        <div className="col-span-12 sm:col-span-6 lg:col-span-4 flex">
        <CardCategory6 taxonomy={DEMO_CATS_2[4]} />
        </div>
        </div>
        
        <SectionGridCategoryBox />
        
        <div className="relative py-16">
        <BackgroundSection />
        <SectionGridAuthorBox boxCard="box2" />
        </div>
        
        <SectionHowItWork />
        
        <div className="relative py-16">
        <BackgroundSection />
        </div>
        <SectionSubscribe2 />
        </div> */}
    </div>
  );
}
//}
export default PageHome3;
