import React from "react";

interface ServiceCardProps {
  icon: string;
  title: string;
  description: string;
}

const ServiceCard: React.FC<ServiceCardProps> = ({ icon, title, description }) => (
  <div className="bg-gray-800  rounded-lg shadow-lg max-w-[300px] mx-2 my-4 md:my-0 overflow-hidden flex flex-col p-2 text-left">
    <div className="w-full h-[180px] overflow-hidden">
      <img src={icon} alt={`${title} icon`} className="w-full h-full object-cover" />
    </div>
    <div className="p-2">
      <h3 className="text-lg font-bold text-white mb-2">{title}</h3>
      <p className="text-sm text-white leading-snug m-0">{description}</p>
    </div>
  </div>
);

export default ServiceCard;
