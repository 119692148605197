import React, { useState } from "react";

interface FAQItem {
  question: string;
  answer: string;
}

interface AccordionProps {
  items: FAQItem[];
}

const Accordion: React.FC<AccordionProps> = ({ items }) => {
  const [activeIndex, setActiveIndex] = useState<number | null>(null);

  const toggleAccordion = (index: number) => {
    setActiveIndex(index === activeIndex ? null : index);
  };

  return (
    <div className="accordion w-full">
      {items.map((item, index) => (
        <div
          key={index}
          className="accordion-item border-b border-gray-200 dark:border-gray-700"
        >
          <button
            className="accordion-header w-full flex justify-between items-center py-4 text-left text-md font-medium text-gray-700 dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-800 focus:outline-none"
            onClick={() => toggleAccordion(index)}
          >
            <h3 className="accordion-title text-md">{item.question}</h3>
            <svg
              className={`accordion-icon w-5 h-5 transform transition-transform duration-300 ${
                index === activeIndex ? "rotate-180" : ""
              }`}
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <polyline points="6 9 12 15 18 9"></polyline>
            </svg>
          </button>
          {index === activeIndex && (
            <div className="accordion-content py-4 text-gray-500 dark:text-gray-400">
              <p>{item.answer}</p>
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default Accordion;
