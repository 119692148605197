import React, { FC } from "react";
import {PriceTagRnCRate} from "../componetSrc/PriceComponent";
import {
  getCurrencySymbol,
  formatAmount,
  formatFBdate,
  formatTripName,
} from "hooks/Global/index";

export interface PriceInclusionSectionProps {
  className?: string;
  children?: React.ReactNode;
  inclusions?: [];
  currency_symbol?: string;
  extra_info?: {
    "has_couple": boolean,
    "has_sharing": boolean
}
  rate?: {
    childWithBed: 0;
    couple: 0;
    childWithoutBed: 0;
    singleSupplement: 0;
    adult: 0;
    infant: 0;
  };
}

const PriceInclusionSection: FC<PriceInclusionSectionProps> = ({
  className = "",
  children,
  currency_symbol,
  inclusions = [{ item: "", available: true, type: "" }],
  rate,
  extra_info,
  
}) => {
  return (
    <div className="container mx-auto bg-gray-50 p-4">
      <h4 className="text-[25px] text-green-700 font-semibold pt-4">
        Package Pricing
      </h4>
      <div className="text-black-800 text-[18px] pt-2">
        Find the Perfect Inclusions and Fit for Your Budget and Ideal Vacation
      </div>
      <div className="grid grid-cols-1 md:justify-center items-center  md:grid-cols-2 gap-1 md:gap-4 md:p-8">
        <div className="p-4">
          {/* Content for the second column */}
          <PriceTagRnCRate
            className="flex flex-col bg-white pt-4 pb-8 md:py-10 my-1 md:my-10 rounded-lg "
            sharingRate={formatAmount(rate?.adult || 0)}
            coupleRate={formatAmount(rate?.couple || 0)}
            currency_symbol={currency_symbol}
            extra_info={extra_info}
          />
        </div>
        <div className="p-4">
          {/* Content for the first column */}
          <div className="w-full pt-8 max-w-[650px] mx-auto  py-0 text-center">
            <div className="max-w-full p-6 bg-white shadow-md">
              <h2 className="text-2xl text-green-800 font-bold mb-4">
                Cost Inclusions
              </h2>
              <ul className="list-disc list-inside">
                {inclusions.map((inclusion, index) => (
                  <li
                    key={index}
                    className="flex items-center space-x-2 py-2 border-t border-b border-gray-300"
                  >
                    <svg
                      aria-hidden="true"
                      className="w-6 h-6 text-green-600"
                      viewBox="0 0 448 512"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill="#03844e"
                        d="M400 32H48C21.49 32 0 53.49 0 80v352c0 26.51 21.49 48 48 48h352c26.51 0 48-21.49 48-48V80c0-26.51-21.49-48-48-48zm0 400H48V80h352v352zm-35.864-241.724L191.547 361.48c-4.705 4.667-12.303 4.637-16.97-.068l-90.781-91.516c-4.667-4.705-4.637-12.303.069-16.971l22.719-22.536c4.705-4.667 12.303-4.637 16.97.069l59.792 60.277 141.352-140.216c4.705-4.667 12.303-4.637 16.97.068l22.536 22.718c4.667 4.706 4.637 12.304-.068 16.971z"
                      ></path>
                    </svg>
                    <span>{inclusion.item}</span>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PriceInclusionSection;
