import React, { FC, useEffect, useState } from "react";
import Logo from "shared/Logo/lpLogo";
import { useLocation } from "react-router-dom";
import MarshalHeader from "./AdansiMarshallHeader";

interface Header3Props {
  className?: string;
}

const Header3: FC<Header3Props> = ({ className = "" }) => {
  const location = useLocation();
  const isTermsPage = location.pathname === "/terms-and-conditions";

  const [TnCClass, setTnCClass] = useState("");

  useEffect(() => {
    if (isTermsPage) {
      setTnCClass("justify-left md:justify-center ml-5");
    } else {
      setTnCClass("justify-center");
    }
  }, [isTermsPage]);

  if (location.pathname === "/career_in_travel") {

    return (
      <>
      <MarshalHeader/>
      </>
    );
  }

  return (
    <header className={`top-0 z-40 ${className}`}>
      <div
        className={`relative px-4 lg:container h-[100px] flex items-center `}
      >
        <div className={`absolute inset-0 flex items-center ${TnCClass}`}>
          <Logo />
        </div>
        <div className="absolute left-0 z-10 flex items-center">
          {/* Other left-aligned elements can go here */}
        </div>
        <div className="absolute right-0 z-10 flex items-center">
          {/* Other right-aligned elements can go here */}
        </div>
      </div>
    </header>
  );
};

export default Header3;
