import React, { FC } from "react";
import Card from "./Card"
import Hero from "../../Adways/visa/Hero";
import vibeImage from "../../../images/slider-img/captown-seaside2.jpg";

export interface ToursPageProps {}
const ToursPage: FC<ToursPageProps> = ({}) => {

  return (
    <div>
        <div className={`nc-SectionHero relative`} style={{ height: "100%", maxHeight: "500px" }}>
          <Hero img={vibeImage} />
        </div>

        

    <div className="container relative">
      <Card  />
    </div></div>
  );
};

export default ToursPage;
