export const getCurrencySymbol = ( currencyCode ) => {

  if (!currencyCode) {
    return ''; // Return an empty string or some default value if currencyCode is undefined or empty
  }

  const currencySymbols = {
      USD: '$',
      EUR: '€',
      GBP: '£',
      INR: '₹',
      GHS: '₵', // Ghanaian Cedi
      // Add more currency codes and symbols as needed
    };
    // Convert currency code to uppercase
    const uppercaseCurrencyCode = currencyCode.toUpperCase();
    // Return the symbol corresponding to the uppercase currency code, or the currency code itself if not found
    return currencySymbols[uppercaseCurrencyCode] || uppercaseCurrencyCode;
  
}

export const Nationalities = ()=> {
  let Nationalities = [
    { id: "0", name: "ghanaian" },
    { id: "1", name: "afghan" },
    { id: "2", name: "albanian" },
    { id: "3", name: "algerian" },
    { id: "4", name: "american" },
    { id: "5", name: "andorran" },
    { id: "6", name: "angolan" },
    { id: "7", name: "antiguan" },
    { id: "8", name: "argentine" },
    { id: "9", name: "armenian" },
    { id: "10", name: "australian" },
    { id: "11", name: "austrian" },
    { id: "12", name: "azerbaijani" },
    { id: "13", name: "bahamian" },
    { id: "14", name: "bahraini" },
    { id: "15", name: "bangladeshi" },
    { id: "16", name: "barbadian" },
    { id: "17", name: "barbudan" },
    { id: "18", name: "batswana" },
    { id: "19", name: "belarusian" },
    { id: "20", name: "belgian" },
    { id: "21", name: "belizean" },
    { id: "22", name: "beninese" },
    { id: "23", name: "bhutanese" },
    { id: "24", name: "bolivian" },
    { id: "25", name: "bosnian" },
    { id: "26", name: "brazilian" },
    { id: "27", name: "british" },
    { id: "28", name: "bruneian" },
    { id: "29", name: "bulgarian" },
    { id: "30", name: "burkinabe" },
    { id: "31", name: "burmese" },
    { id: "32", name: "burundian" },
    { id: "33", name: "cabo verdean" },
    { id: "34", name: "cambodian" },
    { id: "35", name: "cameroonian" },
    { id: "36", name: "canadian" },
    { id: "37", name: "central african" },
    { id: "38", name: "chadian" },
    { id: "39", name: "chilean" },
    { id: "40", name: "chinese" },
    { id: "41", name: "colombian" },
    { id: "42", name: "comoran" },
    { id: "43", name: "congolese" },
    { id: "44", name: "costa rican" },
    { id: "45", name: "croatian" },
    { id: "46", name: "cuban" },
    { id: "47", name: "cypriot" },
    { id: "48", name: "czech" },
    { id: "49", name: "danish" },
    { id: "50", name: "djiboutian" },
    { id: "51", name: "dominican" },
    { id: "52", name: "dutch" },
    { id: "53", name: "east timorese" },
    { id: "54", name: "ecuadorean" },
    { id: "55", name: "egyptian" },
    { id: "56", name: "emirati" },
    { id: "57", name: "equatorial guinean" },
    { id: "58", name: "eritrean" },
    { id: "59", name: "estonian" },
    { id: "60", name: "ethiopian" },
    { id: "61", name: "fijian" },
    { id: "62", name: "filipino" },
    { id: "63", name: "finnish" },
    { id: "64", name: "french" },
    { id: "65", name: "gabonese" },
    { id: "66", name: "gambian" },
    { id: "67", name: "georgian" },
    { id: "68", name: "german" },
    { id: "69", name: "greek" },
    { id: "70", name: "grenadian" },
    { id: "71", name: "guatemalan" },
    { id: "72", name: "guinea-bissauan" },
    { id: "73", name: "guinean" },
    { id: "74", name: "guyanese" },
    { id: "75", name: "haitian" },
    { id: "76", name: "herzegovinian" },
    { id: "77", name: "honduran" },
    { id: "78", name: "hungarian" },
    { id: "79", name: "icelandic" },
    { id: "80", name: "indian" },
    { id: "81", name: "indonesian" },
    { id: "82", name: "iranian" },
    { id: "83", name: "iraqi" },
    { id: "84", name: "irish" },
    { id: "85", name: "israeli" },
    { id: "86", name: "italian" },
    { id: "87", name: "ivorian" },
    { id: "88", name: "jamaican" },
    { id: "89", name: "japanese" },
    { id: "90", name: "jordanian" },
    { id: "91", name: "kazakhstani" },
    { id: "92", name: "kenyan" },
    { id: "93", name: "kittitian and nevisian" },
    { id: "94", name: "kuwaiti" },
    { id: "95", name: "kyrgyz" },
    { id: "96", name: "laotian" },
    { id: "97", name: "latvian" },
    { id: "98", name: "lebanese" },
    { id: "99", name: "liberian" },
    { id: "100", name: "libyan" },
    { id: "101", name: "liechtensteiner" },
    { id: "102", name: "lithuanian" },
    { id: "103", name: "luxembourger" },
    { id: "104", name: "macedonian" },
    { id: "105", name: "malagasy" },
    { id: "106", name: "malawian" },
    { id: "107", name: "malaysian" },
    { id: "108", name: "maldivan" },
    { id: "109", name: "malian" },
    { id: "110", name: "maltese" },
    { id: "111", name: "marshallese" },
    { id: "112", name: "mauritanian" },
    { id: "113", name: "mauritian" },
    { id: "114", name: "mexican" },
    { id: "115", name: "micronesian" },
    { id: "116", name: "moldovan" },
    { id: "117", name: "monacan" },
    { id: "118", name: "mongolian" },
    { id: "119", name: "montenegrin" },
    { id: "120", name: "moroccan" },
    { id: "121", name: "mosotho" },
    { id: "122", name: "mozambican" },
    { id: "123", name: "namibian" },
    { id: "124", name: "nauruan" },
    { id: "125", name: "nepalese" },
    { id: "126", name: "new zealander" },
    { id: "127", name: "nicaraguan" },
    { id: "128", name: "nigerien" },
    { id: "129", name: "nigerian" },
    { id: "130", name: "north korean" },
    { id: "131", name: "norwegian" },
    { id: "132", name: "omani" },
    { id: "133", name: "pakistani" },
    { id: "134", name: "palauan" },
    { id: "135", name: "panamanian" },
    { id: "136", name: "papua new guinean" },
    { id: "137", name: "paraguayan" },
    { id: "138", name: "peruvian" },
    { id: "139", name: "polish" },
    { id: "140", name: "portuguese" },
    { id: "141", name: "qatari" },
    { id: "142", name: "romanian" },
    { id: "143", name: "russian" },
    { id: "144", name: "rwandan" },
    { id: "145", name: "saint lucian" },
    { id: "146", name: "salvadoran" },
    { id: "147", name: "samoan" },
    { id: "148", name: "san marinese" },
    { id: "149", name: "sao tomean" },
    { id: "150", name: "saudi" },
    { id: "151", name: "senegalese" },
    { id: "152", name: "serbian" },
    { id: "153", name: "seychellois" },
    { id: "154", name: "sierra leonean" },
    { id: "155", name: "singaporean" },
    { id: "156", name: "slovakian" },
    { id: "157", name: "slovenian" },
    { id: "158", name: "solomon islander" },
    { id: "159", name: "somali" },
    { id: "160", name: "south african" },
    { id: "161", name: "south korean" },
    { id: "162", name: "spanish" },
    { id: "163", name: "sri lankan" },
    { id: "164", name: "sudanese" },
    { id: "165", name: "surinamer" },
    { id: "166", name: "swazi" },
    { id: "167", name: "swedish" },
    { id: "168", name: "swiss" },
    { id: "169", name: "syrian" },
    { id: "170", name: "taiwanese" },
    { id: "171", name: "tajik" },
    { id: "172", name: "tanzanian" },
    { id: "173", name: "thai" },
    { id: "174", name: "togolese" },
    { id: "175", name: "tongan" },
    { id: "176", name: "trinidadian or tobagonian" },
    { id: "177", name: "tunisian" },
    { id: "178", name: "turkish" },
    { id: "179", name: "tuvaluan" },
    { id: "180", name: "ugandan" },
    { id: "181", name: "ukrainian" },
    { id: "182", name: "uruguayan" },
    { id: "183", name: "uzbekistani" },
    { id: "184", name: "venezuelan" },
    { id: "185", name: "vietnamese" },
    { id: "186", name: "vincentian" },
    { id: "187", name: "yemeni" },
    { id: "188", name: "zambian" },
    { id: "189", name: "zimbabwean" },
  ];

  return Nationalities;
} 

export const formatAmount = ( amount ) => {
  // Ensure the input is a number by converting it, then format with two decimal places.
  if (isNaN(amount)) {
    return 'Invalid amount';
  }
  return Number(amount).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
}

// export const formatFBdate = ( dateObject ) => {

//   if(dateObject){
//       // Extract seconds and nanoseconds from the provided object
//       const { seconds, nanoseconds } = dateObject;
      
//       // Convert seconds to milliseconds
//       const milliseconds = seconds * 1000;
      
//       // Create a new Date object
//       const date = new Date(milliseconds);
      
//       // Get the day, month, and year
//       const day = date.getDate();
//       const monthIndex = date.getMonth();
//       const year = date.getFullYear();
    
//       // Define an array of month names
//       const months = [
//         'January', 'February', 'March', 'April', 'May', 'June', 
//         'July', 'August', 'September', 'October', 'November', 'December'
//       ];
    
//       // Format the day with 'st', 'nd', 'rd', or 'th'
//       const formattedDay = day + (
//         day % 10 === 1 && day !== 11 ? 'st' :
//         day % 10 === 2 && day !== 12 ? 'nd' :
//         day % 10 === 3 && day !== 13 ? 'rd' : 'th'
//       );
    
//       // Get the month name
//       const month = months[monthIndex];
    
//       // Return the formatted date string
//       return `${formattedDay} ${month} ${year}`;
//     }
    
//   }


export const formatFBdate = (dateObject) => {
  if (dateObject) {
     // Extract either _seconds/_nanoseconds or seconds/nanoseconds
     const seconds = dateObject._seconds || dateObject.seconds;
     const nanoseconds = dateObject._nanoseconds || dateObject.nanoseconds;

    // Convert seconds to milliseconds
    const milliseconds = seconds * 1000;

    // Create a new Date object
    const date = new Date(milliseconds);

    // Get the day, month, and year
    const day = date.getDate();
    const monthIndex = date.getMonth();
    const year = date.getFullYear();

    // Define an array of month names
    const months = [
      "January", "February", "March", "April", "May", "June",
      "July", "August", "September", "October", "November", "December"
    ];

    // Format the day with 'st', 'nd', 'rd', or 'th'
    const formattedDay = day + (
      day % 10 === 1 && day !== 11 ? "st" :
      day % 10 === 2 && day !== 12 ? "nd" :
      day % 10 === 3 && day !== 13 ? "rd" : "th"
    );

    // Get the month name
    const month = months[monthIndex];

    // Return the formatted date string
    return `${formattedDay} ${month} ${year}`;
  }

  // Return an empty string or some default value if dateObject is not provided
  return '';
};

export const formatFBdate3LETTER = (dateObject) => {
  if (dateObject) {
    // Extract _seconds and _nanoseconds from the provided object
    const { _seconds, _nanoseconds } = dateObject;

    // Convert seconds to milliseconds
    const milliseconds = _seconds * 1000;

    // Create a new Date object
    const date = new Date(milliseconds);

    // Get the day and month
    const day = date.getDate();
    const month = date.toLocaleString('en-US', { month: 'short' }).toUpperCase();

    // Format the day with leading zero if necessary
    const formattedDay = String(day).padStart(2, '0');

    // Return the formatted date string
    return `${month} ${formattedDay}`;
  }

  // Return an empty string or some default value if dateObject is not provided
  return '';
};



// export const formatFBdate3LETTER = (dateObject) => {
//   console.log(dateObject)
//   if (dateObject) {
//     // Extract seconds and nanoseconds from the provided object
//     const { seconds, nanoseconds } = dateObject;

//     // Convert seconds to milliseconds
//     const milliseconds = seconds * 1000;

//     // Create a new Date object
//     const date = new Date(milliseconds);

//     // Get the day and month
//     const day = date.getDate();
//     const month = date.toLocaleString('en-US', { month: 'short' }).toUpperCase();

//     // Format the day with leading zero if necessary
//     const formattedDay = String(day).padStart(2, '0');

//     // Return the formatted date string
//     return `${month} ${formattedDay}`;
//   }

//   // Return an empty string or some default value if dateObject is not provided
//   return '';
// };



  export const formatTripName = ( tripName ) => {
    if(tripName){
      const parts = tripName?.split('-');

// Extract the ending and starting dates
const endingDateParts = parts.splice(-3); // Take the last 3 parts for the ending date
const endingDate = endingDateParts.join('-'); // Join them back to form the ending date
const startingDateParts = parts.splice(-3); // Take the next 3 parts for the starting date
const startingDate = startingDateParts.join('-'); // Join them back to form the starting date

// Extract the rest of the parts for the name
const nameParts = parts.join(' ');

// Convert the starting date to the desired format (e.g., 26 June 24)
const formattedStartingDate = new Date(startingDate).toLocaleDateString('en-GB', { day: 'numeric', month: 'long', year: 'numeric' });

// Combine the formatted name and starting date
const formattedNameAndStarting = `${nameParts}`; // ${formattedStartingDate}

return formattedNameAndStarting;
  }
}

// export const extractParameterFromUrl = ( url ) => {
//   const parameter = url.substring(url.lastIndexOf('/') + 1); // Extract the parameter after the last '/'
//   const decodedParameter = decodeURIComponent(parameter.replace(/\+/g, ' ')); // Decode URL-encoded characters and replace '+' with white space
//   return decodedParameter;
// }



// export const tour_params = ( thisPathname ) => {
// const parts = thisPathname.split("/"); // Split the pathname by "/"
// const idWithPercentEncoding = parts[parts.length - 1];
// return extractParameterFromUrl( idWithPercentEncoding )
// }


export const extractParameterFromUrl = (url) => {
  if(url){
  const parts = url.split('/'); // Split the URL by "/"
  if (parts.length > 2) {
    const parameter = parts[2]; // Get the second parameter segment
    const decodedParameter = decodeURIComponent(parameter.replace(/\+/g, ' ')); // Decode URL-encoded characters and replace '+' with white space
    return decodedParameter;
  }
  return ''; // Return empty string if the required segment does not exist
}
};

export const tour_params = (thisPathname) => {
  return extractParameterFromUrl(thisPathname);
}



export const getTotalAmount = (items) => {
  let totalAmount = 0;

  items.forEach((item) => {
    totalAmount += item.total;
  });

  return totalAmount;
};

// export const sortKeysAlphabetically = (obj) => {
//   if(obj){
//     console.log(obj , "---what there---")
//   const sortedKeys = Object?.keys(obj).sort();
//   const sortedObj = {};
//   sortedKeys.forEach((key) => {
//       sortedObj[key] = obj[key];
//   });
//   return sortedObj;
// }
// };

export const sortKeysAlphabetically = (obj) => {
  if (obj) {
    const sortedKeys = Object.keys(obj).sort();
    const sortedObj = {};
    sortedKeys.forEach((key) => {
      if (obj[key]) {
        sortedObj[key] = obj[key];
      }
    });
    return sortedObj;
  }
};



export const hasAdultOrCouple = (arr)=>{
  if(arr){
  return arr.some(item => item.category === "adult" || item.category === "couple");
}
return false;
}


export const getDropDownSelectOptions = (arrayOfObj, value, label) => {
  const selectOptions = arrayOfObj.map((item) => (
    {
      label: item[label],
      value: item[value]
    }

  ))
  return selectOptions
}