import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  bookingEngineUrl: "https://workstation.adansitravels.com",
  selected_tour:""
};

const configSlice = createSlice({
  name: 'global_config',
  initialState,
  reducers: {
    setSelectedTour: (state , action ) => {
        state.selected_tour = action.payload;
      },
  },
});

export default configSlice.reducer;
export const { setSelectedTour } = configSlice.actions;
