import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  tour_cart: [],
  tour_cart_total: 0,
  tour_info: [],
};

const cartSlice = createSlice({
  name: 'cart',
  initialState,
  reducers: {
    set_adway_cart: (state , action ) => {
      state.tour_cart = action.payload;
    },
    set_tour_cart_total: (state , action ) => {
      state.tour_cart_total = action.payload;
    },
    set_tour_info: (state , action ) => {
      state.tour_info = action.payload;
    },
  },
});

export default cartSlice.reducer;
export const { set_adway_cart, set_tour_cart_total,set_tour_info } = cartSlice.actions;
