import React, { FC } from "react";



export interface InlusionCardProps {
  className?: string;
  children?: React.ReactNode;
  inclusions?: [];
}

const PackageInclusions: FC<InlusionCardProps> = ({
  className = "",
  children,
  inclusions = [  { item: '', available: true, type: '' },],
}) => {
  return (
    <div className="max-w-full p-6 bg-white shadow-md">
  <h2 className="text-2xl text-green-800 font-bold mb-4">Cost Inclusions</h2>
  <ul className="list-disc list-inside grid grid-cols-1 sm:grid-cols-2 gap-0">
    {inclusions.map((inclusion, index) => (
      <li key={index} className="flex items-center space-x-2 py-2 border-t border-b border-gray-300">
        <svg
          aria-hidden="true"
          className="w-6 h-6 text-green-600"
          viewBox="0 0 448 512"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path fill="#03844e" d="M400 32H48C21.49 32 0 53.49 0 80v352c0 26.51 21.49 48 48 48h352c26.51 0 48-21.49 48-48V80c0-26.51-21.49-48-48-48zm0 400H48V80h352v352zm-35.864-241.724L191.547 361.48c-4.705 4.667-12.303 4.637-16.97-.068l-90.781-91.516c-4.667-4.705-4.637-12.303.069-16.971l22.719-22.536c4.705-4.667 12.303-4.637 16.97.069l59.792 60.277 141.352-140.216c4.705-4.667 12.303-4.637 16.97.068l22.536 22.718c4.667 4.706 4.637 12.304-.068 16.971z"></path>
        </svg>
        <span>{inclusion.item}</span>
      </li>
    ))}
  </ul>
</div>
  );
};

export default PackageInclusions;