import React from "react";
import hero_img from "../../../../images/marshall/main-4-marshall.jpeg";
import Carrer_in_travel from "../../../../images/marshall/carrer_in_travel.jpeg";

const HeroSection: React.FC<{ onOpenModal: () => void }> = ({ onOpenModal }) => (
  <div className=" relative flex w-full flex-col md:flex-row black-white justify-between items-center ">
  
  <div className="relative min-w-full">
  <img
    src={Carrer_in_travel}
    alt="Be Among the First to Join Our Course!"
    className="w-full md:min-w-full md:h-[600px] object-cover"
  />

  <div className="md:absolute md:top-1/4 md:left-0 w-full h-full flex flex-col items-left justify-center z-10 max-w-[700px] px-3 md:px-10">
    {/* <h1 className="text-2xl md:text-4xl font-extrabold text-center md:text-left text-darkText mb-5 leading-tight">
      Launch Your Career in Travel Sales Consultancy
    </h1> */}
    <p className="text-base px-3 py-4 md:py-0 md:text-xl text-darkText leading-relaxed text-left">
      Prepare to enter the world of travel consultancy with industry-leading
      training from Marshalls University College and Adansi
      Travels.
    </p>
    <button
      className="md:w-48  bg-button bg-red-700 text-white py-3 px-5 rounded-full mt-2 md:mt-5 hover:bg-red-800"
      onClick={() => {
        window.location.href =
          "https://marshalls.edu.gh/adansi/marshalls-adansi-application/";
      }}
    >
      Get Started
    </button>
  </div>
</div>


  </div>
);

export default HeroSection;
