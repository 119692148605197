import React, { FC } from "react";

export interface DatatagProps {
  className?: string;
  children?: React.ReactNode;
  startDate?: string;
  endDate?: string;
  duration?: string;
}

const DateTag: FC<DatatagProps> = ({
  className = "",
  children,
  startDate,
  endDate,
  duration,
}) => {
  return (
    <div
      className="flex flex-col bg-white rounded-lg shadow-md"
      style={{ paddingTop: "30px", paddingBottom: "30px" }}
    >
      <div className="flex flex-col">
        <div>
          <div className="flex justify-center flex-row">
          <svg
          width="23"
          height="23"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect
            x="3"
            y="4"
            width="18"
            height="18"
            rx="2"
            ry="2"
            stroke="green"
            strokeWidth="2"
          />
          <path
            d="M16 2V6"
            stroke="green"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M8 2V6"
            stroke="green"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M3 10H21"
            stroke="green"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
            <p className="text-md text-center text-gray-500">From</p>
          </div>
          <h2 className=" font-bold text-gray-700 pt-3 text-center pb-9">
            <div style={{ lineHeight: "1.5", fontSize: 20 }}>{startDate}</div>
          </h2>

          <div className="flex justify-center flex-row">
          <svg
          width="23"
          height="23"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect
            x="3"
            y="4"
            width="18"
            height="18"
            rx="2"
            ry="2"
            stroke="red"
            strokeWidth="2"
          />
          <path
            d="M16 2V6"
            stroke="red"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M8 2V6"
            stroke="red"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M3 10H21"
            stroke="red"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
            <p className="text-md text-center text-gray-500">To</p>
          </div>
          <h2 className=" font-bold  text-gray-700 pt-3 text-center">
            <div style={{ lineHeight: "1.5", fontSize: 20 }}>{endDate}</div>
          </h2>
        </div>
        <div className="flex mt-6 flex-col items-center mx-auto ">
          <div className="flex text-center flex-row">
            <svg
              width="23"
              height="23"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="12" cy="12" r="10" stroke="green" strokeWidth="2" />
              <path
                d="M12 6V12L15 15"
                stroke="green"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            <p className="text-md text-center text-gray-500">Duration</p>
          </div>
          <p className="text-lg pt-1 font-semibold text-gray-700">{duration} Days</p>
        </div>
      </div>
    </div>
  );
};

export interface EndStartDateProps {
  className?: string;
  children?: React.ReactNode;
  startDate?: string;
  endDate?: string;
  duration?: string;
}

const EndStartDate: FC<EndStartDateProps> = ({
  className = "",
  children,
  startDate = "",
  endDate = "",
  duration = "",
}) => {
  return (
    <div className="grid my-8 mx-auto md:m-10 place-items-noon sm:place-items-center grid-cols-1 sm:grid-cols-3 md:grid-col-3 gap-4 bg-white shadow-md p-6 rounded-md">
      <div className="flex items-left sm:items-center space-x-2">
        <svg
          width="30"
          height="30"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect
            x="3"
            y="4"
            width="18"
            height="18"
            rx="2"
            ry="2"
            stroke="green"
            strokeWidth="2"
          />
          <path
            d="M16 2V6"
            stroke="green"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M8 2V6"
            stroke="green"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M3 10H21"
            stroke="green"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
        <div>
          <p className="text-sm text-left text-gray-500">From</p>
          <p className="text-lg font-semibold text-gray-700">{startDate}</p>
        </div>
      </div>

      <div className="flex items-center space-x-2 mt-2 sm:mt-0">
        <svg
          width="30"
          height="30"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect
            x="3"
            y="4"
            width="18"
            height="18"
            rx="2"
            ry="2"
            stroke="red"
            strokeWidth="2"
          />
          <path
            d="M16 2V6"
            stroke="red"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M8 2V6"
            stroke="red"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M3 10H21"
            stroke="red"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
        <div>
          <p className="text-sm text-left text-gray-500">To</p>
          <p className="text-lg font-semibold text-gray-700">{endDate}</p>
        </div>
      </div>

      <div className="flex items-center space-x-2 mt-2 sm:mt-0">
        <svg
          width="30"
          height="30"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="12" cy="12" r="10" stroke="green" strokeWidth="2" />
          <path
            d="M12 6V12L15 15"
            stroke="green"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
        <div>
          <p className="text-sm text-left text-gray-500">For</p>
          <p className="text-lg font-semibold text-gray-700">
            {duration} day(s)
          </p>
        </div>
      </div>
    </div>
  );
};

export interface MultiDateTagProps {
  className?: string;
  dates: string[];
}

const MultiDateTag: FC<MultiDateTagProps> = ({ className = "", dates }) => {
  // <div className="text-xl">
  //   Available Dates
  // </div>
  return (
    <div>
      <div className={`flex flex-col md:flex-row items-center `}>
        {dates.map((date, index) => (
          <div
            key={index}
            className="flex flex-col md:flex-row items-center bg-white rounded-lg shadow-md m-4 px-4 py-8 w-full max-w-xs"
          >
            <h2 className="text-lg font-bold text-center">
              <div className={`text-black-600 ${className}`}>{date}</div>
            </h2>
          </div>
        ))}
      </div>
    </div>
  );
};

export { DateTag, EndStartDate, MultiDateTag };
