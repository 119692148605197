// import React, { FC, useState , useEffect } from "react";
// import Logo from "shared/Logo/Logo";
// import Navigation from "shared/Navigation/Navigation";
// // import SearchDropdown from "./SearchDropdown";
// import ButtonPrimary from "shared/Button/AdwayBtn";
// import MenuBar from "shared/MenuBar/MenuBar";
// import HeroSearchForm2MobileFactory from "components/HeroSearchForm2Mobile/HeroSearchForm2MobileFactory";
// import AvatarDropdown from "./AvatarDropdown";

// import { auth } from "../../api/firebase";
// import { clearCustomerInfo } from "../../redux/slices/customerSlice";

// import { useSelector, useDispatch } from 'react-redux';
// import { useLocation, useNavigate, Link } from "react-router-dom"; 



// export interface MainNav1Props {
//   className?: string;
// }

// const MainNav1: FC<MainNav1Props> = ({ className = "" }) => {
//   const dispatch = useDispatch();
//   const router = useNavigate();
//   const [isAuthenticated, setIsAuthenticated] = useState(false);

//   useEffect(() => {
//     const unsubscribe = auth.onAuthStateChanged((user) => {
//       if (user) {
//         setIsAuthenticated(true);
//       } else {
//         setIsAuthenticated(false);
//       }
//     });
//     return () => unsubscribe();
//   }, []);

  
//     const logout = async () => {
//       try {
//         await auth.signOut();
//         setIsAuthenticated( false );
//         dispatch(clearCustomerInfo());
//         router('/');
//       } catch (error) {
//         console.error("Error logging out:", error);
//       }
//     };



//   return (
//     <div className={`nc-MainNav1 relative z-10 ${className}`}>
//       <div className="px-4 lg:container py-4 lg:py-5 relative flex justify-between items-center">
//         <div className="hidden md:flex justify-start flex-1 items-center space-x-4 sm:space-x-10">
//           <Logo />
//           <Navigation />
//         </div>

//        {/* <HeroSearchForm2MobileFactory /> */}
//         <div className="lg:hidden flex-[3] max-w-lg !mx-auto md:px-3">
//         <Logo />
         
//           <div className="flex xl:hidden items-center">
//             <div className="px-0.5" />
//             <MenuBar />
//           </div>
//         </div>


// {/* <div className="lg:hidden flex items-center justify-between max-w-lg mx-auto md:px-3 px-4">
//   <div className="flex-1">
//     <Logo />
//   </div>
//   <div className="ml-auto">
//     <MenuBar />
//   </div>
// </div> */}


//         <div className="hidden md:flex flex-shrink-0 items-center justify-end flex-1 lg:flex-none text-neutral-700 dark:text-neutral-100">
//           <div className="hidden xl:flex items-center space-x-0.5">
         
//             {/* <SearchDropdown /> */}
//             <div className="px-1" />
            
//             {isAuthenticated ? 
//              <AvatarDropdown logout={ logout } /> :
//             <div>
//             <Link
//             to="https://thebosstravelclub.com/"
//             className="
//               text-opacity-90
//               group px-4 py-2 border border-neutral-300 hover:border-neutral-400 dark:border-neutral-700 rounded-full inline-flex items-center text-sm text-gray-700 dark:text-neutral-300 font-medium hover:text-opacity-100 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75"
//           >
//             Become a Club Member
//           </Link>
//          &nbsp; <ButtonPrimary href="/login">Login</ButtonPrimary></div>
         
//         } 
           
//           </div>
       
//         </div> 
//       </div>
//     </div>
//   );
// };

// export default MainNav1;



import React, { FC, useState, useEffect } from "react";
import Logo from "shared/Logo/Logo";
import Navigation from "shared/Navigation/Navigation";
// import SearchDropdown from "./SearchDropdown";
import ButtonPrimary from "shared/Button/AdwayBtn";
import MenuBar from "shared/MenuBar/MenuBar";
import HeroSearchForm2MobileFactory from "components/HeroSearchForm2Mobile/HeroSearchForm2MobileFactory";
import AvatarDropdown from "./AvatarDropdown";

import { auth } from "../../api/firebase";
import { clearCustomerInfo } from "../../redux/slices/customerSlice";

import { useSelector, useDispatch } from 'react-redux';
import { useLocation, useNavigate, Link } from "react-router-dom"; 

export interface MainNav1Props {
  className?: string;
}

const MainNav1: FC<MainNav1Props> = ({ className = "" }) => {
  const dispatch = useDispatch();
  const router = useNavigate();
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        setIsAuthenticated(true);
      } else {
        setIsAuthenticated(false);
      }
    });
    return () => unsubscribe();
  }, []);

  const logout = async () => {
    try {
      await auth.signOut();
      setIsAuthenticated(false);
      dispatch(clearCustomerInfo());
      router('/');
    } catch (error) {
      console.error("Error logging out:", error);
    }
  };

  return (
    <div className={`nc-MainNav1 relative z-10 ${className}`}>
      <div className="px-4 lg:container py-4 lg:py-5 relative flex justify-between items-center">
        <div className="hidden md:flex justify-start flex-1 items-center space-x-4 sm:space-x-10">
          <Logo />
          <Navigation />
        </div>

        {/* Adjusted section */}
        <div className="lg:hidden flex items-center justify-between w-full md:px-3">
          <div>
            <Logo />
          </div>
          <div className="ml-auto">
            <MenuBar />
          </div>
        </div>

        <div className="hidden md:flex flex-shrink-0 items-center justify-end flex-1 lg:flex-none text-neutral-700 dark:text-neutral-100">
          <div className="hidden xl:flex items-center space-x-0.5">
            {/* <SearchDropdown /> */}
            <div className="px-1" />

            {isAuthenticated ? (
              <AvatarDropdown logout={logout} />
            ) : (
              <div>
                <Link
                  to="https://thebosstravelclub.com/"
                  className="
                    text-opacity-90
                    group px-4 py-2 border border-neutral-300 hover:border-neutral-400 dark:border-neutral-700 rounded-full inline-flex items-center text-sm text-gray-700 dark:text-neutral-300 font-medium hover:text-opacity-100 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75"
                >
                  Become a Club Member
                </Link>
                &nbsp; <ButtonPrimary href="/login">Login</ButtonPrimary>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MainNav1;
