import React, { FC, useState, Fragment, useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import AuthLoader from "../../components/Adways/ContentLoaders/AuthLoader";
import { useAuthenticateCustomers } from "../../hooks/customers";
import { useSelector, useDispatch } from "react-redux";
import { setCustomerInfo } from "../../redux/slices/customerSlice";
import facebookSvg from "images/Facebook.svg";
import twitterSvg from "images/Twitter.svg";
import googleSvg from "images/Google.svg";
import { Helmet } from "react-helmet";
import Input from "shared/Input/Input";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import NoticePopups from "../../components/Adways/NoticePopUps";
import { useLocation, useNavigate, Link } from "react-router-dom"; 


export interface PageLoginProps {}

const loginSocials = [
  {
    name: "Continue with Facebook",
    href: "#",
    icon: facebookSvg,
  },
  {
    name: "Continue with Twitter",
    href: "#",
    icon: twitterSvg,
  },
  {
    name: "Continue with Google",
    href: "#",
    icon: googleSvg,
  },
]; 

const validationSchema = Yup.object({
  email: Yup.string().email("Invalid email address").required("Email is required"),
  password: Yup.string().min(8, "Password must be at least 8 characters").required("Password is required"),
});

const PageLogin: FC<PageLoginProps> = ({}) => {
  const [loginDetails, setLoginDetails] = useState({ email: "", password: "" });
  const [isOpen, setIsOpen] = useState(false);
  const [popupType, setPopupType] = useState("success");
  const [popupMessage, setPopupMessage] = useState("");
  const router = useNavigate();


  
  const { data : userData, isLoading, error } = useAuthenticateCustomers(loginDetails.email, loginDetails.password);
  const dispatch = useDispatch();

  const openPopup = (type, message) => {
    setPopupType(type);
    setPopupMessage(message);
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  useEffect(() => {
     // Use //@ts-ignore to ignore TypeScript error
  //@ts-ignore
    if (userData?.status === false) {
        // Use //@ts-ignore to ignore TypeScript error
  //@ts-ignore
      openPopup( "error", userData?.message );
    }
  }, [userData]);

  useEffect(() => {
    // Use //@ts-ignore to ignore TypeScript error
  //@ts-ignore
    if (userData?.status === true) {
        // Use //@ts-ignore to ignore TypeScript error
  //@ts-ignore
      dispatch(setCustomerInfo(userData?.message));
      openPopup("success", "Login successful! Redirecting to your account...");
      router('/account');
    }
  }, [userData]);

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      setLoginDetails(values);
    },
  });

  return (
    <div className="nc-PageLogin">
      <div className="container mb-24 lg:mb-32">
        <h2 className="my-20 flex items-center text-2xl leading-[115%] md:text-3xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
          Login
        </h2>

        {isLoading ? (
          <div className="flex items-center justify-center h-100">
            <AuthLoader />
          </div>
        ) : (
          <div className="max-w-md mx-auto space-y-6 border border-[#03844e] shadow-lg p-8">
            <div className="relative text-center">
              <span className="relative z-10 inline-block px-4 font-medium text-sm bg-white dark:text-neutral-400 dark:bg-neutral-900">
                Welcome back, Login to access your Adansi Account
              </span>
              <div className="absolute left-0 w-full top-1/2 transform -translate-y-1/2 border border-neutral-100 dark:border-neutral-800"></div>
            </div>

            <form className="grid grid-cols-1 gap-6" onSubmit={formik.handleSubmit} style={{ marginBottom: "40px" }}>
              <label className="block">
                <span className="text-neutral-800 dark:text-neutral-200">Email Address</span>
                <Input
                  type="email"
                  name="email"
                  placeholder=""
                  className="mt-1"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.email}
                />
                {formik.touched.email && formik.errors.email ? (
                  <div className="text-red-500 text-sm mt-1">{formik.errors.email}</div>
                ) : null}
              </label>
              <label className="block">
                <span className="flex justify-between items-center text-neutral-800 dark:text-neutral-200">
                  Password
                  <Link to="/forgot-password" className="text-sm underline font-medium">
                    Forgot password?
                  </Link>
                </span>
                <Input
                  type="password"
                  name="password"
                  className="mt-1"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.password}
                />
                {formik.touched.password && formik.errors.password ? (
                  <div className="text-red-500 text-sm mt-1">{formik.errors.password}</div>
                ) : null}
              </label>
              <ButtonPrimary type="submit">Continue</ButtonPrimary>
            </form>

            <Link to="https://thebosstravelclub.com" className="text-sm underline font-medium">
              Become a club member
            </Link>
          </div>
        )}

        <NoticePopups isOpen={isOpen} closeModal={closeModal} type={popupType} message={popupMessage} />
      </div>
    </div>
  );
};

export default PageLogin;

