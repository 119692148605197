import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import ServiceSection from "./ServiceSection";
import hero_img from "../../../../images/marshall/main-4-marshall.jpeg";
import PriceSection from "./PriceSection";

const SecondarySectionWithServices: React.FC = () => (
  <div className="justify-center text-center bg-gray-50">
    {/* <div className="relative mx-auto max-w-6xl overflow-hidden py-10 md:py-20 md:px-20 px-3 ">
      {/* Text Container */}
    {/* <div className="md:max-w-[90%] mx-auto text-center mb-10 relative">
        <BgGlassmorphism />
        <h2 className="text-2xl md:text-4xl font-black text-black mb-4 leading-snug">
          Be Among the First to Join Our Course!
        </h2>
        <p className="text-lg px-3 md:px-0 text-black leading-relaxed text-left">
          Are you ready to embark on a career in travel consultancy? Our
          comprehensive <strong>Travel Sales Consultancy Course</strong>,
          crafted in partnership with industry experts, offers you everything
          you need to become a skilled travel professional. Enroll to gain
          essential skills in sales, marketing, customer service, tour
          packaging, and advanced technologies like GDS and AI.
        </p>

   
    </div>
      </div> */}

    {/* Heading */}
    <h3 className="text-xl md:text-3xl font-semibold text-black pt-10 pb-2 bg-gradient-to-r from-blue-50 via-white to-blue-100" >
      Launch Your Career in Travel Sales Consultancy with AI
    </h3>
    <span className="text-lg font-normal">First Cohort begins in January 2025</span>
    {/* <p className="mx-auto max-w-[80%] pb-10 text-base md:text-lg text-left md:text-left md:px-3">
      Are you ready to embark on a career in travel consultancy? Our
      comprehensive <strong>Travel Sales Consultancy Course</strong>, crafted in
      partnership with industry experts, offers you everything you need to
      become a skilled travel professional. Enroll to gain essential skills in
      sales, marketing, customer service, tour packaging, and advanced
      technologies like GDS and AI.
    </p> */}
    <PriceSection />

    <div className="relative w-full md:max-w-[90%] md:mx-auto md:pb-20 grid grid-cols-1 md:grid-cols-2 gap-6 md:p-6">
      {/* Left Column (Spacer or additional content) */}
      <img
        src={hero_img}
        alt="Be Among the First to Join Our Course!"
        className=" w-full md:rounded-lg mt-5 md:mt-0"
      />

      {/* Right Column (Text and Button) */}
      <div className=" flex flex-col bg-white items-start animate-fadeIn md:max-w-2xl md:mt-48 md:-ml-24 px-3 md:p-10 shadow-md rounded-sm ">
        <h3 className="text-xl pt-10 md:pt-0 md:text-3xl font-semibold text-red-600 ">
          Be Among the First to Join Our Course!
        </h3>

        {/* Paragraph */}
        {/* <p className="text-base md:text-lg text-black text-left leading-relaxed mb-6">
          Are you ready to embark on a career in travel consultancy? Our
          comprehensive <strong>Travel Sales Consultancy Course</strong>,
          crafted in partnership with industry experts, offers you everything
          you need to become a skilled travel professional. Enroll to gain
          essential skills in sales, marketing, customer service, tour
          packaging, and advanced technologies like GDS and AI.
        </p> */}
        {/* <p className="flex flex-col space-y-3 text-base md:text-lg text-black text-left leading-relaxed mb-6">
          <strong>Course Name:</strong> Travel Sales Consultancy Level 1 
          <strong>Duration :</strong> 6 months including 2 months of internship at Adansi Travels or partner agency.
          <strong>Target Candidates :</strong> Diploma Holders, HND and Degree holders
        </p> */}
        {/* <p className="flex flex-col space-y-3 text-base md:text-lg text-black text-left leading-relaxed mb-6">
        <span className="flex items-center">
          <i className="fas fa-book mr-2 text-blue-500"></i>
          <strong>Course Name:</strong>&nbsp;Travel Sales Consultancy Level 1
        </span>
        <span className="flex items-center">
          <i className="fas fa-clock mr-2 text-green-500"></i>
          <strong>Duration:</strong>&nbsp;6 months including 2 months of internship at Adansi Travels or partner agency.
        </span>
        <span className="flex items-center">
          <i className="fas fa-user-graduate mr-2 text-purple-500"></i>
          <strong>Target Candidates:</strong>&nbsp;Diploma Holders, HND and Degree holders
        </span>
      </p> */}
      <div className="max-w-md mx-auto bg-white  rounded-lg overflow-hidden ">
  <div className="p-6">
    <h2 className="text-lg md:text-3xl font-bold text-gray-900 mb-4">Course Details</h2>

<div className="text-base text-left space-y-4">
  <div className="flex flex-col">
    <label className="font-semibold text-gray-800">Course Name:</label>
    <p className="text-gray-800">Travel Sales Consultancy Level 1</p>
  </div>
  <div className="flex flex-col">
    <label className="font-semibold text-gray-800">Duration:</label>
    <p className="text-gray-800">
      6 months including 2 months of internship at Adansi Travels or partner agency.
    </p>
  </div>
  <div className="flex flex-col">
    <label className="font-semibold text-gray-800">Target Candidates:</label>
    <p className="text-gray-800">Diploma Holders, HND, and Degree holders</p>
  </div>
</div>

    <button
      className="mt-6 w-full bg-red-700 text-white py-2 px-4 rounded-lg hover:bg-red-800 transition"
      onClick={() => {
        window.location.href =
          "https://marshalls.edu.gh/adansi/marshalls-adansi-application/";
      }}
    >
      Register Now
    </button>
  </div>
</div>


        

        {/* Button
        <a
          href="https://wa.link/avjj3i"
          target="_blank"
          rel="noopener noreferrer"
          className="inline-block bg-gradient-to-r from-green-500 to-blue-500 text-white py-3 px-6 rounded-lg shadow-lg hover:opacity-90"
          >
          <span className="flex items-center">
            <i className="fas fa-star mr-2"></i> Explore our Tours
          </span>
        </a> */}
      </div>
    </div>
  </div>
);

export default SecondarySectionWithServices;
