import React, { useEffect, useRef } from "react";

const IframeContainer: React.FC = () => {
    
    const elementRef = useRef<HTMLDivElement | null>(null); // Ref for main element
    const iframeRef = useRef<HTMLIFrameElement | null>(null); // Ref for iframe
  
    useEffect(() => {
      // Hide element in the main document
      if (elementRef.current) {
        elementRef.current.style.display = "none";
      }
  
      // Hide element inside the iframe
      if (iframeRef.current) {
        const iframeDoc = iframeRef.current.contentDocument;
        if (iframeDoc) {
            console.log(iframeDoc);
          const iframeElement = iframeDoc.getElementById("destination_popup");
          if (iframeElement) {
            (iframeElement as HTMLElement).style.display = "none"; // Type assertion for HTMLElement
          } else {
            console.warn("Element with ID 'iframe_element_id' not found in iframe.");
          }
        } else {
          console.warn("Iframe document is not accessible.");
        }
      }
    }, []); // Runs after component mounts


    
  return (
    <div className="w-screen h-screen">
    
       {/* Main document element */}
       <div ref={elementRef} id="destination_popup">
        This will be hidden in the main document.
      </div>

      {/* Embedded Iframe */}
      <div id="esim_iframe" className="w-full relative flex justify-center items-center bg-gray-200">
        <iframe
         ref={iframeRef}
        id="my_iframe"
          src="https://www.worldmobile.com/doc/?coupon-code=qr3g1uyzhq"
          width="100%"
          height="900px"
          allowFullScreen
          loading="lazy"
          title="Adways ESIM"
        ></iframe>
      </div>
    </div>
  );
};

export default IframeContainer;
