import React, { FC } from "react";
import { PriceTagRnCRate, PricingTable } from "../componetSrc/PriceComponent";
import { DateTag, MultiDateTag } from "../componetSrc/DateComponent";
import { formatAmount } from "hooks/Global/index";
import PackageInclusions from "../componetSrc/PackageInclusion";
import PriceCostDateTable from "../componetSrc/PriceCostDateTable";

export interface MergerPriceTableInclusionSecProps {
  className?: string;
  children?: React.ReactNode;
  prices?: [{
    regular: {
      single_sharing: "",
      couple: ""
    },
    clubMember: {
      single_sharing: "",
      couple: ""
    }
  },
  
  
   
  ];

  inclusions?: string[];
}

const MergerPriceTableInclusionSec: FC<MergerPriceTableInclusionSecProps> = ({
  className = "",
  children,
  prices,
  inclusions = "",
}) => {
  return (
    <div className="container mx-auto bg-gray-100 sm:p-4 md:px-10">
      <h4 className="text-4xl text-green-700 font-bold pt-4">
        Package Pricing
      </h4>
      <div className="text-black-800 py-3" style={{ fontSize: "18px" }}>
        Find the Perfect Fit for Your Budget and Ideal Vacation
      </div>
      <div className="grid grid-rows-2 gap-4 p-0 sm:p4 md:p-8">
        <div className="p-0 sm:p4 md:p-9">
            <PricingTable prices={prices}/>
        </div>
        <div className="p-0 sm:p4 md:p-9">
          <PackageInclusions
            // Use //@ts-ignore to ignore TypeScript error
            //@ts-ignore
            inclusions={inclusions}
          />
        </div>
      </div>
    </div>
  );
};


export interface MergerUniversalPriceTableInclusionProps {
  className?: string;
  children?: React.ReactNode;
  tableData?: [{
      name: String,   
      id: String,     
      costing: [         
       {
          amount: Number,           
          category: String          
        },
        // Additional costing objects can follow
      ],
      currency: String,            
      enddate: {                   
        seconds: Number,           
        nanoseconds: Number        
      startdate: {                 
        seconds: Number,           
        nanoseconds: Number 
      }
    },
  }
];
  inclusions?: string[];
}

const MergerUniversalPriceTableInclusion: FC<MergerUniversalPriceTableInclusionProps> = ({
  className = "",
  children,
  tableData,
  inclusions = "",
}) => {
  return (
    <div className="container mx-auto bg-gray-100 sm:p-4 md:px-10">
      <h4 className="text-4xl text-green-700 font-bold pt-4">
        Package Pricing
      </h4>
      <div className="text-black-800 py-3" style={{ fontSize: "18px" }}>
        Find the Perfect Fit for Your Budget and Ideal Vacation
      </div>
      <div className="grid grid-rows-1 gap-2 p-0 sm:p-4 md:p-9">
        <div className="px-0 sm:px-4 md:px-9">
            <PriceCostDateTable tours={tableData}/>
        </div>
        <div className="px-0 mt-20 pt-15 sm:px-4 md:px-9">
          <PackageInclusions
            // Use //@ts-ignore to ignore TypeScript error
            //@ts-ignore
            inclusions={inclusions}
          />
        </div>
      </div>
    </div>
  );
};

export {MergerPriceTableInclusionSec ,MergerUniversalPriceTableInclusion};
