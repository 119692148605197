import { useFetchAllTours } from "hooks/tours";
import React, { useState, useEffect } from "react";

const TourSearchFilter = ({
  setSearchFilteredTours,
}: {
  setSearchFilteredTours: (tours) => void;
}) => {
  // Fetching tour data
  const { data: toursData, isLoading } = useFetchAllTours();

  // State for search query
  const [searchQuery, setSearchQuery] = useState("");

  // State for filtered tours
  const [filteredTours, setFilteredTours] = useState([]);


  // Use useEffect to filter tours whenever the search query or toursData changes
  useEffect(() => {
    if (toursData && searchQuery.length > 0) {
      const filtered = toursData.filter((tour) =>
        tour.name.toLowerCase().includes(searchQuery.toLowerCase())
      );
      setFilteredTours(filtered);
      setSearchFilteredTours(filteredTours);
      // setShowDropdown(true); // Show dropdown when query exists
    }
  
  }, [searchQuery, toursData]);


  return (
    <div className="">
      {/* Search Input */}
      <div className=" w-full md:w-[245px] mx-auto relative ">
        <div className="absolute left-3 top-1/2 transform -translate-y-1/2">
          <i className=" p-1 text-xl fas fa-search text-gray-400"></i>
        </div>
        <input
          type="text"
          className="flex items-start  justify-center pl-10 pr-10 py-3 text-sm rounded-full border border-neutral-300 "
          placeholder="Search Tour By Name ..."
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          //onFocus={() => setShowDropdown(true)} // Show dropdown on focus
        />
      </div>
    </div>
  );
};

export default TourSearchFilter;
