import { FaGlobe, FaPassport, FaLaptopCode, FaSmile, FaBalanceScale, FaLightbulb } from "react-icons/fa";

const ModulesCard = () => {
  return (
    <div className="max-w-xl mx-auto bg-gradient-to-r from-blue-50 via-white to-blue-100 shadow-lg rounded-lg overflow-hidden p-10">
      
        <h2 className="text-xl font-bold text-gray-900 mb-4 text-center">
          Course Modules
        </h2>
        <ul className="space-y-4">
          <li className="flex items-start">
            <FaGlobe className="text-blue-500 mr-3 mt-1" />
            <span className="text-base text-gray-800 font-medium">
              Overview of the Travel Industry
            </span>
          </li>
          <li className="flex items-start">
            <FaPassport className="text-green-500 mr-3 mt-1" />
            <span className="text-base text-gray-800 font-medium">
              Travel Documentation
            </span>
          </li>
          <li className="flex items-start">
            <FaLaptopCode className="text-purple-500 mr-3 mt-1" />
            <span className="text-base text-gray-800 font-medium">
              Technology in Travel Consultancy
            </span>
          </li>
          <li className="flex items-start">
            <FaSmile className="text-yellow-500 mr-3 mt-1" />
            <span className="text-base text-gray-800 font-medium">
              Customer Service in Travel Consultancy
            </span>
          </li>
          <li className="flex items-start">
            <FaBalanceScale className="text-orange-500 mr-3 mt-1" />
            <span className="text-base text-gray-800 font-medium">
              Ethics and Professionalism in Travel Consultancy
            </span>
          </li>
          <li className="flex items-start">
            <FaLightbulb className=" text-red-500 mr-3 mt-1" />
            <span className="text-base text-gray-800 font-medium">
              Emerging Trends in Travel Consultancy
            </span>
          </li>
        </ul>

        <div
          className="mt-6 w-full bg-blue-500 text-white py-2 px-4 rounded-lg hover:bg-blue-600 transition"
        ></div>
    </div>
    
    
  );
};

export default ModulesCard;
