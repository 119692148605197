import React, { useState, useEffect, useRef } from 'react';

const AdBannerSlider = ({ banners }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const adSliderRef = useRef(null);

  useEffect(() => {
    const adSlider = adSliderRef.current;

    // Auto-scroll function
    const scrollNext = () => {
      setCurrentIndex((prevIndex) => (prevIndex === banners.length - 1 ? 0 : prevIndex + 1));
    };

    // Interval for auto-scrolling
    const intervalId = setInterval(scrollNext, 3000); // Adjust interval time (e.g., 3000ms = 3 seconds)

    // Clean up on component unmount
    return () => clearInterval(intervalId);
  }, [banners.length]); // Re-run effect when banners length changes

  useEffect(() => {
    const adSlider = adSliderRef.current;
    adSlider.scrollLeft = adSlider.clientWidth * currentIndex;
  }, [currentIndex]);

  return (
    <div className="relative bg-gray-200">
      <div ref={adSliderRef} className="flex items-center overflow-hidden">
        {banners.map((banner, index) => (
          <div
            key={index}
            className=""
            style={{ minWidth: '100%', maxWidth: '100%' }}
          >
            <img
              src={banner.image}
              alt={`Banner ${index + 1}`}
              className="max-w-full h-auto"
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default AdBannerSlider;
