import React from "react";
import siteErrorGif from "../../../images/short-circuit-animated-500-error-page (4).gif";

const SiteErrorModal = ({
  errorMessage = "An error occurred. Please try again later.",
  details = "",
  buttonText = "",
  isOpen,
  onClose,
}) => {
  if (!isOpen) return null;

  return (
    <>
      <div className="h-screen w-screen bg-white flex items-center justify-center"></div>
      <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
        <div className="bg-white p-6 rounded-lg shadow-lg max-w-md w-full">
          <h2 className="text-xl text-center font-bold text-red-600 mb-4">
            Server Error
          </h2>
          <p className="text-center">{errorMessage}</p>
          {details && (
            <p className="text-sm text-center mt-2 text-gray-600">{details}</p>
          )}

          <button
            className="mt-6 w-full bg-red-600 text-white py-2 px-4 rounded hover:bg-red-700"
            onClick={onClose}
          >
            {buttonText}
          </button>
        </div>
      </div>
    </>
  );
};

const SiteErrorModal500 = ({ isOpen, onClose }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-90">
      <div className="relative">
        {/* The image container with relative positioning */}
        <img
          src={siteErrorGif}
          alt="Sample GIF"
          className="w-[800px] rounded-lg shadow-lg"
        />

        <button
          className="absolute bottom-4 left-1/2 transform -translate-x-1/2 bg-red-600 text-white py-2 px-4 rounded hover:bg-red-700"
          onClick={onClose}
        >
          Go to Home
        </button>
      </div>
    </div>
  );
};


interface TransactionFailedProps {
  errorMessage?: string;
  details?: string;
  buttonText: string;
  isOpen: boolean;
  onClose: () => void;
 onRetryTransaction: () => void;
 onCancelTransaction: () => void;
}

const TransactionFailed: React.FC<TransactionFailedProps> = ({
  errorMessage = "An error occurred. Please try again later.",
  details = "",
  buttonText,
  isOpen,
  onClose,
  onCancelTransaction,
  onRetryTransaction,
}) => {
  if (!isOpen) return null;

  return (
    <>
       <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-150">
      <div className="bg-gray-100 p-9 rounded-3xl shadow-lg max-w-md w-full">
        <h2 className="text-xl font-bold mb-1 text-red-600">Transaction Cancelled</h2>
        <p className="text-gray-800 text-left mx-2 p-2 mb-3">
          Your transaction has been canceled, but your <b>{details} tour has been booked</b>. 
        </p>
        <p className="text-gray-800 text-left p-2 mx-2 mb-5">
          Would you like to retry the transaction or proceed with the cancellation?
        </p>

        <div className="flex justify-between space-x-4">
          <button
            className="bg-red-600 text-white py-2 px-4 rounded-xl hover:bg-red-700 focus:outline-none transition-all"
            onClick={onCancelTransaction}
          >
            Cancel
          </button>
          <button
            className="bg-green-600 text-white py-2 px-4 rounded-xl hover:bg-green-700 focus:outline-none transition-all"
            onClick={onRetryTransaction}
          >
            Retry Transaction
          </button>
        </div>
      </div>
    </div>
    </>
  );
};


export { SiteErrorModal, TransactionFailed, SiteErrorModal500 };
