import React, { FC } from "react";

export interface Theme1HeroProps {
  className?: string;
  children?: React.ReactNode;
  img?: string;
}

const Theme1Hero: FC<Theme1HeroProps> = ({
  className = "",
  children,
  img,
}) => {
  return (
    <div className={`nc-SectionHero2 relative ${className}`}>
   
   <div className="flex flex-wrap relative">
 
  <div className="w-full h-[250px] sm:h-[450px] relative">
    <img className="w-full h-full object-cover" src={img} alt="hero" />
    <div className="absolute top-0 left-0 w-full h-full flex  justify-center">
      <p className="text-white text-center p-12">{children}</p>
    </div>
  </div>
 
</div>

    </div>
  );
};

export default Theme1Hero;