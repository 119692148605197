import CheckoutBooking from './Form';


const CheckOutForm = ({ isOpen, onClose, bookingData }: { isOpen: boolean; bookingData:any; onClose: () => void;  }) => {
     

  const onClick = () => {
    onClose();
  }

  if (!isOpen) {
    return null; // Optional, depending on your use case
  }

  return (
    <div className="fixed overflow-y-scroll inset-0 max-h-screen py-10 bg-gray-800 bg-opacity-75 flex z-50">
      <div className="bg-gray-100 rounded-lg shadow-lg max-w-4xl mx-auto">
        <div className="flex m-3 justify-end">
          <button
            onClick={onClick}
            className="text-gray-400 hover:text-gray-600"
          >
            <svg
              className="h-6 w-6 fill-current"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
            >
              <path d="M6.225 4.811l-1.414 1.414L10.586 12l-5.775 5.775 1.414 1.414L12 13.414l5.775 5.775 1.414-1.414L13.414 12l5.775-5.775-1.414-1.414L12 10.586z" />
            </svg>
          </button>
        </div>
        <div className="text-center overflow-hidden">
          {/* <img src={logo} alt="Thank you" className="mx-auto mb-4 w-24 h-20" /> */}
        
            <CheckoutBooking  bookingData={bookingData} onClose={onClick}/>
           {/* <ConfirmationDialog onBookOnly={()=>{}} onBookwithPayment={()=>{}}/> */}
        
        </div>
      </div>
    </div>
  );
};

export default CheckOutForm;
