import React ,{useState, useEffect} from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import Input from "shared/Input/Input";
import CommonLayout from "./CommonLayout";
import { useChangePasswordWithVerification } from "../../hooks/customers";

const AccountPass = () => {
  const [loginDetails, setLoginDetails] = useState({ currentPassword: "", newPassword: "" });
    // Use //@ts-ignore to ignore TypeScript error
  //@ts-ignore
  const { data, isLoading } = useChangePasswordWithVerification( loginDetails.currentPassword, loginDetails.newPassword);
  const [isOpen, setIsOpen] = useState(false);
  const [popupType, setPopupType] = useState("success");
  const [popupMessage, setPopupMessage] = useState("");



  const initialValues = {
    currentPassword: "",
    newPassword: "",
    confirmPassword: "",
  };

  const openPopup = (type, message) => {
    setPopupType(type);
    setPopupMessage(message);
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  useEffect(() => {
   
    if (data?.status === false) {
      //openPopup( "error", userData?.message );
    }
  }, [data]);

  useEffect(() => {
  
    if (data?.status === true) {
      openPopup("success", "Login successful! Redirecting to your account...");
    }
  }, [data]);


  const validationSchema = Yup.object({
    currentPassword: Yup.string().required("Current password is required"),
    newPassword: Yup.string()
      .min(8, "Password must be at least 8 characters")
      .required("New password is required"),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("newPassword"), null], "Passwords must match")
      .required("Please confirm your password"),
  });

  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    try {
      // Call changePasswordWithVerification hook to update password
      setLoginDetails( values )
    } catch (error) {
      console.error("Error updating password:", error);
    } finally {
      setSubmitting(false);
    }
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: handleSubmit,
  });

  return (
    <div>
      <CommonLayout>
        <div className="space-y-6 sm:space-y-8">
          {/* HEADING */}
          <h2 className="text-xl font-semibold">Update your password</h2>
          <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
          <div className="max-w-xl space-y-6">
            <form onSubmit={formik.handleSubmit} className="space-y-6">
              <div>
                <label htmlFor="currentPassword" className="block font-medium text-neutral-800 dark:text-neutral-200">
                  Current password
                </label>
                <Input
                  id="currentPassword"
                  name="currentPassword"
                  type="password"
                  className="mt-1.5"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.currentPassword}
                />
                {formik.touched.currentPassword && formik.errors.currentPassword ? (
                    // Use //@ts-ignore to ignore TypeScript error
  //@ts-ignore
                  <div className="text-red-500 text-sm mt-1">{formik.errors.currentPassword}</div>
                ) : null}
              </div>
              <div>
                <label htmlFor="newPassword" className="block font-medium text-neutral-800 dark:text-neutral-200">
                  New password
                </label>
                <Input
                  id="newPassword"
                  name="newPassword"
                  type="password"
                  className="mt-1.5"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.newPassword}
                />
                {formik.touched.newPassword && formik.errors.newPassword ? (
                    // Use //@ts-ignore to ignore TypeScript error
  //@ts-ignore
                  <div className="text-red-500 text-sm mt-1">{formik.errors.newPassword}</div>
                ) : null}
              </div>
              <div>
                <label htmlFor="confirmPassword" className="block font-medium text-neutral-800 dark:text-neutral-200">
                  Confirm password
                </label>
                <Input
                  id="confirmPassword"
                  name="confirmPassword"
                  type="password"
                  className="mt-1.5"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.confirmPassword}
                />
                
                {formik.touched.confirmPassword && formik.errors.confirmPassword ? (
                    // Use //@ts-ignore to ignore TypeScript error
  //@ts-ignore
                  <div className="text-red-500 text-sm mt-1">{formik.errors.confirmPassword}</div>
                ) : null}
              </div>
              <div className="pt-2">
                <ButtonPrimary type="submit" disabled={formik.isSubmitting}>
                  Update password
                </ButtonPrimary>
              </div>
            </form>
          </div>
        </div>
      </CommonLayout>
    </div>
  );
};

export default AccountPass;
