import React, { FC, useState, useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useSubmitVisaForm } from "../../../../hooks/visa";
import FormItem from "../../../../containers/PageAddListing1/FormItem";
import Input from "../../../../shared/Input/Input";
import Select from "../../../../shared/Select/Select";
import ButtonSecondary from "../../../../shared/Button/ButtonSecondary";
import AdwayBtn from "../../../../shared/Button/AdwayBtn";

import NoticePopups from "../../../../components/Adways/NoticePopUps";
import PhoneInput from "react-phone-input-2";

export interface PageAddListing2Props {}

// Form Data Type
interface VisaForm {
  firstName: string;
  otherNames: string;
  phone: string;
  email: string;
  countryOfInterest: string;
  purpose: string;
  otherPurpose: string;
  travelExperience: string;
  travelExperienceAnswer: string;
  occupation: string;
  referral: string;
  additionalCountry: string;
  employeeChecklist: string[];
  selfEmployedChecklist: string[];
  studentChecklist: string[];
  retireeChecklist: string[];
  houseWifeChecklist: string[];
}

const validationSchema = Yup.object().shape({
  firstName: Yup.string().required("First Name is required"),
  otherNames: Yup.string().required("Other Names are required"),
  phone: Yup.string()
    .matches(/^[0-9]{10}$/, "Phone number is not valid")
    .required("Phone Number is required"),
  email: Yup.string()
    .email("Invalid email address")
    .required("Email is required"),
  countryOfInterest: Yup.string().required("Country of Interest is required"),
  purpose: Yup.string().required("Purpose of Travel is required"),
  travelExperience: Yup.string().required("Travel Experience is required"),
  travelExperienceAnswer: Yup.string().when("travelExperience", {
    is: (val) => val === "Yes",
    then: (schema) => schema.required("Travel Experience Answer is required"),
  }),
  occupation: Yup.string().required("Occupation is required"),
});

const Page = ({ applyBtn }) => {
  const [submitData, setSubmitData] = useState<VisaForm | null>(null);
  const { data, isLoading } = useSubmitVisaForm(submitData);

  const [popupIsOpen, setPopupIsOpen] = useState(false);
  const [popupType, setPopupType] = useState(false);
  const [popupMessage, setPopupMessage] = useState(false);

  const openPopup = (type, message) => {
    setPopupType(type);
    setPopupMessage(message);
    setPopupIsOpen(true);
  };

  const popupCloseModal = () => {
    setPopupIsOpen(false);
  };

  useEffect(() => {
    // Use //@ts-ignore to ignore TypeScript error
    //@ts-ignore
    if (data?.status == true) {
      applyBtn();
      // Use //@ts-ignore to ignore TypeScript error
      //@ts-ignore
      openPopup("success", data?.message);
      formik.resetForm();
    }
  }, [data]);

  useEffect(() => {
    // Use //@ts-ignore to ignore TypeScript error
    //@ts-ignore
    if (data?.status == false) {
      // Use //@ts-ignore to ignore TypeScript error
      //@ts-ignore
      openPopup("error", data?.message);
    }
  }, [data]);

  const formik = useFormik<VisaForm>({
    initialValues: {
      firstName: "",
      otherNames: "",
      phone: "",
      email: "",
      countryOfInterest: "",
      purpose: "",
      otherPurpose: "",
      travelExperience: "",
      travelExperienceAnswer: "",
      occupation: "",
      referral: "",
      additionalCountry: "",
      employeeChecklist: [],
      selfEmployedChecklist: [],
      studentChecklist: [],
      retireeChecklist: [],
      houseWifeChecklist: [],
    },
    validationSchema,
    onSubmit: (values) => {
      setSubmitData(values);
    },
  });

  if (isLoading) {
    return (
      <div className="flex justify-center items-center h-full">
        <div className="animate-spin rounded-full border-4 border-gray-200 border-t-4 border-t-[#03844e] h-12 w-12"></div>
      </div>
    );
  }

  return (
    <div className="container mx-auto py-4">
      <div className="w-14 border-b border-neutral-200 dark:border-neutral-700 mx-auto mb-8"></div>
      <form
        className="grid grid-cols-1 gap-1 sm:gap-6"
        onSubmit={formik.handleSubmit}
        style={{ marginBottom: "40px" }}
      >
        <div className="grid grid-cols-1 md:grid-cols-2 gap-5 md:gap-2">
          <FormItem className="py-1 sm:py-0" label="First Name">
            <Input
              type="text"
              name="firstName"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.firstName}
              className="h-9 rounded-sm"
            />
            {formik.touched.firstName && formik.errors.firstName ? (
              <div className="text-red-500 text-sm mt-1">
                {formik.errors.firstName}
              </div>
            ) : null}
          </FormItem>
          <FormItem className="py-1 sm:py-0" label="Other Names">
            <Input
              type="text"
              name="otherNames"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.otherNames}
              className="h-9 rounded-sm"
            />
            {formik.touched.otherNames && formik.errors.otherNames ? (
              <div className="text-red-500 text-sm mt-1">
                {formik.errors.otherNames}
              </div>
            ) : null}
          </FormItem>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-5 md:gap-2">
          <FormItem className="py-2 sm:py-0" label="Phone Number">
            <PhoneInput
              country={"gh"}
              value={formik.values.phone}
              onChange={(phone) => {
                // Update the formik value for phone
                formik.setFieldValue("phone", phone);
              }}
              onBlur={formik.handleBlur}
              inputStyle={{ width: "100%" }}
            />

            {formik.touched.phone && formik.errors.phone ? (
              <div className="text-red-500 text-sm mt-1">
                {formik.errors.phone}
              </div>
            ) : null}
          </FormItem>
          <FormItem className="py-2 sm:py-0" label="Email">
            <Input
              type="email"
              name="email"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.email}
              className="h-9 rounded-sm"
            />
            {formik.touched.email && formik.errors.email ? (
              <div className="text-red-500 text-sm mt-1">
                {formik.errors.email}
              </div>
            ) : null}
          </FormItem>
        </div>
        <FormItem className="py-2 sm:py-0" label="Country of Interest">
          <Select
            name="countryOfInterest"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.countryOfInterest}
          >
            <option value="">Select...</option>
            <option value="Europe - Schengen states">
              Europe - Schengen states
            </option>
            <option value="United Kingdom">United Kingdom</option>
            <option value="United States">United States</option>
            <option value="Canada">Canada</option>
            <option value="Turkey">Turkey</option>
            <option value="South Africa">South Africa</option>
            <option value="Ethiopia">Ethiopia</option>
            <option value="Malaysia">Malaysia</option>
            <option value="India">India</option>
            <option value="United Arab Emirates - Dubai">
              United Arab Emirates - Dubai
            </option>
            <option value="Others">Others</option>
          </Select>
          {formik.touched.countryOfInterest &&
          formik.errors.countryOfInterest ? (
            <div className="text-red-500 text-sm mt-1">
              {formik.errors.countryOfInterest}
            </div>
          ) : null}
        </FormItem>

        {formik.values.countryOfInterest === "Europe - Schengen states" && (
          <FormItem className="py-2 sm:py-0" label="Select the First Country you want to enter">
            <Input
              type="text"
              name="additionalCountry"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.additionalCountry}
            />
          </FormItem>
        )}

        <FormItem className="py-4 sm:py-0" label="Purpose of Travel">
          <Select
            name="purpose"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.purpose}
          >
            <option value="">Select...</option>
            <option value="To Further Education">To Further Education</option>
            <option value="Holiday/Vacation">Holiday/Vacation</option>
            <option value="Visit Friends and Families">
              Visit Friends and Families
            </option>
            <option value="Business">Business</option>
            <option value="Medical">Medical</option>
            <option value="Transit">Transit</option>
            <option value="Others">Others</option>
          </Select>
          {formik.touched.purpose && formik.errors.purpose ? (
            <div className="text-red-500 text-sm mt-1">
              {formik.errors.purpose}
            </div>
          ) : null}
        </FormItem>

        {formik.values.purpose === "Others" && (
          <FormItem className="py-4 sm:py-0" label="Specify Purpose of Travel">
            <Input
              type="text"
              name="otherPurpose"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.otherPurpose}
            />
          </FormItem>
        )}

        <FormItem className="py-4 sm:py-0" label="Travel Experience">
          <Select
            name="travelExperience"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.travelExperience}
          >
            <option value="">Select...</option>
            <option value="Yes">Yes</option>
            <option value="No">No</option>
          </Select>
          {formik.touched.travelExperience && formik.errors.travelExperience ? (
            <div className="text-red-500 text-sm mt-1">
              {formik.errors.travelExperience}
            </div>
          ) : null}
        </FormItem>

        {formik.values.travelExperience === "Yes" && (
          <FormItem className="py-4 sm:py-0" label="Where and When">
            <Input
              type="text"
              name="travelExperienceAnswer"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.travelExperienceAnswer}
            />
            {formik.touched.travelExperienceAnswer &&
            formik.errors.travelExperienceAnswer ? (
              <div className="text-red-500 text-sm mt-1">
                {formik.errors.travelExperienceAnswer}
              </div>
            ) : null}
          </FormItem>
        )}

        <FormItem className="py-4 sm:py-0" label="Occupation">
          <Select
            name="occupation"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.occupation}
          >
            <option value="">Select...</option>
            <option value="Employee">Employee</option>
            <option value="Self Employed">Self Employed</option>
            <option value="Student">Student</option>
            <option value="Retiree">Retiree</option>
            <option value="House Wife">House Wife</option>
          </Select>
          {formik.touched.occupation && formik.errors.occupation ? (
            <div className="text-red-500 text-sm mt-1">
              {formik.errors.occupation}
            </div>
          ) : null}
        </FormItem>

        {formik.values.occupation === "Employee" && (
          <FormItem className="py-4 sm:py-0" label="Employee Checklist">
            <label>
              <input
                type="checkbox"
                name="employeeChecklist"
                value="letter of employment"
                onChange={formik.handleChange}
              />
              Letter of Employment
            </label>
            <label>
              <input
                type="checkbox"
                name="employeeChecklist"
                value="leave letter"
                onChange={formik.handleChange}
              />
              Leave Letter
            </label>
            <label>
              <input
                type="checkbox"
                name="employeeChecklist"
                value="salary account statement"
                onChange={formik.handleChange}
              />
              Salary Account Statement
            </label>
          </FormItem>
        )}

        {formik.values.occupation === "Self Employed" && (
          <FormItem className="py-4 sm:py-0" label="Self Employed Checklist">
            <label>
              <input
                type="checkbox"
                name="selfEmployedChecklist"
                value="business registration"
                onChange={formik.handleChange}
              />
              Business Registration
            </label>
            <label>
              <input
                type="checkbox"
                name="selfEmployedChecklist"
                value="business account statement"
                onChange={formik.handleChange}
              />
              Business Account Statement
            </label>
            <label>
              <input
                type="checkbox"
                name="selfEmployedChecklist"
                value="company bank statement"
                onChange={formik.handleChange}
              />
              Company Bank Statement
            </label>
          </FormItem>
        )}

        {formik.values.occupation === "Student" && (
          <FormItem className="py-4 sm:py-0" label="Student Checklist">
            <label>
              <input
                type="checkbox"
                name="studentChecklist"
                value="school admission letter"
                onChange={formik.handleChange}
              />
              School Admission Letter
            </label>
            <label>
              <input
                type="checkbox"
                name="studentChecklist"
                value="student id card"
                onChange={formik.handleChange}
              />
              Student ID Card
            </label>
            <label>
              <input
                type="checkbox"
                name="studentChecklist"
                value="sponsor letter"
                onChange={formik.handleChange}
              />
              Sponsor Letter
            </label>
          </FormItem>
        )}

        {formik.values.occupation === "Retiree" && (
          <FormItem className="py-4 sm:py-0" label="Retiree Checklist">
            <label>
              <input
                type="checkbox"
                name="retireeChecklist"
                value="retirement letter"
                onChange={formik.handleChange}
              />
              Retirement Letter
            </label>
            <label>
              <input
                type="checkbox"
                name="retireeChecklist"
                value="pension statement"
                onChange={formik.handleChange}
              />
              Pension Statement
            </label>
          </FormItem>
        )}

        {formik.values.occupation === "House Wife" && (
          <FormItem className="py-4 sm:py-0" label="House Wife Checklist">
            <label>
              <input
                type="checkbox"
                name="houseWifeChecklist"
                value="marriage certificate"
                onChange={formik.handleChange}
              />
              Marriage Certificate
            </label>
            <label>
              <input
                type="checkbox"
                name="houseWifeChecklist"
                value="husband's letter of employment"
                onChange={formik.handleChange}
              />
              Husband's Letter of Employment
            </label>
            <label>
              <input
                type="checkbox"
                name="houseWifeChecklist"
                value="husband's salary account statement"
                onChange={formik.handleChange}
              />
              Husband's Salary Account Statement
            </label>
          </FormItem>
        )}

        <FormItem className="py-4 sm:py-0" label="Referral">
          <Input
            type="text"
            name="referral"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.referral}
          />
        </FormItem>

        <div className="grid grid-cols-2 gap-4">
          <AdwayBtn type="submit">Submit</AdwayBtn>
          <ButtonSecondary onClick={applyBtn} type="button">
            Cancel
          </ButtonSecondary>
        </div>
      </form>
      <NoticePopups
        isOpen={popupIsOpen}
        closeModal={popupCloseModal}
        type={popupType}
        message={popupMessage}
      />
    </div>
  );
};

export default Page;
