import { FC } from "react";
import Logo from "../../images/marshall/Adansi-Marshall-logo.png";
import { Link } from "react-router-dom";

const MarshalHeader: FC = () => {
  return (
    <header className={`top-0 z-40 `}>
      <div
        className={`relative px-4 lg:container h-[120px] flex items-center justify-center`}
      >
        <div className={`absolute inset-0 flex items-center justify-center `}>
          <Link
            to="/"
            className={`ttnc-logo inline-block text-primary-6000 focus:outline-none focus:ring-0`}
          >
            <img
              className={`block max-h-20 justify-center`} // Increased max-height class
              src={Logo}
              alt="Logo"
            />
          </Link>
        </div>
        <div className="absolute left-0 z-10 flex items-center">
          {/* Other left-aligned elements can go here */}
        </div>
        <div className="absolute right-0 z-10 flex items-center">
          {/* Other right-aligned elements can go here */}
        </div>
      </div>
    </header>
  );
};

export default MarshalHeader;
